<template>
  <a-select v-model="value" style="width: 400px" placeholder="请选择分组" @change="onChangeHandler">
    <a-select-option :value="1">育儿文章</a-select-option>
    <a-select-option :value="2">评测文章</a-select-option>
    <a-select-option :value="3">品牌资讯</a-select-option>
    <a-select-option :value="4">奶查资讯</a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "ArticleCategoryFormItem",
  props: ['value'],
  methods: {
    onChangeHandler() {
      this.$emit('input', this.value);
      this.$emit('change');
    }
  }
}
</script>

<style scoped>

</style>