<template>
  <div class="page-container">
    <a-row class="data-list">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="headImageUrl" slot-scope="headImageUrl">
          <img :src="headImageUrl" style="width: 100px; height: 100px" alt="">
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
            <a class="link" @click="onCopyRowHandler(row)">复制信息</a>
            <a class="link" @click="onOpenExpressDialog">已寄产品</a>
          </a-space>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
import * as clipboard from 'clipboard-polyfill'
export default {
  name: "EvaluationEnroll.vue",
  props: ['evaluationId', 'disabled'],
  data() {
    return {
      // 表单
      form: {
        fieldType: 1,
        keyword: '',
        evaluationId: this.evaluationId
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      columns: [
        {title: '报名头像', dataIndex:'headImageUrl', scopedSlots: {customRender: 'headImageUrl'}},
        {title: '昵称', dataIndex:'nickName'},
        {title: '姓名', dataIndex:'userName'},
        {title: '手机号', dataIndex:'mobile'},
        {title: '地址', dataIndex:'address'},
        {title: '报名时间', dataIndex:'createTime'},
        {title: '备注', dataIndex:'memo'},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ],
      dataList: [
      ],
      data: {
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('EvaluationEnroll 初始化数据...', [this.disabled])
      // 先加载需要的数据
      this.refreshList();
    },
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.evaluationEnrollPage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
    // 复制信息
    onCopyRowHandler(row) {
      let clipboardData = `${row.userName} ${row.mobile} ${row.address} ${row.memo}`;
      clipboard.writeText(clipboardData);
      this.$message.success("成功复制: "+clipboardData);
    },
    // 打开填写快递信息
    onOpenExpressDialog(row) {
      console.log(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
.top_tab {
  border-bottom: 1px solid #EAEAEA;
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;

  .refresh-btn {
    padding-right: 20px;
    .btn {
      cursor: pointer;
    }
  }
}
.agg {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* or 18px */
  color: #000000;
}
.desc {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  font-size: 13px;
  /* or 18px */
  color: #bababa;
}
</style>
