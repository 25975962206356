<template>
  <div class="page-container">
    <a-row type="flex" >
      <a-col flex="1">
        <a-button @click="onGotoFormHandler(null)" type="primary">添加指标</a-button>
      </a-col>
      <a-col>
        <a-space size="small">
<!--          <a-select v-model="form.brandId" placeholder="请选择品牌" style="width: 160px">-->
<!--            <a-select-option value="">请选择品牌</a-select-option>-->
<!--            <a-select-option v-for="(b, idx) in data.brands" :key="idx" :value="b.id">-->
<!--              {{b.name}}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
          <a-input-search v-model="form.keyword" placeholder="请输入关键字" style="width: 200px" @search="refreshList" />
<!--          <a-button type="link" @click="onTemplateDownloadHandler">下载模板</a-button>-->
<!--          <a-button type="link" @click="onImportDataHandler">导入</a-button>-->
<!--          <a-button type="link" icon="export" @click="onExportHandler">导出</a-button>-->
        </a-space>
      </a-col>
    </a-row>
    <br><br>
    <a-row class="data-list">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="status" slot-scope="row">
          <p v-if="row.status === 1">{{row.statusName}}</p>
          <p v-if="row.status === 0" style="color: #E54545">{{row.statusName}}</p>
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
<!--            <a @click="onUniqueHandler(row.id)" class="link">查看</a>-->
            <a @click="onEditHandler(row.id)" class="link">编辑</a>
            <a-popconfirm title="确认要删除当前数据吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onRemoveHandler(row.id)">
              <a class="link">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "PregnantBxIndexList.vue",
  data() {
    return {
      // 表单
      form: {
        name: '',
        endDate: '',
        keyword: '',
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      columns: [
        {title: '名称', dataIndex:'name'},
        // {title: '项目介绍', dataIndex:'description'},
        // {title: '温馨提示', dataIndex:'warnings'},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ],
      dataList: [
      ],
      data: {
        brands: [],
        provinces: [],
        cities: [],
        counties: []
      },
    };
  },
  mounted() {
    this.$store.commit('clearRouteStack');
    this.$store.commit('changeCurRouteStack', 4);
    this.initData();
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('初始化数据...')
      // 先加载需要的数据
      // await this.fetchConditions();
      this.refreshList();
    },
    // 获取筛选条件
    async fetchConditions() {
      let res = await this.$api.brandListAll();
      if(res.code === 0) {
        this.data.brands = res.list;
      }
    },

    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.pregnantBxIndexPage({productType: this.productType,pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    // 导出
    onExportHandler() {
      console.log('导出列表');
      this.$api.adminStoreExport(this.form);
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看product: '+id);
      this.$message.info("待完善");
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑product: '+id);
      this.onGotoFormHandler(id);
    },
    // 删除
    async onRemoveHandler(id) {
      console.log('删除product: '+id);
      await this.$api.pregnantBxIndexDelete({id});
      await this.refreshList();
    },
    // 前往表单页面
    async onGotoFormHandler(id) {
      // 前往登录页面
      this.$router.push(`/pregnantBxIndex/pregnantBxIndexForm?id=${id}`);
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
    onTemplateDownloadHandler() {
      window.open('https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/templates/store_template.xlsx', '_blank');
    },
    onImportDataHandler() {
      let that = this;
      let inputTag = document.createElement('input');
      inputTag.type = 'file';
      inputTag.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      inputTag.onchange = async function() {
        console.log('选中的文件: ', inputTag.files);
        if (inputTag.files.length > 0) {
          let file = inputTag.files[0];

          // 上传图片
          let res = await that.$api.storeImportData({file});
          if(res) {
            that.refreshList();
          }
        }
      };
      inputTag.click();
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
.ant-btn-link {
  padding: 0;
}
</style>
