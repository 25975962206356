<template>
  <div>
    <div class="link" @click="$emit('choose', type)">添加{{this.modelName}}</div>
    <div class="list1">
      <div class="item1" v-for="(item, idx) in form.list"
           @dragstart="ev => ev.dataTransfer.setData('Text', ev.target.getAttribute('index'))"
           @drop="exchangeNode"
           :index="idx"
           @dragover="ev => ev.preventDefault()"
           draggable="true"
           :key="idx">
        <img :src="item.faceUrl"
             :index="idx"
             @dragover="ev => ev.preventDefault()" />
        <div class="middle-wrap"
             :index="idx"
             @dragover="ev => ev.preventDefault()">
          <div class="title">{{ item.title }}</div>
          <div class="no">{{ item.registerNo }}</div>
        </div>
        <a-icon class="close" @click="onRemoveOptions(item)" type="close-circle" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerConfig",
  props: ['modelName', 'list', 'type'],
  mounted() {
  },
  data() {
    return {
      form: {
        list: [],
        modelName: ''
      },
      state: {
        formColSpan: 24,
        labelCol: {span: 5, offset: 1},
        wrapperCol: {span: 16},
      },
    }
  },
  watch: {
    list(oldVal, newVal) {
      if (oldVal === newVal) {
        return;
      }
      this.form.list = this.list;
    }
  },
  methods: {
    // 选择图片
    async onChooseImageHandler(item) {
      let that = this;
      console.log('添加图片: ', item);
      let inputTag = document.createElement('input');
      inputTag.type = 'file';
      inputTag.accept = 'image/*';
      inputTag.onchange = async function() {
        console.log('添加图片: ', inputTag.files);
        if (inputTag.files.length > 0) {
          let file = inputTag.files[0];
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function(e){
            item.imageUrl = e.target.result;
          }
          // 上传图片
          let res = await that.$api.publicUpload({file});
          if(res) {
            item.imageUrl = res.data.url;
            that.submitForm();
          }
        }
      };
      inputTag.click();
    },
    // 类别变动
    onTypeChangeHandler(item, selectedItems) {
      // let selectedOption = selectedOptions[0];
      item.type = selectedItems.value;
      item.label = selectedItems.label;
      this.form.curItem = item;
      if(item.type === 201) {
        // h5页面
        this.state.showH5PageForm = true;
      }else if (item.type === 202) {
        // 小程序页面
        this.state.showLittleAppForm = true;
      } else {
        this.submitForm();
      }
    },
    // 小程序页面
    onConfirmSelectedHandler() {
      this.state.showSelectDialog = false;
      this.submitForm();
    },
    // 提交表单
    submitForm() {
      console.log('提交产品选择: ', JSON.stringify(this.list));
      let form = {list: this.form.list.map(t => ({
          id: t.configId, sort: t.sort, type: t.type, itemId: t.id
        }))};
      this.$api.hotItemsBatchAddOrSave(form);
    },
    onRemoveOptions(b) {
      this.$emit('remove', b, this.type);
    },
    exchangeNode(ev) {
      let idx1 = ev.target.getAttribute('index');
      let idx2 = ev.dataTransfer.getData('Text');
      let tmpArr = [...this.list];
      let tmp = tmpArr[idx1];
      tmpArr[idx1] = tmpArr[idx2];
      tmpArr[idx2] = tmp;
      tmpArr.forEach((t, idx) => t.sort = idx);
      this.list.splice(0);
      this.list.push(...tmpArr);
      this.submitForm();
    }
  }
}
</script>

<style lang="scss" scoped>
// banner图编辑样式
.link {
  color: #5A66FF;
  margin-bottom: 10px;
  cursor: pointer;
  display: inline-block;
}
.list1 {
  display: flex;
  flex-wrap: wrap;
  width: 1240px;
  background: #F5F5F5;
  padding: 10px;
  padding-right: 0;
  .item1 {
    cursor: move;
    width: 230px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    background: #fff;
    img {
      width: 47px;
      height: 47px;
      border: 1px solid #dedede;
      border-radius: 5px;
      margin-right: 10px;
    }
    .middle-wrap {
      flex: 1;
    }
    .title {
      color: #333;
    }
    .no {
      color: #888;
    }
    .close {
      margin-left: 10px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
</style>
