<template>
  <div class="tabs">
    <div v-for="(it, idx) in value" @click="onTabClickHandler(idx)"
         :key="idx" :class="{tab: true, active: idx === state.curTab}">{{ it.title }}
      <span v-if="showNum" class="tab-title">(<span class="tab-num">{{ it.count }}</span>)</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTab",
  props: ['showNum', 'value'],
  mounted() {
    console.log(this.value);
  },
  data() {
    return {
      state: {
        curTab: 0
      },
    };
  },
  methods: {
    onTabClickHandler(idx) {
      if (this.state.curTab === idx) {
        return;
      }
      this.state.curTab = idx;
      this.$emit('change', idx);
    },
  }

}
</script>

<style lang="scss" scoped>
.tabs {
   .tab {
     float: left;
     cursor: pointer;
     padding: 0 20px;
     .tab-title {
       color: #333;
     }

     .tab-num {
       color: #E54545;
     }
   }
   .tab.active {
     color: #5A66FF;
     border-bottom: 2px solid #5A66FF;
     border-radius: 1px;
   }
 }
</style>
