<template>
  <div class="page-container">
    <br>
    <a-form-model :model="form" ref="form" :rules="rules"
                 :label-col="{span: 2}"
                 :wrapper-col="{span: 10}"
    >
<!--      <div class="title">-->
<!--        <a-space>-->
<!--          <span>维护的孕周: </span>-->
<!--          <a-input v-model="state.weeks" placeholder="请输入共需维护多少周" />-->
<!--          <a-button type="primary" size="small">确认</a-button>-->
<!--        </a-space>-->
<!--      </div>-->
      <div>
        <a-space>
          <a-button v-if="!state.isEdit" @click="state.isEdit=true" type="primary">编辑</a-button>
          <a-button v-if="state.isEdit" @click="onSaveHandler(false)" type="primary">保存并退出编辑</a-button>
          <a-button v-if="state.isEdit" @click="onSaveHandler(true)" type="primary">保存</a-button>
        </a-space>
      </div>
      <br>
      <!--  胎动界面：操作说明，胎动说明  -->
      <a-table :columns="columns" :data-source="form.bxConfig"
               :defaultExpandAllRows="true"
               @expand="onExpandedRowChangeHandler">
        <template slot="operation" slot-scope="record">
          <a v-if="state.isEdit" @click="record.childs.push({id: null, range: null, name: null})">添加指标</a>
        </template>
        <m-input v-model="record.name" :is-edit="false" slot="name" slot-scope="_,record" />
        <a-table
          slot="expandedRowRender"
          slot-scope="record"
          :columns="innerColumns"
          :data-source="record.childs"
          :pagination="{pageSize: 50}"
        >
          <pregnant-bx-index-form-item v-model="rd.id"
                                       :name="rd.name"
                                       @change="({id, name}) => { rd.id=id; rd.name=name;}"
                                       :is-edit="state.isEdit" slot="indexName" slot-scope="_, rd" />
          <m-input v-model="rd.range" :is-edit="state.isEdit" slot="range" slot-scope="_, rd" />
          <template slot="operation" slot-scope="rd, idx" class="table-operation">
            <a-space>
              <a-popconfirm title="确认要删除当前数据吗?" ok-text="确认" cancel-text="取消"
                            @confirm="record.childs.splice(idx, 1)">
                <a class="link">删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </a-table>
      </a-table>

    </a-form-model>

  </div>
</template>

<script>
import MInput from "../../components/common/MInput";
import PregnantBxIndexFormItem from "./PregnantBxIndexFormItem";
export default {
  name: "UISetting",
  components: {PregnantBxIndexFormItem, MInput},
  data() {
    return {
      form: {
        bxConfig: [
        ],
      },
      rules: {
      },
      state: {
        weeks: 0,
        isEdit: false
      },
      columns: [
        { title: '周数', dataIndex: 'name', key: 'name', scopedSlots: { customRender: 'name' } },
        { title: '操作', key: 'operation', scopedSlots: { customRender: 'operation' } },
      ],
      innerData: []
    };
  },
  mounted() {
    this.loadConfig();
  },
  computed: {
    innerColumns() {
      return this.state.isEdit ?
        [
          {title: '名称', dataIndex: 'name', key: 'name', scopedSlots: {customRender: 'indexName'}},
          {title: '参考值', dataIndex: 'range', key: 'range', scopedSlots: {customRender: 'range'}},
          {title: '操作', key: 'operation', scopedSlots: {customRender: 'operation'}},
        ] :
        [
          {title: '名称', dataIndex: 'name', key: 'name', scopedSlots: {customRender: 'indexName'}},
          {title: '参考值', dataIndex: 'range', key: 'range', scopedSlots: {customRender: 'range'}},
        ];
    }
  },
  methods: {
    async loadConfig() {
      let res = await this.$api.sysConfigGet();
      console.log(res);
      if (res) {
        let bxConfig = res.data.bxConfig || [];
        for (let i = 0; i < 40; i++) {
          let cur = bxConfig[i];
          if (!cur) {
            bxConfig[i] = {name: '第'+(i+1)+'周', childs: []};
          }
        }
        console.log(bxConfig)
        this.form.bxConfig = bxConfig;
        this.state.weeks = bxConfig.length;
      }
    },
    onExpandedRowChangeHandler(expanded, record) {
      console.log('展开的行: ', expanded, record);
    },
    onEditChildItemHandler(record) {
      console.log(record);
    },
    async onSaveHandler(b) {
      this.state.isEdit = b;
      this.$api.sysConfigSave(this.form);
    },
    async submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$api.sysConfigSave(this.form);
        }
      })
    }
  }
}
</script>

<style scoped>
.page-container {
  padding: 20px;
  background: #fff;
}
</style>