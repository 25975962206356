
// 判断是什么设备
export const isH5OrPc = ()=> {
    if(/Mobile|Android|webOS|iPhone|iPad|Phone/i.test(navigator.userAgent)){
        return "H5";
    }
    return "PC";
 }

const PRODUCT_FEATURE_STORE_ADV_KEY = 'milk_query_product_feature_adv_key';
// 载入历史数据
export const productFeatureAdvantageLoad = () => {
    let map = JSON.parse(localStorage.getItem(PRODUCT_FEATURE_STORE_ADV_KEY)||'{}');
    let list = [];

    for (let ele in map) {
        list.push(map[ele]);
    }
    return list;
}
// 修改历史数据
export const productFeatureAdvantageUpdate = (name, intro) => {
    let map = JSON.parse(localStorage.getItem(PRODUCT_FEATURE_STORE_ADV_KEY)||'{}');
    let obj = map[name];
    if (obj) {
        obj.intro = intro;
    } else {
        obj = {name, intro}
    }
    map[name] = obj;
    localStorage.setItem(PRODUCT_FEATURE_STORE_ADV_KEY, JSON.stringify(map));
}
const PRODUCT_FEATURE_STORE_WRN_KEY = 'milk_query_product_feature_wrn_key';
// 载入历史数据
export const productFeatureWarningLoad = () => {
    let map = JSON.parse(localStorage.getItem(PRODUCT_FEATURE_STORE_WRN_KEY)||'{}');
    let list = [];
    for (let ele in map) {
        list.push(map[ele]);
    }
    return list;
}
// 修改历史数据
export const productFeatureWarningUpdate = (name, intro) => {
    let map = JSON.parse(localStorage.getItem(PRODUCT_FEATURE_STORE_WRN_KEY)||'{}');
    let obj = map[name];
    if (obj) {
        obj.intro = intro;
    } else {
        obj = {name, intro}
    }
    map[name] = obj;
    localStorage.setItem(PRODUCT_FEATURE_STORE_WRN_KEY, JSON.stringify(map));
}

const PRODUCT_FEATURE_STORE_NR_KEY = 'milk_query_product_feature_nn_key';
// 载入历史数据
export const productFeatureNrLoad = () => {
    let map = JSON.parse(localStorage.getItem(PRODUCT_FEATURE_STORE_NR_KEY)||'{}');
    let list = [];
    for (let ele in map) {
        list.push(map[ele]);
    }
    return list;
}
// 修改历史数据
export const productFeatureNrUpdate = (name, item) => {
    let map = JSON.parse(localStorage.getItem(PRODUCT_FEATURE_STORE_NR_KEY)||'{}');
    map[name] = item;
    localStorage.setItem(PRODUCT_FEATURE_STORE_NR_KEY, JSON.stringify(map));
}
