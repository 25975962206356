<template>
  <div class="page-container">
    <a-row type="flex" >
      <a-col flex="1">
        <a-button @click="onGotoFormHandler(null)" type="primary">发布评测</a-button>
      </a-col>
      <a-col>
        <a-space size="middle">
          <a-input-search v-model="form.keyword" placeholder="请输入关键字" style="width: 200px" @search="refreshList" />
        </a-space>
      </a-col>
    </a-row>
    <br><br>
    <a-row class="data-list">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="faceUrl" slot-scope="faceUrl">
          <img :src="faceUrl" style="width: 100px; height: 100px" alt="">
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
            <a @click="onUniqueHandler(row.id)" class="link">详情</a>
<!--            <a @click="onEditHandler(row.id)" class="link">编辑</a>-->
            <a-popconfirm title="确认要删除当前数据吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onRemoveHandler(row.id)">
              <a class="link">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "Evaluation.vue",
  data() {
    return {
      // 表单
      form: {
        startDate: '',
        endDate: '',
        keyword: '',
        brandId: '',
        province: '',
        city: '',
        county: '',
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      columns: [
        {title: '编号', dataIndex:'id'},
        {title: '活动名称', dataIndex:'activityName'},
        {title: '产品名称', dataIndex:'productName'},
        {title: '评测名额', dataIndex:'limitCount'},
        {title: '报名人数', dataIndex:'enrollCount'},
        {title: '报名截止时间', dataIndex:'expireDate'},
        {title: '评测截至时间', dataIndex:'commentEndTime'},
        {title: '来源', dataIndex:'origin'},
        {title: '状态', dataIndex:'statusName'},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ],
      dataList: [
      ],
      data: {
        brands: [],
        provinces: [],
        cities: [],
        counties: []
      },
    };
  },
  mounted() {
    this.$store.commit('clearRouteStack');
    this.$store.commit('changeCurRouteStack', 6);
    this.initData();
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('初始化数据...')
      // 先加载需要的数据
      this.refreshList();
    },
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.evaluationPage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    // 导出
    onExportHandler() {
      console.log('导出列表');
      this.$api.adminStoreExport(this.form);
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看evaluation: '+id);
      this.$router.push('/evaluation/detail?id='+id);
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑product: '+id);
      this.onGotoFormHandler(id);
    },
    // 删除
    async onRemoveHandler(id) {
      console.log('删除evaluation: '+id);
      await this.$api.evaluationDelete({id});
      await this.refreshList();
    },
    // 前往表单页面
    async onGotoFormHandler(id) {
      // 前往登录页面
      this.$router.push(`evaluation/evaluationForm?id=${id}`);
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
</style>
