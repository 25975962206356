<template>
  <div class="page-container">
    <a-row class="top_tab" type="flex" v-if="!isGene">
      <a-col flex="1">
        <my-page-tab :showNum="true" @change="onTabChangeHandler" v-model="data.pageTabs" />
      </a-col>
      <a-col class="refresh-btn">
        <a-icon class="btn" type="sync" />
      </a-col>
    </a-row>
    <br>
    <a-row type="flex" style="padding: 0 20px">
      <a-col flex="1">
        <a-button @click="onGotoFormHandler(null, 1)" type="primary" class="sell-goods-button">发布{{ goodsTypeName }}</a-button>
        <a-button @click="onGotoFormHandler(null, 2)" v-if="!isGene">发布助力购商品</a-button>
      </a-col>
      <a-col>
        <a-space size="middle">
          <a-input-search v-model="form.keyword" placeholder="请输入关键字" style="width: 200px" @search="refreshList" />
        </a-space>
      </a-col>
    </a-row>
    <br><br>
    <a-row class="data-list" style="padding: 0 20px">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="faceUrl" slot-scope="row">
          <a-row>
            <div style="display: flex">
              <img v-if="row.logoUrl" :src="row.logoUrl" style="width: 60px; height: 60px; border-radius: 5px" alt="">
              <div style="flex: 1; display: flex; margin-left: 10px">{{row.goodsName}}</div>
<!--              <div>{{row.id}}</div>-->
            </div>
          </a-row>
        </template>
        <template slot="status" slot-scope="row">
          <div v-if="row.stockCount > 0">
            <div v-if="row.status === 1">销售中</div>
            <div v-if="row.status === 0">未上架</div>
          </div>
          <div v-if="row.stockCount === 0">已售罄</div>
        </template>
        <template slot="helpBuy" slot-scope="row">
          <div v-if="row.sellType === 2">{{row.helpPersonCount}}人 - {{row.helpedPrice}}元</div>
          <div v-if="row.sellType === 1">未开启</div>
        </template>
        <template slot="price" slot-scope="row">
<!--          <a-icon :v-if="false" type="edit" class="inline-edit-btn" />-->
          <div>{{row.price}}</div>
        </template>
        <template slot="stockCount" slot-scope="row">
<!--          <a-icon :v-if="false" type="edit" class="inline-edit-btn" />-->
          <div>{{row.stockCount}}</div>
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
<!--            <a @click="onUniqueHandler(row.id)" class="link">查看</a>-->
            <a @click="onEditHandler(row.id, 0)" class="link">编辑</a>
            <a-popconfirm title="确认要删除当前数据吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onRemoveHandler(row.id)">
              <a class="link">删除</a>
            </a-popconfirm>
            <a-popconfirm v-if="row.status === 0" placement="bottom" ok-text="确定" cancel-text="取消" @confirm="onPullonHandler(row.id)">
              <template slot="title">
                <p>确定要上架该商品吗?</p>
              </template>
              <a class="link">上架</a>
            </a-popconfirm>
            <a-popconfirm v-if="row.status === 1" placement="bottom" ok-text="确定" cancel-text="取消" @confirm="onPulloffHandler(row.id)">
              <template slot="title">
                <p>确定要下架该商品吗?</p>
              </template>
              <a class="link">下架</a>
            </a-popconfirm>
            <a-popconfirm v-if="!isGene" placement="bottom" ok-text="确定" cancel-text="取消" @confirm="onPormoptHandler(row)">
              <template slot="title">
                <p>确定要推广该商品吗?</p>
              </template>
              <a class="link">推广</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-row>
    <!--  推广产品二维码  -->
    <a-modal
        title="产品推广"
        :visible="state.showPromptModal"
        ok-text="下载"
        @ok="onDownloadQrcode"
        cancel-text="取消"
    >
      <div style="text-align: center">
        <img :src="state.goodsQrcodeUrl" style="width: 300px" alt="小程序码">
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "Product.vue",
  data() {
    return {
      // 表单
      form: {
        keyword: '',
        gene: 0,
      },
      // 状态
      state: {
        curTab: 0,
        showPromptModal: false,
        goodsQrcodeUrl: ''
      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      columns: [
        {title: '编号', dataIndex:'id'},
        {title: '商品', dataIndex:'', scopedSlots: {customRender: 'faceUrl'}},
        {title: '商品类别', dataIndex:'goodsType'},
        {title: '价格（元）', dataIndex:'', scopedSlots: {customRender: 'price'}},
        {title: '助力购', dataIndex:'', scopedSlots: {customRender: 'helpBuy'}},
        {title: '库存（件）', dataIndex:'', scopedSlots: {customRender: 'stockCount'}},
        {title: '商品状态', dataIndex:'', scopedSlots: {customRender: 'status'}},
        {title: '访问量', dataIndex:'visitCount'},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ],
      dataList: [
      ],
      data: {
        pageTabs: [
          {title: '全部', count: 0},
          {title: '助力购', count: 0},
          {title: '售罄', count: 0},
        ],
      },
    };
  },
  computed: {
    isGene() {
      return parseInt(this.$route.query.isGene) === 1;
    },
    goodsTypeName() {
      return parseInt(this.$route.query.isGene) === 1 ? '基因商品' : '售卖商品';
    }
  },
  mounted() {
    this.$store.commit('clearRouteStack');
    this.$store.commit('changeCurRouteStack', parseInt(this.$route.query.isGene)===1 ? 7:8);
    if (this.isGene) {
      this.columns.splice(4, 1);
    }
    this.initData();
  },
  watch: {
    isGene(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$store.commit('changeCurRouteStack', parseInt(this.$route.query.isGene)===1 ? 7:8);
        this.refreshList();
      }
    }
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('初始化数据...')
      // 先加载需要的数据
      this.refreshList();
    },
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.goodsPage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form
        , gene: this.$route.query.isGene, curTab: this.state.curTab});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
      this.data.pageTabs[0].count = (res.data.helpCount + res.data.sellOutCount);
      this.data.pageTabs[1].count = res.data.helpCount;
      this.data.pageTabs[2].count = res.data.sellOutCount;
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    // 导出
    onExportHandler() {
      console.log('导出列表');
      this.$api.adminStoreExport(this.form);
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看product: '+id);
      this.$message.info("待完善");
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑product: '+id);
      this.onGotoFormHandler(id);
    },
    // 删除
    async onRemoveHandler(id) {
      // console.log('删除product: '+id);
      await this.$api.goodsDelete({id});
      await this.refreshList();
    },
    // 上架
    async onPullonHandler(id) {
      // console.log('删除product: '+id);
      await this.$api.goodsPopup({id, action: 1});
      await this.refreshList();
    },
    // 下架
    async onPulloffHandler(id) {
      // console.log('删除product: '+id);
      await this.$api.goodsPopup({id, action: 0});
      await this.refreshList();
    },
    // 推广
    async onPormoptHandler(row) {
      console.log('推广goods: '+row);
      let pages = row.sellType === 1 ? 'pages/goods/index?id=' + row.id : 'pages/goods/invite?id=' + row.id;
      this.state.goodsQrcodeUrl = '/api/wechat/littleapp/qrcode?path='+pages;
      this.state.showPromptModal = true;
    },
    // 前往表单页面
    async onGotoFormHandler(id, sellType) {
      // 前往登录页面
      this.$router.push(`goods/goodsForm?id=${id}&sellType=${sellType}&isGene=${this.$route.query.isGene}`);
    },
    // 页面tab页切换
    async onTabChangeHandler(idx) {
      console.log('page-goods: 切换导航栏=', idx);
      this.state.curTab = idx;
      this.refreshList();
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
    // 下载二维码
    onDownloadQrcode() {
      // 使用a标签方式下载
      const dlLink = document.createElement('a')
      dlLink.download = 'MQ_goods_qrcode_' + Date.now() + '.png';
      // 图片的url后面，必须加上后缀，否则会直接打开图片，不是下载图片
      dlLink.href = this.state.goodsQrcodeUrl + '?response-content-type=application/octet-stream'
      document.body.appendChild(dlLink)
      dlLink.click()
      document.body.removeChild(dlLink);
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
}
.sell-goods-button {
  margin-right: 20px;
}
.top_tab {
  border-bottom: 1px solid #EAEAEA;
  height: 50px;
  line-height: 50px;

  .refresh-btn {
    padding-right: 20px;
    .btn {
      cursor: pointer;
    }
  }
}
.inline-edit-btn {
  margin-left: 10px;
  cursor: pointer;
}
</style>
