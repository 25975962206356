<template>
  <div class="page-container">
    <div class="tab-container">
      <div class="title">Banner</div>
      <a-row class="form-block">
        <a-col :span="2">
          <div class="label">首页</div>
        </a-col>
        <a-col :span="22">
          <my-banner-config :position="1" v-on:choose="showChooseDialog" :list="form.indexBanners" :size="5" />
        </a-col>
      </a-row>
      <a-row class="form-block">
        <a-col :span="2">
          <div class="label">育儿知识</div>
        </a-col>
        <a-col :span="22">
          <my-banner-config :position="2" v-on:choose="showChooseDialog" :list="form.toLearnBanners" :size="5" />
        </a-col>
      </a-row>
      <a-row class="form-block">
        <a-col :span="2">
          <div class="label">查福利</div>
        </a-col>
        <a-col :span="22">
          <my-banner-config :position="3" v-on:choose="showChooseDialog" :list="form.toTryBanners" :size="5" />
        </a-col>
      </a-row>
      <a-row class="form-block">
        <a-col :span="2">
          <div class="label">基因</div>
        </a-col>
        <a-col :span="22">
          <my-banner-config :position="4" v-on:choose="showChooseDialog" :list="form.geneBanners" :size="5" />
        </a-col>
      </a-row>
      <a-row class="form-block">
        <a-col :span="2">
          <div class="label">评测</div>
        </a-col>
        <a-col :span="22">
          <my-banner-config :position="5" v-on:choose="showChooseDialog" :list="form.evaluationBanners" :size="5" />
        </a-col>
      </a-row>
      <a-row class="form-block">
        <a-col :span="2">
          <div class="label">查孕检</div>
        </a-col>
        <a-col :span="22">
          <my-banner-config :position="6" v-on:choose="showChooseDialog" :list="form.pregnantBanners" :size="5" />
        </a-col>
      </a-row>
      <a-row class="form-block">
        <a-col :span="2">
          <div class="label">查招聘</div>
        </a-col>
        <a-col :span="22">
          <my-banner-config :position="7" v-on:choose="showChooseDialog" :list="form.jobBanners" :size="5" />
        </a-col>
      </a-row>
      <a-row class="form-block">
        <a-col :span="2">
          <div class="label">疫苗指南</div>
        </a-col>
        <a-col :span="22">
          <my-banner-config :position="8" v-on:choose="showChooseDialog" :list="form.vaccineBanners" :size="5" />
        </a-col>
      </a-row>
      <a-row class="form-block">
        <a-col :span="2">
          <div class="label">查代理</div>
        </a-col>
        <a-col :span="22">
          <my-banner-config :position="9" v-on:choose="showChooseDialog" :list="form.agentBanners" :size="5" />
        </a-col>
      </a-row>
      <a-row class="form-block">
        <a-col :span="2">
          <div class="label">查资讯</div>
        </a-col>
        <a-col :span="22">
          <my-banner-config :position="10" v-on:choose="showChooseDialog" :list="form.newsBanners" :size="5" />
        </a-col>
      </a-row>
    </div>
    <!--  选择商品  -->
    <a-modal v-model="state.showSelectDialog" :title="'添加'+this.form.modelName"
             okText="确定"
             :width="960"
             cancelText="取消"
             @ok="onConfirmSelectedHandler">
      <div>
        <div style="height: 60px; width: 100%;">
          <a-input-search v-model="form.keyword" style="float: right; width: 500px;"
                          placeholder="请输入搜索关键字"
                          size="large"
                          enter-button @search="onKeywordChange" />
        </div>
        <div class="item-list">
          <div v-for="(b, idx) in data.optionList"
               :key="idx"
               @click="onOptionChoosedHandler(b)"
               :class="{item: true, checked: isChecked(b)}">
            <div class="triangle-topleft"><a-icon class="icon-check" type="check" /></div>
            <img :src="b.faceUrl" />
            <div class="block">
              <div class="title">{{b.title}}</div>
              <div class="no">{{ b.no }}</div>
            </div>
          </div>
        </div>
        <a-pagination :current="page.current" :total="page.total" :show-quick-jumper="true" @change="onDataListChange" />
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "PageConfig",
  data() {
    return {
      form: {
        // banner部分
        indexBanners: [],
        toLearnBanners: [],
        toTryBanners: [],
        geneBanners: [],
        evaluationBanners: [],
        pregnantBanners: [],
        jobBanners: [],
        vaccineBanners: [],
        agentBanners: [],
        newsBanners: [],
        // 产品部分
        milkList: [],
        assistList: [],
        childMilkList: [],
        teenMilkList: [],
        adultMilkList: [],
        nurtureList: [],
        dipperList: [],
        liquidMilkList: [],
        evaluationList: [],
          // 产品品牌部分
        milkBrandList: [],
        assistBrandList: [],
        nurtureBrandList: [],
        liquidMilkBrandList: [],
        evaluationEnrollList: [],
        // 热搜产品部分
        searchMilkList: [],
        searchAssistList: [],
        searchFurtureList: [],
        searchDipperList: [],
          searchLiquidMilkList: [],
        // banner部分
        bannerMilkList: [],
        bannerAssistList: [],
        bannerNurtureList: [],
          bannerLiquidList: [],
        bannerHelpList: [],
        bannerEvaluationList: [],
        modelName: '',
        curHotType: null,
        keyword: '',
        curBannerItem: null,
        curBannerAfterTrigger: () => console.log('not complemented!'),
      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 12, total: 0, size: '10', showQuickJumper: true },
      state: {
        showSelectDialog: false,
        isMultiSelect: true,
        curTab: '1',
      },
      data: {
        optionList: [],
      }
    }
  },
  mounted() {
    this.fetchBannerList();
    this.fetchHotItemList();
  },
  methods: {
    // 加载bannerlist表
    async fetchBannerList() {
      let res = await this.$api.bannerListMap();
      if(res) {
        this.form.indexBanners = res.data.indexBanners;
        this.form.toLearnBanners = res.data.toLearnBanners;
        this.form.toTryBanners = res.data.toTryBanners;
        this.form.geneBanners = res.data.geneBanners;
        this.form.evaluationBanners = res.data.evaluationBanners;
        this.form.pregnantBanners = res.data.pregnantBanners;
        this.form.jobBanners = res.data.jobBanners;
        this.form.vaccineBanners = res.data.vaccineBanners;
        this.form.agentBanners = res.data.agentBanners;
        this.form.newsBanners = res.data.newsBanners;
      }
    },
    // 加载热门配置信息数据
    async fetchHotItemList() {
      let res = await this.$api.hotItemListMap();
      if (res) {
        // this.form.milkList = res.data.milkList;
        this.form.childMilkList = res.data.childMilkList;
        this.form.teenMilkList = res.data.teenMilkList;
        this.form.adultMilkList = res.data.adultMilkList;
        this.form.assistList = res.data.assistList;
        this.form.dipperList = res.data.dipperList;
        this.form.nurtureList = res.data.nurtureList;
        this.form.evaluationList = res.data.evaluationList;
        this.form.milkBrandList = res.data.milkBrandList;
        this.form.assistBrandList = res.data.assistBrandList;
        this.form.nurtureBrandList = res.data.nurtureBrandList;
        this.form.evaluationEnrollList = res.data.evaluationEnrollList;
        this.form.searchMilkList = res.data.searchMilkList;
        this.form.searchAssistList = res.data.searchAssistList;
        this.form.searchFurtureList = res.data.searchFurtureList;
        this.form.searchDipperList = res.data.searchDipperList;
      }
    },
    onTabChangeHandler(curTab) {
      if (this.state.curTab === curTab) {
        return;
      }
      this.state.curTab = curTab;
    },
    // 加载数据列表
    async fetchSelectedItemList(type, keyword) {
      if (this.form.curHotType !== type) {
        this.page.current = 1;
      }
      this.form.curHotType = type;
      // 1牛奶，2辅食，3营养品，4评测，5奶粉品牌，6辅食品牌，7营养品品牌，8评测, 10热搜奶粉，11热搜辅食，12热搜营养品, 13 热搜纸尿裤
      // 101 婴幼儿奶粉，102儿童奶粉，103中老年奶粉，40纸尿裤, 50液奶
      let {current, pageSize} = this.page;
      if ([1,2,3,40,50,101,102,103].indexOf(type) > -1) {
        let res = await this.$api.productPage({pageNow: current, pageSize, productType: type, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
            .map(t => ({id: t.id, faceUrl: t.faceUrl, title: t.productName, no: t.registerNo}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      } else if (type === 4 || type === 25) {
        let res = await this.$api.evaluationPage({pageNow: current, pageSize, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
            .map(t => ({id: t.id, faceUrl: t.faceUrl, title: t.productName}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      } else if ((type >=5 && type <=7)|| type === 51) {
          let productType = type - 4;
          if(type === 51) productType = 50;
        let res = await this.$api.brandPage({pageNow: current, pageSize, productType, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
            .map(t => ({id: t.id, faceUrl: t.logoUrl, title: t.name}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      } else if (type === 8) {
        let res = await this.$api.evaluationEnrollPage({pageNow: current, pageSize, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
            .map(t => ({id: t.id, faceUrl: t.headImageUrl, title: t.userName, no: t.commentContent}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      }else if (type >= 10 && type <= 13) {
        let res = await this.$api.brandPage({pageNow: current, pageSize, productType: type-9, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
            .map(t => ({id: t.id, faceUrl: t.logoUrl, title: t.name}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      }else if (type >= 21 && type <= 23) {
        let res = await this.$api.brandPage({pageNow: current, pageSize, productType: type-20, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
            .map(t => ({id: t.id, faceUrl: t.logoUrl, title: t.name}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      } else if (type === 24) {
        let res = await this.$api.goodsPage({pageNow: current, pageSize, curTab: 1, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
            .map(t => ({id: t.id, faceUrl: t.logoUrl, title: t.goodsName, no: t.introduction}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      }
    },
    // 选择商品
    async showChooseDialog(type, curItem, afterTrigger) {
      this.form.modelName = ['奶粉', '辅食', '营养品', '评测', '奶粉品牌', '辅食品牌', '营养品品牌', '评论', ''
        , '热搜奶粉', '热搜辅食', '热搜营养品'][type - 1];
        if (type === 50) {
            this.form.modelName = '液奶';
        }else if(type === 51) {
            this.form.modelName = '液奶品牌';
        }
      if (type > 20) {
        this.form.modelName = ['奶粉', '辅食', '营养品', '试用', '评测'][type - 21];
      }
      if (type === 40) {
        this.form.modelName = '纸尿裤';
      }
      if (type >= 101 && type <= 103) {
        this.form.modelName = ['婴幼儿奶粉', '儿童奶粉', '中老年奶粉'][type - 101];
      }
      this.state.showSelectDialog = true;
      this.form.keyword = '';
      this.state.isMultiSelect = !curItem;
      this.form.curBannerAfterTrigger = afterTrigger;
      this.form.curBannerItem = curItem;
      // 打开商品选择界面
      this.fetchSelectedItemList(type);
    },
    // 是否选中
    isChecked(b) {
      if (this.state.isMultiSelect) {
        let checkedList = this.getCheckedList(this.form.curHotType);
        for (let i = 0; i < checkedList.length; i++) {
          if (checkedList[i].id === b.id) {
            b.checked = true;
            return true;
          }
        }
        return false;
      } else {
        return this.form.curBannerItem && this.form.curBannerItem.linkTargetId === b.id;
      }
    },
    getCheckedList(type) {
      let checkedList = null;
      if (type === 1) {
        checkedList = this.form.milkList;
      }else if (type === 2) {
        checkedList = this.form.assistList;
      }else if (type === 3) {
        checkedList = this.form.nurtureList;
      }else if (type === 4) {
        checkedList = this.form.evaluationList;
      }else if (type === 5) {
        checkedList = this.form.milkBrandList;
      }else if (type === 6) {
        checkedList = this.form.assistBrandList;
      }else if (type === 7) {
        checkedList = this.form.nurtureBrandList;
      }else if (type === 8) {
        checkedList = this.form.evaluationEnrollList;
      }else if (type === 10) {
        checkedList = this.form.searchMilkList;
      }else if (type === 11) {
        checkedList = this.form.searchAssistList;
      }else if (type === 12) {
        checkedList = this.form.searchFurtureList;
      }else if (type === 13) {
        checkedList = this.form.searchDipperList;
      }else if (type === 40) {
        checkedList = this.form.dipperList;
      }else if (type === 101) {
        checkedList = this.form.childMilkList;
      }else if (type === 102) {
        checkedList = this.form.teenMilkList;
      }else if (type === 103) {
        checkedList = this.form.adultMilkList;
      }
      // banner部分：21奶粉，22辅食，23营养品，24，助力商品，25，评测
      // else if (type >= 21 && type <= 25) {
      //
      //   }
      return checkedList;
    },
    onOptionChoosedHandler(b) {
      // 先检查是否选择
      if (this.state.isMultiSelect) {
        let checkedList = this.getCheckedList(this.form.curHotType);
        if (this.isChecked(b)) {
          for (let i = 0; i < checkedList.length; i++) {
            if (checkedList[i].id === b.id) {
              checkedList.splice(i, 1);
              break;
            }
          }
        } else {
          checkedList.push(b);
        }
        console.log('checkedList', checkedList);
      } else {
        if (this.form.curBannerItem) {
          this.form.curBannerItem.linkTargetId = b.id;
        }
      }
    },
    // 选中商品
    async onConfirmSelectedHandler() {
      if (this.state.isMultiSelect) {
        let checkedList = this.getCheckedList(this.form.curHotType);
        let checkedIds = checkedList.map(t => t.id);
        await this.$api.hotItemsBatchUpdate(this.form.curHotType, checkedIds);
        this.fetchHotItemList();
      } else {
        this.form.curBannerAfterTrigger();
      }
      this.form.keyword = '';
      this.state.showSelectDialog = false;
    },
    // 移除商品
    async onRemoveChoosedHandler(b, type) {
      let checkedList = this.getCheckedList(type);
      for (let i = 0; i < checkedList.length; i++) {
        if (b.id === checkedList[i].id) {
          await this.$api.hotItemsRemoveBy({itemId: b.id, type: type});
          checkedList.splice(i, 1);
        }
      }
    },
    // 翻页
    onDataListChange(current) {
      console.log('表格列表发生了变更: ', current, this.form.curHotType, this.form.keyword);
      this.page.current = current;
      this.fetchSelectedItemList(this.form.curHotType, this.form.keyword);
    },
    onKeywordChange(kw) {
      this.fetchSelectedItemList(this.form.curHotType, kw);
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  padding: 10px;
}
.tab-container {
  padding: 0 15px;

  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #222222;
  }

  .form-block {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .label {
    text-indent: 20px;
  }

}

.item-list {
  display: flex;
  flex-wrap: wrap;
  .item {
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    width: 280px;
    position: relative;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    .triangle-topleft {
      display: none;
    }
    img {
      width: 48px;
      height: 48px;
      margin-right: 10px;
      /* 描边/分割线_EAEAEA */
      border: 1px solid #EAEAEA;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .block {
      .title {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        /* or 133% */
        color: #222222;
      }
      .no {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #888888;
      }
    }
    &.checked {
      border: 1px solid #5A66FF;
      $triangleSize: 30px;
      .triangle-topleft {
        display: inline-block;
        width: 0;
        height: 0;
        border-top: $triangleSize solid #5A66FF;
        border-right: $triangleSize solid transparent;
        position: absolute;
        top: 0;
        left: 0;
        .icon-check {
          position: absolute;
          left:3px;
          top: -28px;
          color: #fff;
        }
      }
    }
  }
}
</style>
