<template>
  <div>
    <a-input v-if="isEdit" v-model="innerValue" :style="{width: (width||300)+'px'}"
             @change="$emit('input', innerValue)" />
    <span v-if="!isEdit">{{value}}</span>
  </div>
</template>

<script>
export default {
  name: "MInput",
  // status: plain 纯文本展示，edit 展示， disabled 禁用
  props: ['value', 'isEdit', 'width'],
  data() {
    return {
      innerValue: this.value || ''
    }
  },
  watch: {
    value() {
      this.innerValue = this.value;
    }
  }
}
</script>

<style scoped>

</style>