<template>
  <div class="page-container">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :rules="rules"
                  labelAlign="left"
                  :wrapper-col="state.wrapperCol">
      <a-row>
        <a-col :span="state.formColSpan">
          <div class="title">评测商品</div>
<!--          <a-form-model-item label="产品名称">-->
<!--            <a-select v-model="form.productId" @change="onGoodsChange">-->
<!--              <a-select-option v-for="(b, idx) in data.goods" :key="idx" :value="b.id">-->
<!--                {{b.productName}}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="产品名">
            <div>{{form.productName}}</div>
          </a-form-model-item>
          <a-form-model-item label="市场价">
            <div>{{form.price}}元</div>
          </a-form-model-item>
          <a-form-model-item label="封面图片">
            <my-upload v-model="form.faceUrl" :disabled="true" :multiple="false" />
          </a-form-model-item>
          <a-form-model-item label="产品图片">
            <my-upload v-model="form.images" :disabled="true" :multiple="true" />
          </a-form-model-item>
          <a-divider />
          <div class="title">报名限制</div>
          <a-form-model-item label="评测名额">
            <div>{{form.limitCount}}</div>
          </a-form-model-item>
          <a-form-model-item label="截止时间">
            <div>{{form.expireDate}}</div>
          </a-form-model-item>
          <a-form-model-item label="流程步骤">
            <evaluation-step-form :disabled="true" style="width: 600px" v-model="form.steps" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space v-if="false">
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import EvaluationStepForm from "@/pages/evaluation/EvaluationStepForm";
import moment from 'moment'
export default {
  name: "BrandForm",
  props: ['id'],
  components: {
    'evaluation-step-form': EvaluationStepForm
  },
  data() {
    return {
      form: {
        id: this.id,
        productId: '',
        productType: '',
        productName: '',
        price: '',
        faceUrl: '',
        images: [],
        limitCount: '',
        expireDate: '',
        expireDateObj: '',
        commentEndTime: '',
        commentEndTimeObj: '',
        steps: [],
      },
      rules: {  },
      state: {
        formColSpan: 22,
        labelCol: {span: 2, offset: 1},
        wrapperCol: {span: 18},
      },
      data: {
        types: [],
        goods: []
      }
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      await this.fetchTypeConditionList();
      // await this.fetchGoodsList();
      if (!this.isAddForm) {
        // 加载数据
        this.fetchEntity();
      }
    },
    // 获取分类的数据
    async fetchTypeConditionList() {
      let res = await this.$api.commonTypeByType(1);
      if (res.code === 0) {
        this.data.types = res.list
      }
    },
    // 获取商品数据
    async fetchGoodsList() {
      let res = await this.$api.productListAll();
      if (res.code === 0) {
        this.data.goods = res.list
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.evaluationUnqiue({id: this.$route.query.id});
      console.log('evalutaion-fetchentity: ', this.id, res);
      if(res.data) {
        let {productId, productType, productName, price, faceUrl, images, limitCount, expireDate, commentEndTime, steps, id} = res.data;
        let expireDateObj = moment(expireDate);
        let commentEndTimeObj = moment(commentEndTime);
        this.form = {id, productId, productType, productName, price, faceUrl, images, limitCount
          , expireDate: expireDate?expireDate.substr(0, 10):'', expireDateObj
          , commentEndTime: commentEndTime?commentEndTime.substr(0, 10): '', commentEndTimeObj, steps};
      }
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,};
      console.log('evaluation-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.evaluationAddOrSave(form);
      if (res.code === 0) {
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
    onExpiredDateChange() {
      this.form.expireDate = this.form.expireDateObj.format('YYYY-MM-DD');
    },
    // 商品
    onGoodsChange() {
      // 找到商品
      this.data.goods.forEach(t => {
        if (t.id === this.form.productId) {
          this.form.productName = t.productName;
          this.form.productType = t.productType;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
  .ant-form-item-control {
    width: 260px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
.page-container .ant-form-item-control {
  width: 800px!important;
}
</style>
