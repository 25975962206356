<template>
  <a-layout>
    <a-layout-header class="header">
      <a-row align="middle" type="flex" style="height: 50px">
        <a-col class="logo-part">
          <img src="../assets/images/logo.png" alt="">
<!--          <span>后台管理系统</span>-->
        </a-col>
        <a-col flex="1"  class="menu-part">
          <menu-item v-for="(m, index) in menuList"
                     :key="index" :active="m.active"
                     :name="m.name" :url="m.url" :children="m.children"
                     :index="index"
                     v-on:menu-changed="onMenuChangeHandler"
          ></menu-item>
        </a-col>
        <a-col class="dropdown-part">
          <strong>管理员</strong>
          <a-icon type="caret-down" />
        </a-col>
      </a-row>
    </a-layout-header>
    <a-layout-content class="content">
      <div class="bread-wrap" v-if="routeStack.length > 0">
        <a-breadcrumb>
          <a-breadcrumb-item v-for="(b, idx) in routeStack"
                             :key="idx"
                             :class="{'bread': !b.isCur, 'curBred': b.isCur}">
            <span v-if="b.isCur">{{ b.name }}</span>
            <router-link v-if="!b.isCur" :to="b.url">{{b.name}}</router-link>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <router-view></router-view>
    </a-layout-content>
    <a-layout-footer class="footer-wrap">
      Copyright © 2020 - 2020. All Rights Reserved. 奶查版权所有
    </a-layout-footer>
  </a-layout>
</template>

<script>
export default {
  name: "Main",
  data(){
    return {
      // 表单
      form: {},
      // 状态
      state: {
        viewClass: ''
      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0 },
      // 列表数据
      menuList: [
        {name: '首页', active: true, url: '/dashboard'},
        {name: '查产品', active: false, url: '/product', children: [
            {name: '品牌发布', active: false, url: '/settings/brand'},
            {name: '查奶粉', active: false, url: '/product?type=1'},
            {name: '查辅食', active: false, url: '/product?type=2'},
            {name: '查营养品', active: false, url: '/product?type=3'},
            {name: '查纸尿裤', active: false, url: '/product?type=4'},
            {name: '查液奶', active: false, url: '/product?type=5'},
            {name: '评论管理', active: false, url: '/productComment'},
            {name: '申请合作', active: false, url: '/enrollApply/product'},
          ]},
        {name: '查门店', active: false, url: '/store', children: [
            {name: '查门店', active: false, url: '/store'},
            {name: '申请入驻', active: false, url: '/enrollApply/store'},
          ]},
        {name: '查理商', active: false, url: '/proxy', children: [
            {name: '发布代理商', active: false, url: '/proxy'},
            {name: '申请入驻', active: false, url: '/enrollApply/agent'},
            {name: '申请合作', active: false, url: '/enrollApply/merchant'},
          ]},
        {name: '查疫苗', active: false, url: '/vaccine', children: [
            {name: '疫苗批次', active: false, url: '/vaccine'},
            {name: '疫苗解读', active: false, url: '/vaccineMeta/vaccineMetaList'},
          ]},
        {name: '查孕检', active: false, url: '/pregnantCheck/pregnantCheckList'},
        {name: '查胎动', active: false, url: '/setting/fedal'},
        {name: '查B超', active: false, url: '/pregnantCheck/pregnantCheckList', children: [
            {name: 'B超解读', active: false, url: '/pregnantBxIndex/setting'},
            {name: 'B超指标', active: false, url: '/pregnantBxIndex/pregnantBxIndexList'},
          ]},
        // {name: '评测', active: false, url: '/evaluation'},
        {name: '查福利', active: false, url:'/goods', children: [
            {name: '基因商品', active: false, url: '/goods?isGene=1'},
            {name: '助力商品', active: false, url: '/goods?isGene=0'},
            {name: '订单', active: false, url: '/order'},
          ]},
        {name: '奶查企服', active: false, url: '/qfConsult/list', children: [
            {name: '奶查企服', active: false, url: '/setting/qf'},
            {name: '合作咨询', active: false, url: '/qfConsult/list'},
          ]},
        {name: '查招聘', active: false, url: '/recruit', children: [
            {name: '公司', active: false, url: '/recruitCompany/recruitCompanyList'},
            {name: '工作', active: false, url: '/recruitJobs/recruitJobsList'},
            {name: '职位发布', active: false, url: '/recruitJobs/recruitJobsForm'},
            {name: '招聘咨询', active: false, url: '/recruit/consult/list'},
          ]},
        // {name: '会员', active: false, url: '/vips'},
        // {name: '留言', active: false, url: '/posts'},
        {name: '文章', active: false, url: '/article', children: [
            {name: '育儿知识', active: false, url: '/article?category=1'},
            {name: '产品评测', active: false, url: '/article?category=2'},
            {name: '品牌资讯', active: false, url: '/article?category=3'},
            {name: '奶查资讯', active: false, url: '/article?category=4'},
            {name: '文章分类', active: false, url: '/article/articleType'},
          ]},
        {name: '设置', active: false, url: '/settings', children: [
            {name: 'banner', active: false, url: '/setting/banner'},
            {name: '热门设置', active: false, url: '/setting/hotItem'},
            {name: '热搜设置', active: false, url: '/setting/hotSearch'},
            {name: '启动页设置', active: false, url: '/appSplash'},
          ]},
      ],
      lastIdx: 0,
    }
  },
  mounted() {

  },
  computed: {
    routeStack() {
      return this.$store.state.routeStack;
    },
    curRouteStack() {
      return this.$store.state.curRouteIndex;
    }
  },
  methods: {
    onMenuChangeHandler(index) {
      if(this.lastIdx === index) {
        return ;
      }
      let menu = this.menuList[index];
      let lastMenu = this.menuList[this.lastIdx];
      lastMenu.active = false;
      menu.active = true;
      console.log('menu change: '+index, lastMenu, menu);
      this.lastIdx = index;
      if (!menu.children) {
        this.$router.replace(menu.url);
      }
    }
  },

}
</script>
<style lang="scss">
body {
  background: #F1F2F1;
}
.page-container {
  .ant-form-item-control {
    width: 400px!important;
    .ant-input {
      width: 400px;
    }
  }
  .full-text-editor .ant-form-item-control {
    width: 100%!important;
  }
}

.ant-form-item-label > label {
  color: #888888!important;
}
$primaryColor: #5A66FF!important;
.ant-btn-primary {
  background: $primaryColor;
  border-color: $primaryColor;
  min-width: 88px;
}
.ant-btn-link {
  color: $primaryColor;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: $primaryColor;
  color: $primaryColor;
  box-shadow: -1px 0 0 0 $primaryColor;
}
.ant-radio-button-wrapper:hover {
  color: $primaryColor;
}
.menu-item {
  color: #222222!important;
  &.active {
    color: $primaryColor;
  }
}

.bread-wrap {
  margin-bottom: 15px;
  .bread {
    font-size: 17px;
    color: #888888;
  }
  .curBred {
    font-size: 18px;
    color: #222222;
  }
}
.ant-select-dropdown {
  z-index: 10004!important;
}
</style>
<style lang="scss" scoped>
$header-height: 70px;
.header {
  height: $header-height;
  background: #FFFFFF;
  padding: 0 10px;
  /* 分割线/线_下 */
  box-shadow: inset 0px -1px 0px #EAEAEA;
  .logo-part {
    height: $header-height;
    line-height: $header-height;
    img {
      height: 30px;
      margin-right: 10px;
    }
    span {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      color: #222222;
    }
  }
  .menu-part {
    height: $header-height;
    display: flex;
  }
  .dropdown-part {
    height: $header-height;
    line-height: $header-height;
    cursor: pointer;
  }

}
.content {
  min-height: 300px;
  width: 90%;
  margin: 15px auto;
}
.footer-wrap {
  height: 40px;
  text-align: center;
}

</style>
