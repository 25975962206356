<template>
  <div class="page-container">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :rules="rules"
                  labelAlign="left"
                  :wrapper-col="state.wrapperCol">
      <a-row>
        <a-col :span="state.formColSpan">
          <a-form-model-item label="名称">
            <a-input v-model="form.name" placeholder="如：首检套餐" />
          </a-form-model-item>
          <a-form-model-item label="怀孕周">
            <a-input v-model="form.pregnantWeeks" placeholder="如：第3周到第6周" />
          </a-form-model-item>
          <a-form-model-item label="简介">
            <a-textarea
               style="width: 400px"
              v-model="form.description"
              placeholder="请输入介绍"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
          <a-form-model-item label="温馨提示">
            <a-textarea
              style="width: 400px"
              v-model="form.warnings"
              placeholder="请输入温馨提示"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
          <a-form-model-item label="检查目的">
            <wang-editor
              v-model="form.checkTarget"
              placeholder="请输入温馨提示"
            />
          </a-form-model-item>
          <a-form-model-item label="检查前准备">
            <wang-editor
              v-model="form.checkPreparation"
              placeholder="请输入温馨提示"
            />
          </a-form-model-item>
          <a-divider orientation="left">
            详情维护
          </a-divider>
          <a-form-model-item label="检查项">
            <pregnant-check-item-form-item v-model="form.checkItems" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import PregnantCheckItemFormItem from "./PregnantCheckItemFormItem";
export default {
  name: "VaccineMetaForm.vue",
  components: {
    PregnantCheckItemFormItem
  },
  data() {
    return {
      //provinceId=310000, cityId=310100, countyId=310113
      form: {
        // 门店信息
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        name: '',
        pregnantWeeks: '',
        description: '',
        warnings: '',
        checkPreparation: '',
        checkTarget: '',
        checkItems: [],
      },
      rules: {  },
      state: {
        activeKey: '0',
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 18},
      },
    };
  },
  mounted() {
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: '孕检列表', isCur: false, url: '/store'},
      {name: '发布孕检套餐', isCur: true, url: ''},
    ]);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      await this.fetchBrandList();
      if (!this.isAddForm) {
        // 加载数据
        this.fetchEntity();
      }
    },
    // 获取品牌列表
    async fetchBrandList() {
      // let res = await this.$api.brandListAll();
      // console.log('store-fetchBrandList: ', res);
      // if(res.list) {
      //   this.data.brands = res.list;
      // }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.pregnantCheckUnqiue({id: this.$route.query.id});
      console.log('store-fetchentity: ', this.$route.query.id, res);
      if(res.data) {
        console.log('store-fetchentity-result: ', res.data)
        this.form = res.data;
      }
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,};
      console.log('store-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.pregnantCheckAddOrSave(form);
      if (res.code === 0) {
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  $input-width: 300px;
  .ant-input {
    width: $input-width;
  }
  .ant-select {
    width: $input-width;
  }
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
</style>
