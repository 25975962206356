<template>
  <div class="block-two">
    <h3>{{title}}</h3>
    <a-row type="flex" align="middle" justify="space-around" class="block-line">
      <a-col class="block-col">
        <div class="title">{{t1}}</div>
        <div :class="{'num': true, 'red': showRed}">{{formattedT1}}</div>
      </a-col>
      <a-col class="block-col">
        <div class="title">{{t2}}</div>
        <div :class="{'num': true}">{{formattedT2}}</div>
      </a-col>
    </a-row>
    <a-divider v-if="showDivider" type="vertical" class="divider"></a-divider>
  </div>
</template>

<script>
export default {
  name: "DashBlockTwo",
  props: ['title', 't1', 't2', 'num1', 'num2', 'red1', 'divider'],
  computed: {
    formattedT1() {
      return parseFloat(this.num1 || 0).toLocaleString();
    },
    formattedT2() {
      return parseFloat(this.num2 || 0).toLocaleString();
    },
    showDivider() {
      return !!this.divider;
    },
    showRed() {
      return !!this.red1;
    }
  }
}
</script>

<style lang="scss" scoped>
.block-two {
  position: relative;
  margin-bottom: 20px;
  h3 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .block-line {
    padding-left: 40px;
    padding-right: 40px;
    .block-col {
      width: 100px;
      text-align: center;
      .title {
        height: 30px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */
        text-align: center;

        /* 文字颜色/黑_222 */
        color: #222222;
      }
      .num {
        /* data-32 加粗 */
        font-family: DIN Alternate;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 150%;
        /* identical to box height, or 48px */
        text-align: center;

        /* 文字颜色/黑_222 */
        color: #222222;
      }
      .red {
        color: #E54545;
      }
    }
  }
  .divider {
    height: 100px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
