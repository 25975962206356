<template>
  <a-select style="width: 200px" v-model="innerValue"
            :showSearch="true"
            :filterOption="(inputValue, option) => option.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0">
    <a-select-option v-for="(b, idx) in list" :key="idx" :value="b.id">{{b.name}}</a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "BrandFormItem",
  props: ['value'],
  data() {
    return {
      list: [],
      innerValue: this.value
    }
  },
  mounted() {
    this.innerValue = this.value;
    this.fetchData();
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
    innerValue() {
      this.$emit('input', this.innerValue);
    }
  },
  methods: {
    async fetchData() {
      // 需要修改服务器端的查询
      let {list} = await this.$api.brandListAll();
      if (list) {
        this.list = list;
      }
    }
  }
}
</script>

<style scoped>

</style>