<template>
  <div>
    <div class="list1">
      <div class="item1" v-for="(b, idx) in form.list"
           @dragstart="ev => ev.dataTransfer.setData('Text', ev.target.getAttribute('index'))"
           :index="idx"
           @drop="exchangeNode"
           @dragover="ev => ev.preventDefault()"
           draggable="true"
           :key="idx">
        <div v-if="b.imageUrl" @click="onChooseImageHandler(b)" class="upload-img"
             :index="idx"
             @drop="exchangeNode">
          <img :src="b.imageUrl" />
          <div class="mask">更换图片</div>
        </div>
        <div v-if="!b.imageUrl" @click="onChooseImageHandler(b)" class="upload-img1"
             :index="idx"
             @drop="exchangeNode">
          <a-icon type="plus" />
          <p>添加图片</p>
        </div>
        <div class="button-group"
             :index="idx"
             @drop="exchangeNode">
          <span class="txt">跳转链接</span>
          <a-tag v-if="b.label" class="tag" @close="b.label=null" :closable="true">{{ b.label }}</a-tag>
          <a-cascader v-if="!b.label" @change="(_, ops) => onTypeChangeHandler(b, ops[ops.length-1], _)"
                      v-model="b.selectedItem"
                      class="cas-select"
                      :options="data.bannerOptions" size="small"
                      placeholder="跳转的页面" />
        </div>
        <a-icon type="swap" class="swap-button" />
      </div>
    </div>
    <!--  修改h5链接  -->
    <a-modal v-model="state.showH5PageForm" title="H5页面"
             okText="确定"
             cancelText="取消"
             @ok="onSubmitH5PageForm">
      <a-form-model
          :label-col="state.labelCol"
          labelAlign="left"
          :wrapper-col="state.wrapperCol">
        <a-row>
          <a-col :span="state.formColSpan">
            <a-form-model-item label="H5路径">
              <a-input v-model="form.curItem.linkUrl" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <!--  修改小程序链接  -->
    <a-modal v-model="state.showLittleAppForm" title="小程序页面"
             okText="确定"
             cancelText="取消"
             @ok="onSubmitLittleAppForm">
      <a-form-model
          :label-col="state.labelCol"
          labelAlign="left"
          :wrapper-col="state.wrapperCol">
        <a-row>
          <a-col :span="state.formColSpan">
            <div v-if="form.curItem.linkType === 2" class="warning-txt">使用该功能需要先配置小程序App名单</div>
            <a-form-model-item label="页面">
              <a-radio-group name="linkType" v-model="form.curItem.linkType">
                <a-radio :value="1">链接到我的小程序</a-radio>
                <a-radio :value="2">链接到其他小程序</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item v-if="form.curItem.linkType === 2" label="小程序AppID">
              <a-input v-model="form.curItem.linkTargetAppid" />
            </a-form-model-item>
            <a-form-model-item v-if="form.curItem.linkType === 1 || form.curItem.linkType === 2" label="小程序路径">
              <a-input v-model="form.curItem.linkUrl" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "BannerConfig",
  props: ['size', 'list', 'position'],
  watch: {
    list(oldVal, newVal) {
      if (oldVal === newVal) {
        return ;
      }
      this.form.list = this.list;
      for (let i = this.form.list.length; i < this.size; i++) {
        this.form.list.push({imageUrl: null, position: this.position
          , type: null, label: null, linkTargetId: null, linkUrl: null, sort: this.form.list.length
          , linkTargetAppid: null, linkType: null, selectedItem: []});
      }
    }
  },
  data() {
    return {
      form: {
        list: [],
        curItem: {}
      },
      state: {
        showH5PageForm: false,
        showLittleAppForm: false,
        formColSpan: 24,
        labelCol: {span: 5, offset: 1},
        wrapperCol: {span: 16},
      },
      data: {
        bannerOptions: [
          {value: 1, label: '系统链接', children: [
              {value: 101, label: '首页'},
              {value: 102, label: '不学就会'},
              {value: 103, label: '不信就试'},
              {value: 104, label: '附近门店'},
              {value: 105, label: '个人中心'},
            ]},
          {value: 2, label: '自定义路径', children: [
              {value: 201, label: 'H5页面'},
              {value: 202, label: '小程序页面'},
            ]},
          {value: 4, label: '奶粉'},
          {value: 5, label: '辅食'},
          {value: 50, label: '液奶'},
          {value: 6, label: '营养品'},
          {value: 9, label: '纸尿裤'},
          {value: 7, label: '试用'},
          {value: 8, label: '评测'},
        ]
      }
    }
  },
  methods: {
    // 选择图片
    async onChooseImageHandler(item) {
      let that = this;
      this.form.curItem = item;
      console.log('添加图片: ', item);
      let inputTag = document.createElement('input');
      inputTag.type = 'file';
      inputTag.accept = 'image/*';
      inputTag.onchange = async function() {
        console.log('添加图片: ', inputTag.files);
        if (inputTag.files.length > 0) {
          let file = inputTag.files[0];
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function(e){
            item.imageUrl = e.target.result;
          }
          // 上传图片
          let res = await that.$api.publicUpload({file});
          if(res) {
            item.imageUrl = res.data.url;
            that.submitForm();
          }
        }
      };
      inputTag.click();
    },
    // 类别变动
    onTypeChangeHandler(item, selectedItems) {
      // let selectedOption = selectedOptions[0];
      item.type = selectedItems.value;
      item.label = selectedItems.label;
      this.form.curItem = item;
      if(item.type === 201) {
        // h5页面
        this.state.showH5PageForm = true;
      }else if (item.type === 202) {
        // 小程序页面
        this.state.showLittleAppForm = true;
      } else {
        console.log('修改type: ', JSON.stringify(item));
        this.form.curItem.linkType = item.type;
        switch (item.type) {
          case 101: this.form.curItem.linkUrl = 'pages/index/index'; break;
          case 102: this.form.curItem.linkUrl = 'pages/learn/index'; break;
          case 103: this.form.curItem.linkUrl = 'pages/mall/index'; break;
          case 104: this.form.curItem.linkUrl = 'pages/store/index'; break;
          case 105: this.form.curItem.linkUrl = 'pages/my/index'; break;
          case 4:
            this.form.curItem.linkUrl = 'pages/brand/detail';
            this.$emit('choose', 21, this.form.curItem, () => this.submitForm());
            break;
          case 5:
            this.form.curItem.linkUrl = 'pages/brand/detail';
            this.$emit('choose', 22, this.form.curItem, () => this.submitForm());
          break;
          case 6:
            this.form.curItem.linkUrl = 'pages/brand/detail';
            this.$emit('choose', 23, this.form.curItem, () => this.submitForm());
          break;
          case 7:
            this.form.curItem.linkUrl = 'pages/invite';
            this.$emit('choose', 24, this.form.curItem, () => this.submitForm());
            break;
          case 8:
            this.form.curItem.linkUrl = 'pages/evaluation/index';
            this.$emit('choose', 25, this.form.curItem, () => this.submitForm());
          break;
          case 50:
            this.form.curItem.linkUrl = 'pages/liquidMilk/index';
            this.$emit('choose', 50, this.form.curItem, () => this.submitForm());
          break;
        }
        if (item.type < 3 || item.type > 7) {
          this.submitForm();
        }
      }
    },
    // h5页面
    onSubmitH5PageForm() {
      this.state.showH5PageForm = false;
      this.submitForm();
    },
    // 小程序页面
    onSubmitLittleAppForm() {
      this.state.showLittleAppForm = false;
      this.submitForm();
    },
    // 提交表单
    async submitForm() {
      console.log('提交banner配置: ', JSON.stringify(this.form.curItem));
      this.$api.bannerBatchAddOrSave({list: this.form.list});
    },
    exchangeNode(ev) {
      let idx1 = ev.target.getAttribute('index');
      let idx2 = ev.dataTransfer.getData('Text');
      let tmpArr = [...this.list];
      let tmp = tmpArr[idx1];
      tmpArr[idx1] = tmpArr[idx2];
      tmpArr[idx2] = tmp;
      tmpArr.forEach((t, idx) => t.sort = idx);
      this.list.splice(0);
      this.list.push(...tmpArr);
      this.submitForm();
    }
  }
}
</script>

<style lang="scss" scoped>
// banner图编辑样式
.list1 {
  display: flex;
  .item1 {
    width: 240px;
    padding: 20px 10px;
    margin-right: 10px;
    cursor: move;
    background: #F5F5F5;
    border-radius: 4px;
    font-size: 12px;
    position: relative;
    .swap-button {
      position: absolute;
      right: 2px;
      top: 0px;
      display: none;
      font-size: 16px;
      color: #333333;
      text-shadow: 0 0 5px #fff;
    }
    .upload-img {
      position: relative;
      cursor: pointer;
      margin-bottom: 8px;
      img {
        width: 100%;
        height: 90px;
      }
      .mask {
        position: absolute;
        z-index: 100;
        background: rgba(0,0,0,0.5);
        bottom: 0;
        left: 0;
        height: 20px;
        width: 100%;
        color: #fff;
        font-size: 12px;
        text-align: center;
      }
    }
    .upload-img1 {
      cursor: pointer;
      margin-bottom: 8px;
      width: 100%;
      height: 90px;
      padding-top: 30px;
      background: #fff;
      text-align: center;
      color: #5A66FF;
      .plus {

      }
    }
    .button-group {
      display: flex;
      line-height: 20px;
      .txt {
        padding-right: 8px;
        color: #333;
        line-height: 22px;
      }
      .tag {
        height: 22px;
      }
      .cas-select {
        width: 100px;
        height: 22px;
      }
    }
  }
}
.warning-txt {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 42px;
  background: #FFF4E3;;
  color: #C07400;
  text-indent: 10px;
  line-height: 42px;
  font-size: 12px;
}
</style>
