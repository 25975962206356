import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: require("../pages/Login.vue").default },
  { path: "/main", component: require("../pages/Main.vue").default,
    children: [
      {path: '/dashboard', component: require('../pages/Dashboard.vue').default},
      {path: '/product', component: require('../pages/product/Product.vue').default},
      {path: '/productForm', component: require('../pages/product/ProductForm.vue').default},
      {path: '/productView', component: require('../pages/product/ProductView.vue').default},
      {path: '/store', component: require('../pages/store/Store.vue').default},
      {path: '/store/storeForm', component: require('../pages/store/StoreForm.vue').default},
      {path: '/proxy', component: require('../pages/store/Proxy.vue').default},
      {path: '/proxy/proxyForm', component: require('../pages/store/ProxyForm.vue').default},
      {path: '/settings', component: require('../pages/setting/Settings.vue').default,},
      {path: '/settings/brandForm', component: require('../pages/setting/BrandForm.vue').default},
      {path: '/vaccine', component: require('../pages/vaccine/Vaccine.vue').default},
      {path: '/vaccine/vaccineForm', component: require('../pages/vaccine/VaccineForm.vue').default},
      {path: '/article', component: require('../pages/article/Article.vue').default},
      {path: '/article/articleForm', component: require('../pages/article/ArticleForm.vue').default},
      {path: '/article/articleType', component: require('../pages/article/ArticleType.vue').default},
      {path: '/article/articleTypeForm', component: require('../pages/article/ArticleTypeForm.vue').default},
      {path: '/evaluation', component: require('../pages/evaluation/Evaluation.vue').default},
      {path: '/evaluation/evaluationForm', component: require('../pages/evaluation/EvaluationForm.vue').default},
      {path: '/evaluation/detail', component: require('../pages/evaluation/EvaluationDetail.vue').default},
      {path: '/goods', component: require('../pages/goods/Goods.vue').default},
      {path: '/goods/goodsForm', component: require('../pages/goods/GoodsForm.vue').default},
      {path: '/order', component: require('../pages/order/Order.vue').default},
      {path: '/qfConsult/list', component: require('../pages/qfConsult/QfConsultList.vue').default},
      {path: '/recruit/consult/list', component: require('../pages/recruitConsult/RecruitConsultList.vue').default},
      {path: '/order/orderDetail', component: require('../pages/order/OrderDetail.vue').default},
      {path: '/test/tecentMap', component: require('../pages/test/TecentMapTest.vue').default},
      {path: '/productComment', component: require('../pages/productComment/ProductComment.vue').default},
      {path: '/pageConfig', component: require('../pages/setting/page-config/PageConfig.vue').default},
      {path: '/appSplash', component: require('../pages/appSplash/AppSplash.vue').default},
      {path: '/appSplash/appSplashForm', component: require('../pages/appSplash/AppSplashForm.vue').default},
      {path: '/settings/ui', component: require('../pages/setting/UISetting.vue').default},
      {path: '/settings/brand', component: require('../pages/setting/BrandSetting.vue').default},
      {path: '/recruitCompany/recruitCompanyList', component: require('../pages/recruitCompany/RecruitCompanyList.vue').default},
      {path: '/recruitCompany/recruitCompanyForm', component: require('../pages/recruitCompany/RecruitCompanyForm.vue').default},
      {path: '/recruitJobs/recruitJobsList', component: require('../pages/recruitJobs/RecruitJobsList.vue').default},
      {path: '/recruitJobs/recruitJobsForm', component: require('../pages/recruitJobs/RecruitJobsForm.vue').default},
      {path: '/pregnantBxIndex/setting', component: require('../pages/pregnantBxIndex/PregnantBxSetting.vue').default},
      {path: '/pregnantBxIndex/pregnantBxIndexList', component: require('../pages/pregnantBxIndex/PregnantBxIndexList.vue').default},
      {path: '/pregnantBxIndex/pregnantBxIndexForm', component: require('../pages/pregnantBxIndex/PregnantBxIndexForm.vue').default},
      {path: '/pregnantCheck/pregnantCheckList', component: require('../pages/pregnantCheck/PregnantCheckList.vue').default},
      {path: '/pregnantCheck/pregnantCheckForm', component: require('../pages/pregnantCheck/PregnantCheckForm.vue').default},
      {path: '/vaccineMeta/vaccineMetaList', component: require('../pages/vaccineMeta/VaccineMetaList.vue').default},
      {path: '/vaccineMeta/vaccineMetaForm', component: require('../pages/vaccineMeta/VaccineMetaForm.vue').default},
      {path: '/enrollApply/product', component: require('../pages/enrollApply/ProductEnroll.vue').default},
      {path: '/enrollApply/store', component: require('../pages/enrollApply/StoreEnroll.vue').default},
      {path: '/enrollApply/agent', component: require('../pages/enrollApply/AgentEnroll.vue').default},
      {path: '/enrollApply/merchant', component: require('../pages/enrollApply/MerchantEnroll.vue').default},
      {path: '/setting/banner', component: require('../pages/setting/BannerSetting.vue').default},
      {path: '/setting/hotItem', component: require('../pages/setting/HotItemSetting.vue').default},
      {path: '/setting/hotSearch', component: require('../pages/setting/HotSearchSetting.vue').default},
      {path: '/setting/fedal', component: require('../pages/setting/SettingFedal.vue').default},
      {path: '/setting/qf', component: require('../pages/setting/SettingQf.vue').default},
    ]
  },
  // { path: "/login", component: require("../views/Login.vue").default },
  // { path: "/home", component: require("../views/Home.vue").default },
  // { name:"practice",path: "/practice", component: require("../views/Practice.vue").default },
  // {
  //   path: "/mock-conference",
  //   component: require("../views/MockConference.vue").default,
  //   children: [
  //     {
  //       path: "/mock-conference/:id",
  //       component: require("../views/MockConferenceDetail.vue").default,
  //     }
  //   ],
  // },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  routes,
});

export default router;
