<template>
  <a-radio-group class="wrap" :value="groupVal" @change="onButtonGroupChange">
    <a-radio-button value="all" @click="onChooseValue(-1)">全部</a-radio-button>
    <a-radio-button value="30" @click="onChooseValue(30)">近30天</a-radio-button>
    <a-radio-button value="0" @click="onChooseValue(0)">今天</a-radio-button>
    <a-radio-button value="1" @click="onChooseValue(1)">昨天</a-radio-button>
    <a-radio-button style="z-index: 3" value="7" @click="onChooseValue(7)">近7天</a-radio-button>
    <a-range-picker class="picker" v-model="dateval" :placeholder="['请选择开始日期', '请选择结束日期']" @change="onDateChange"></a-range-picker>
  </a-radio-group>
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import moment from 'moment';
const TRIGGER_DATE_CHANGE = 'change';
export default {
  name: "DateGroupPicker",
  props: ['startDate', 'endDate'],
  data() {
    return {
      locale,
      moment,
      state: {
        // 选中的按钮
        activeDay: 0
      },
      dateval: [],
      groupVal: 'all'
    }
  },
  methods: {
    onDateChange(date, dateStr) {
      // console.log('日期发送了改变： ', date, dateStr, this.dateval);
      this.$emit(TRIGGER_DATE_CHANGE, ...dateStr);
    },
    onChooseValue(day=-1) {
      if(day === -1) {
        this.dateval = [];
      }else {
        let endDate = moment();
        let startDate = endDate.clone().subtract(day, 'days');
        this.dateval = [startDate, endDate];
      }
      this.$emit(TRIGGER_DATE_CHANGE, ...this.dateval.map(t => moment(t).format('YYYY-MM-DD')))
    },
    onButtonGroupChange(e) {
      this.groupVal = e.target.value;
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  .picker {
    margin-left: -3px;
  }
}
</style>
