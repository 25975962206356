import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    count: 0,
    token: '',
    isLoading: false,
    isUploading: false,
    uploadPercent: 0,
    editorValue: '',
    routeStack: [],
    secondTabIdx: 0,
    curRouteIndex: 0,
    user:{}
  },
  mutations: {
    pushRouteStack(state, list) {
      state.routeStack.splice(0);
      state.routeStack.push(...list);
    },
    clearRouteStack(state) {
      state.routeStack.splice(0);
    },
    changeCurRouteStack(state, index) {
      state.curRouteIndex = index;
    },
    changeSecondTabIdx(state, idx) {
      state.secondTabIdx = idx;
    },
    setUser(state,user){
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    clearUser(state) {
      state.user = {};
    },
    startLoading(state) {
      state.isLoading = true;
    },
    closeLoading(state) {
      state.isLoading = false;
    },
    startUpload(state){
      state.isUploading = true;
      state.uploadPercent = 0;
    },
    uploadProgress(state, percent) {
      state.uploadPercent = percent;
    },
    endUpload(state) {
      state.isUploading = false;
      state.uploadPercent = 0;
    }
  },
});

export default store;
