<template>
  <div class="page-container1">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :rules="rules"
                  labelAlign="left"
                  :wrapper-col="state.wrapperCol">
      <div class="title">门店信息</div>
      <a-row>
        <a-col :span="state.formColSpan">
          <a-form-model-item :label="goodsTypeName+'名称'">
            <a-input v-model="form.goodsName" />
            <p class="desc">不超过30个字，例如：椰树牌椰汁 250ml</p>
          </a-form-model-item>
          <a-form-model-item label="商品卖点">
            <a-input v-model="form.sellingPoints" />
            <p class="desc">在商品卡片下面展示卖点信息，最多支持10个字 </p>
          </a-form-model-item>
<!--          <a-form-model-item label="商品类别" v-if="!isGene">-->
<!--            <a-select v-model="form.type" style="width: 400px">-->
<!--              <a-select-option value="1">奶粉</a-select-option>-->
<!--              <a-select-option value="2">辅食</a-select-option>-->
<!--              <a-select-option value="3">营养品</a-select-option>-->
<!--            </a-select>-->
<!--            <p class="desc">请选择商品类别</p>-->
<!--          </a-form-model-item>-->
          <input type="hidden" v-model="form.type" value="1" v-if="!isGene">
          <input type="hidden" v-model="form.type" value="4" v-if="isGene">
          <a-form-model-item label="商品主图">
            <my-upload v-model="form.carousels" :multiple="true" @change="onCarouselsChange" />
            <p class="desc">建议尺寸200*120像素，支持、JPG或JPEG格式图片，大小不超过5MB</p>
          </a-form-model-item>
          <a-form-model-item label="主图视频">
            <my-video-upload v-model="form.videos" :multiple="false" />
          </a-form-model-item>
          <a-form-model-item label="商品分类">
            <a-select v-model="form.goodsTypeId" style="width: 400px">
              <a-select-option v-for="(b,idx) in data.types" :key="idx" :value="b.typeCode">{{b.typeName}}</a-select-option>
            </a-select>
            <p class="desc">请选择商品分类</p>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="title">商品详情</div>
      <a-row>
        <a-col :span="state.formColSpan">
<!--          <a-form-model-item class="full-text-editor" label="详情描述">-->
<!--            <f-summernote v-model="form.content" />-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="详情描述">
            <a-textarea v-model="form.introduction"></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="商品详情图">
            <my-upload v-model="form.introImages" :multiple="true" />
            <p class="desc">最多上传9张，建议尺寸750*750像素，鼠标拖拽图片调整顺序，支持、JPG或JPEG格式图片，大小不超过5MB</p>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="title" v-if="isCommonGoods">规格与库存</div>
      <a-row v-if="isCommonGoods">
        <a-col :span="state.formColSpan">
          <a-form-model-item label="商品规格" v-if="isAddForm">
            <a-radio-group v-model="form.multiSpec">
              <a-radio-button :value="0">
                单规格
              </a-radio-button>
              <a-radio-button :value="1">
                多规格
              </a-radio-button>
            </a-radio-group>
            <br>
            <div class="wrap" v-if="form.multiSpec===1">
              <div class="content-wrap">
                <a-button v-if="false" class="add-btn" @click="onAddSpecHandler">添加规格</a-button>
                <div class="content-list">
                  <div class="content-item" v-for="(sp, idx) in form.specification" :key="idx">
                    <div class="content-head">
                      <a-input v-model="sp.name" :disabled="true" style="width: 180px" />
                      <a-checkbox class="checkbox" @click="e => sp.hasImage=e.target.checked" v-if="sp.couldImage">添加规格图片</a-checkbox>
                      <div class="content-top-right" v-if="false">
                        <a-popconfirm class="trash" ok-text="确定" cancel-text="取消" @confirm="form.specification.splice(idx, 1)">
                          <template slot="title">
                            <p>确定删除该规格？</p>
                          </template>
                          <a-icon type="delete" theme="filled" :style="{color: '#333333'}" />
                        </a-popconfirm>
                      </div>
                    </div>
                    <div class="content-body">
                      <div :class="{'spec-item': true, 'no-image': !sp.hasImage}" v-for="(spv, idx1) in sp.values" :key="idx1">
                        <a-icon class="close" type="close-circle" theme="filled" @click="() => sp.values.splice(idx1, 1)" :style="{color: '#BBBBBB'}" />
                        <a-input class="input" v-model="spv.value" placeholder="规格值"></a-input>
                        <div class="img-upload" @click="onAddSpecImageHandler(spv)" v-if="sp.hasImage && spv.image">
                          <img :src="spv.image" />
                          <div class="mask">更换图片</div>
                        </div>
                        <div class="img-upload-wrap" @click="onAddSpecImageHandler(spv)" v-if="sp.hasImage && !spv.image">
                          <a-icon type="plus" class="plus" />
                          <p class="plus-txt">添加图片</p>
                        </div>
                      </div>
                      <div class="spec-item normal">
                        <a @click="onAddSpecItemHandler(sp)" class="link">+添加规格值</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="desc">如有颜色、尺码等多种规格，请添加商品规格。仅第一个商品规格可以配置图片</div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="价格与库存" v-if="form.multiSpec===1">
            <div class="wrap">
              <div class="stock-head">
                <a-icon type="sync" v-if="isAddForm" style="cursor:pointer;" @click="flushSpecDataToTable" />
                <span class="title">批量设置</span>
                <a-popconfirm class="price-batch-btn" placement="bottom" ok-text="确定" cancel-text="取消" @confirm="onBatchUpdatePrice">
                  <span slot="icon"></span>
                  <template slot="title">
                    <div>
                      <div style="margin-bottom: 10px">
                        <a-space>
                          <a-select v-model="batchPriceForm.color" style="width: 100px">
                            <a-select-option value="">全部颜色</a-select-option>
                            <a-select-option v-for="(b, idx) in form.specification[0].values" :key="idx" :value="b.value">{{ b.value }}</a-select-option>
                          </a-select>
                          <a-select v-model="batchPriceForm.size" style="width: 100px">
                            <a-select-option value="">全部尺寸</a-select-option>
                            <a-select-option v-for="(b, idx) in form.specification[1].values" :key="idx" :value="b.value">{{ b.value }}</a-select-option>
                          </a-select>
                        </a-space>
                      </div>
                      <a-input v-model="batchPriceForm.price" placeholder="请输入价格" style="width: 210px" >
                        <span slot="addonAfter">元</span>
                      </a-input>
                    </div>
                  </template>
                  <a class="link">价格</a>
                </a-popconfirm>
                <a-popconfirm class="stock-batch-btn" placement="bottom" ok-text="确定" cancel-text="取消" @confirm="onBatchUpdateStock">
                  <span slot="icon"></span>
                  <template slot="title">
                    <div>
                      <div style="margin-bottom: 10px">
                        <a-space>
                          <a-select v-model="batchStockForm.color" style="width: 100px">
                            <a-select-option value="">全部颜色</a-select-option>
                            <a-select-option v-for="(b, idx) in form.specification[0].values" :key="idx" :value="b.value">{{ b.value }}</a-select-option>
                          </a-select>
                          <a-select v-model="batchStockForm.size" style="width: 100px">
                            <a-select-option value="">全部尺寸</a-select-option>
                            <a-select-option v-for="(b, idx) in form.specification[1].values" :key="idx" :value="b.value">{{ b.value }}</a-select-option>
                          </a-select>
                        </a-space>
                      </div>
                      <a-input v-model="batchStockForm.stock" placeholder="请输入库存" style="width: 210px" >
                        <span slot="addonAfter">件</span>
                      </a-input>
                    </div>
                  </template>
                  <a class="link">库存</a>
                </a-popconfirm>
              </div>
              <a-table :columns="data.stockAndPriceColumns"
                       :pagination="false"
                       :data-source="data.stockAndPrice"
              >
                <template slot="priceSlot" slot-scope="row">
                  <a-input placeholder="请输入价格" style="width: 160px" v-model="row.price">
                    <span slot="addonAfter">元</span>
                  </a-input>
                </template>
                <template slot="stockSlot" slot-scope="row">
                  <a-input placeholder="请输入库存数量" style="width: 160px" v-model="row.stock">
                    <span slot="addonAfter">件</span>
                  </a-input>
                </template>
                <template slot="faceUrlSlot" slot-scope="faceUrl">
                  <img class="face-url" v-if="faceUrl" :src="faceUrl" alt="">
                  <span v-if="!faceUrl">-</span>
                </template>
              </a-table>
              <br>
            </div>
          </a-form-model-item>
          <a-form-model-item label="价格" v-if="form.multiSpec===0">
            <a-input v-model="form.price">
              <span slot="addonAfter">元</span>
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="库存" v-if="form.multiSpec===0">
            <a-input v-model="form.stockCount">
              <span slot="addonAfter">件</span>
            </a-input>
            <p class="desc">库存为 0 时，会放到「已售罄」的商品列表里，保存后买家看到的商品可售库存同步更新</p>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="title" v-if="isHelpGoods">价格与助力购</div>
      <a-row v-if="isHelpGoods">
        <a-col :span="state.formColSpan">
          <a-form-model-item label="单买价格">
            <a-input v-model="form.price">
              <span slot="addonAfter">元</span>
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="助力购">
            <a-space>
              <span>助力满</span>
              <a-input v-model="form.helpPersonCount" placeholder="请输入人数" style="width: 140px">
                <span slot="addonAfter">人</span>
              </a-input>
              <span>可享</span>
              <a-input v-model="form.helpedPrice" placeholder="请输入价格" style="width: 160px">
                <span slot="addonAfter">元/件</span>
              </a-input>
            </a-space>
          </a-form-model-item>
          <a-form-model-item label="库存">
            <a-input v-model="form.stockCount">
              <span slot="addonAfter">件</span>
            </a-input>
            <p class="desc">库存为 0 时，会放到「已售罄」的商品列表里，保存后买家看到的商品可售库存同步更新</p>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="title">其他信息</div>
      <a-row>
        <a-col :span="state.formColSpan">
          <a-form-model-item label="运费">
            <a-input v-model="form.expressPrice">
              <span slot="addonAfter">元</span>
            </a-input>
            <span style="color: #333; padding-left: 10px">输入0元代表该商品包邮</span>
          </a-form-model-item>
        </a-col>
        <a-col :span="state.formColSpan">
          <a-form-model-item label="购买限制">
            <span style="color: #333; padding-right: 10px">每个ID可购买</span>
            <a-input v-model="form.buyLimitCount" style="width: 100px">
              <span slot="addonAfter">件</span>
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="state.formColSpan">
          <a-form-model-item label="上架时间">
            <a-date-picker style="width: 300px" v-model="form.putOnTimeObj"
                           @change="onPutOnTimeChange"
                           :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                           placeholder="请选择上架时间"></a-date-picker>
          </a-form-model-item>
        </a-col>
        <a-col :span="state.formColSpan">
          <a-form-model-item label="下架时间">
            <a-date-picker style="width: 300px" v-model="form.putOffTimeObj"
                           @change="onPutOffTimeChange"
                           :show-time="{ defaultValue: moment('23:59:59', 'HH:mm:ss') }"
                           placeholder="请选择下架时间"></a-date-picker>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "BrandForm",
  data() {
    return {
      moment,
      form: {
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        sellType: this.$route.query.sellType === 'null' ? '' : this.$route.query.sellType,
        multiSpec: 0,
        goodsName: '',
        sellingPoints: '',
        type: this.$route.query.isGene == 1 ? 4 : '',
        goodsTypeId: '',
        logoUrl: '',
        carousels: [],
        introImages: [],
        videos: '',
        goodsType: '',
        price: '',
        stockCount: '',
        buyLimitCount: 1,
        putOnTime: '',
        putOnTimeObj: '',
        putOffTime: '',
        putOffTimeObj: '',
        introduction: '',
        specification: [
          {name: '颜色', key: 'color', couldImage: true, hasImage: false, values: [
              {value: '默认', image: '', hasImage: false},
            ]},
          {name: '尺寸', key: 'size', couldImage: false, hasImage: false, values: [
              {value: '默认', image: '', hasImage: false},
            ]}],
        expressPrice: '',
        helpedPrice: '',
        helpPersonCount: '',
      },
      specForm: {},
      batchPriceForm: {
        color: '', size: '', price: 0
      },
      batchStockForm: {
        color: '', size: '', stock: 0
      },
      rules: {  },
      state: {
        formColSpan: 22,
        labelCol: {span: 2, offset: 1},
        wrapperCol: {span: 19},
      },
      data: {
        types: [],
        specTypes: [
          {label: '颜色', value: 'color'},
          {label: '尺寸', value: 'size'},
        ],
        stockAndPriceColumns: [
          {title: '颜色', 'dataIndex': 'color'},
          {title: '尺寸', 'dataIndex': 'size'},
          {title: '价格', 'dataIndex': '', scopedSlots: {customRender: 'priceSlot'}},
          {title: '库存', 'dataIndex': '', scopedSlots: {customRender: 'stockSlot'}},
          {title: '规格图', 'dataIndex': 'faceUrl', scopedSlots: {customRender: 'faceUrlSlot'}},
        ],
        stockAndPrice: [
        ]
      }
    };
  },
  mounted() {
    console.log(this.$route.query)
    let isGene = this.$route.query.isGene;
    let typeName = isGene === '1' ? '基因' : '商品';
    let sellTypeName = '';
    if (isGene === '0') {
      if (this.$route.query.sellType === '1') {
        sellTypeName = '普通';
      }else if (this.$route.query.sellType === '2') {
        sellTypeName = '助力';
      }
    }
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: typeName, isCur: false, url: '/goods?isGene='+isGene},
      {name: '发布'+sellTypeName+typeName, isCur: true, url: ''},
    ]);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
    isHelpGoods() {
      return parseInt(this.form.sellType) === 2;
    },
    isCommonGoods() {
      return parseInt(this.form.sellType) === 1;
    },
    isGene() {
      return parseInt(this.$route.query.isGene) === 1;
    },
    goodsTypeName() {
      return parseInt(this.$route.query.isGene) === 1 ? '基因' : '商品';
    }
  },
  methods: {
    async initData() {
      await this.fetchTypeConditionList();
      if (!this.isAddForm) {
        // 加载数据
        this.fetchEntity();
      }
    },
    // 获取分类的数据
    async fetchTypeConditionList() {
      let res = await this.$api.goodsGoodsTypes(this.$route.query.isGene);
      if (res.code === 0) {
        this.data.types = res.list
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.goodsUnqiue({id: this.$route.query.id});
      console.log('goods-fetchentity: ', this.id, res);
      if(res.data) {
        let putOffTimeObj = moment(res.data.putOffTime);
        let putOnTimeObj = moment(res.data.putOnTime);
        this.form = {...res.data, putOffTimeObj, putOnTimeObj};
        this.data.stockAndPrice = res.data.stockAndPrice;
      }
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      // 提交前需要对表单中的值进行处理
      // 如果是多规格
      if (this.form.multiSpec) {
        // 那么需要将详情中的库存和价格设置到form中去
        let totalStock = 0, minPrice = 9999;
        for (let i = 0; i < this.data.stockAndPrice.length; i++) {
          let {price, stock, color, size} = this.data.stockAndPrice[i];
          if (price < 0) {
            return this.$message.error(`多规格颜色[${color}]尺寸[${size}]中的价格不合理`);
          }
          if (stock < 0) {
            return this.$message.error(`多规格颜色[${color}]尺寸[${size}]中的库存不合理`);
          }
          minPrice = Math.min(minPrice, price);
          totalStock += parseInt(stock);
        }
        this.form.stockCount = totalStock;
        this.form.price = minPrice;
      }else{
        // 如果不是，那么需要将外层的价格待到list里面去
        if (this.form.price < 0) {
          return this.$message.error(`价格设置不合理`);
        }
        if (this.form.stockCount < 0) {
          return this.$message.error(`库存设置不合理`);
        }
        this.flushSpecDataToTable();
        for (let i = 0; i < this.data.stockAndPrice.length; i++) {
          this.data.stockAndPrice[i].stock = this.form.stockCount;
          this.data.stockAndPrice[i].helpedPrice = this.form.helpedPrice;
          this.data.stockAndPrice[i].price = this.form.price;
        }
      }
      let form = {...this.form,stockAndPrice: this.data.stockAndPrice,
        gene: this.$route.query.isGene,
      }

      ;
      console.log('goods-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.goodsAddOrSave(form);
      if (res.code === 0) {
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
    // 添加一个规格
    onAddSpecHandler() {
      let curIndex = this.form.specification.length;
      this.form.specification.push({name: '', couldImage: curIndex===0, hasImage: false, values: []});
    },
    // 设置是否添加规格图片
    onSpecImageClickHandler() {
    },
    // 删除一个规格
    onSpecRemoveHandler(idx) {
      console.log('删除第' + idx + '个');
      this.form.specification.splice(idx, 1);
      console.log(JSON.stringify(this.form.specification))
    },
    // 添加一个规格的值
    onAddSpecItemHandler(sp) {
      sp.values.push({value: '', image: ''});
    },
    // 添加规格图片
    onAddSpecImageHandler(spv) {
      let that = this;
      let inputTag = document.createElement('input');
      inputTag.type = 'file';
      inputTag.accept = 'image/*';
      inputTag.onchange = async function() {
        console.log('添加图片: ', inputTag.files);
        if (inputTag.files.length > 0) {
          let file = inputTag.files[0];
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function(e){
            spv.image = e.target.result;
          }
          // 上传图片
          let res = await that.$api.publicUpload({file});
          if(res) {
            spv.image = res.data.url;
          }
        }
      };
      inputTag.click();
    },
    // 改变规格类型
    onChangeSpecTypeHandler(item, sp) {
      console.log(item, sp)
    },
    // 将商品规格的设置数据，刷新的价格库存表中去
    flushSpecDataToTable() {
      console.log('刷新数据', this.form.specification)
      if (this.form.specification.length === 0) {
        return this.$message.warning('请先添加规格');
      }
      // 表格头部
      let constColumns = [
        {title: '价格', 'dataIndex': '', scopedSlots: {customRender: 'priceSlot'}},
        {title: '库存', 'dataIndex': '', scopedSlots: {customRender: 'stockSlot'}},
        {title: '规格图', 'dataIndex': 'faceUrl', scopedSlots: {customRender: 'faceUrlSlot'}},
      ];
      let columns = this.form.specification.map(t => ({title: t.name, dataIndex: t.key}));
      columns.push(...constColumns);
      // 生成表格的数据
      let generateDatas = (list, curIdx) => {
        let curItem = this.form.specification[curIdx];
        let curValues = curItem.values;
        // 第一次进来的时候
        if (list.length === 0) {
          for (let i = 0; i < curValues.length; i++) {
            let item = {price: 0, stock: 0, faceUrl: ''};
            let curVal = curValues[i];
            item[curItem.key] = curVal.value;
            item.hasImage = curVal.hasImage;
            item.faceUrl = curVal.image;
            list.push(item);
          }
        } else {
          let newList = [];
          let id = 1;
          for (let i = 0; i < list.length; i++) {
            let lastItem = list[i];
            for (let j = 0; j < curValues.length; j++) {
              let item = {...lastItem};
              item[curItem.key] = curValues[j].value;
              item.key = id++;
              newList.push(item);
            }
          }
          list.splice(0);
          list.push(...newList);
        }
        // 如果还有后面的就继续往下
        if (curIdx < this.form.specification.length - 1) {
          generateDatas(list, curIdx + 1);
        }
      };
      let datas = [];
      generateDatas(datas, 0);
      console.log('生成的数据', JSON.stringify(datas));
      this.data.stockAndPriceColumns = columns;
      this.data.stockAndPrice = datas;
    },
    // 批量修改价格
    onBatchUpdatePrice() {
      let {color, size, price} = this.batchPriceForm;
      if (price < 0) {
        return this.$message.error('请输入合理的价格');
      }
      for (let i = 0; i < this.data.stockAndPrice.length; i++) {
        let it = this.data.stockAndPrice[i];
        if ((!color || it.color === color) && (!size || it.size === size)) {
          it.price = price;
        }
      }
      this.batchPriceForm.price = '';
    },
    // 批量修改库存
    onBatchUpdateStock() {
      let {color, size, stock} = this.batchStockForm;
      if (stock < 0) {
        return this.$message.error('请输入合理的库存');
      }
      for (let i = 0; i < this.data.stockAndPrice.length; i++) {
        let it = this.data.stockAndPrice[i];
        if ((!color || it.color === color) && (!size || it.size === size)) {
          it.stock = stock;
        }
      }
      this.batchStockForm.stock = '';
    },
    onCarouselsChange(images) {
      console.log('轮播图发生了改变：', images, !images || images.length === 0);
      if (!images || images.length === 0) {
        this.form.logoUrl = '';
      } else {
        this.form.logoUrl = images[0];
      }
    },
    onPutOffTimeChange() {
      this.form.putOffTime = this.form.putOffTimeObj.format('YYYY-MM-DD HH:mm:ss');
    },
    onPutOnTimeChange() {
      this.form.putOnTime = this.form.putOnTimeObj.format('YYYY-MM-DD HH:mm:ss');
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container1 {
  padding: 20px;
  background: #fff;
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
  .ant-input {
    width: 400px;
  }
  .ant-input-group-wrapper {
    width: 400px;
  }
  .wrap {
    /* 描边/组件_DDD */
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
.content-wrap {
  .add-btn {}
  .content-list {
    /* 背景/灰2_FAFAFA */
    border-radius: 4px;
    min-height: 56px;
    width: 100%;
    padding: 10px;
    .content-item {
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 10px;
      .content-head {
        background: #FAFAFA;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: center;
        .spec-name {
          width: 230px;
        }
        .checkbox {
          margin-left: 20px;
        }
        .content-top-right {
          flex: 1;
          text-align: right;
        }
        .trash {
          cursor: pointer;
        }
      }
      .content-body {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        .spec-item {
          position: relative;
          background: #F5F5F5;
          margin-top: 10px;
          margin-right: 20px;
          padding: 4px 4px 10px;
          padding-bottom: 0;
          border-radius: 5px;
          &.normal {
            background: #fff;
          }
          .close {
            position: absolute;
            right: -5px;
            top: -5px;
            cursor: pointer;
          }
          .input {
            border: 1px solid #eaeaea;
            height: 30px;
            width: 120px;
          }
          $imgSize: 80px;
          .img-upload {
            position: relative;
            width: $imgSize;
            margin: 5px auto 0;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 10px;
            img {
              width: $imgSize;
              height: $imgSize;
              border-radius: 5px;
            }
            .mask {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 20px;
              line-height: 20px;
              text-align: center;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              background: rgba(0,0,0,0.6);
              color: #fff;
            }
            .upload {}
          }
          .img-upload-wrap {
            width: $imgSize;
            height: $imgSize;
            margin: 4px auto 0;
            margin-bottom: 10px;
            cursor: pointer;
            color: #5A66FF;
            border: 1px dashed #EAEAEA;
            background: #fff;
            text-align: center;
            .plus {
              height: 20px;
              margin-top: 20px;
            }

            .plus-txt {
              font-size: 12px;
              height: 20px;
              line-height: 20px;
            }
          }
          .link {}
        }
      }
    }
  }
}
.stock-head {
  .title {
    color: #222;
    font-weight: normal;
  }
  .price-batch-btn {
    margin-left: 20px;
  }
  .stock-batch-btn {
    margin-left: 10px;
  }
}
.face-url {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
</style>
