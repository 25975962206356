<template>
  <div class="clearfix">
    <a-upload
        action="/api/admin/upload"
        list-type="picture-card"
        :accept="accept"
        :class="className"
        :file-list="fileList"
        :data="uploadParams"
        :multiple="false"
        :remove="onRemoveHandler"
        @preview="handlePreview"
        :before-upload="onBeforeUpload"
        @change="handleChange"
    >
      <div v-if="fileList.length < (multiple ? (maxFile||9) : 1)">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          选择视频
        </div>
      </div>
    </a-upload>
    <a-modal okText="确定" cancelText="取消" :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <video ref="video" alt="example" controls="controls" style="width: 100%" :src="previewVideo" />
    </a-modal>
  </div>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: "MyUpload",
  props: ['value', 'multiple', 'maxFile', 'className'],
  data() {
    return {
      previewVisible: false,
      previewVideo: '',
      fileList: [],
      videoList: [],
      accept: '.mp4,.mkv'
    };
  },
  computed: {
    uploadParams() {
      return {token: this.$store.state.token}
    }
  },
  mounted() {
    this.imageToFile();
  },
  watch: {
    value(oldVal, newVal) {
      if (oldVal === newVal) {
        return;
      }
      // console.log('images: ', newVal, oldVal);
      this.imageToFile();
    },
    previewVisible(oldVal, newVal) {
      // console.log('previewVisible: ', oldVal, newVal);
      if(newVal === true) {
        if (this.$refs.video) {
          this.$refs.video.pause();
        }
      }else {
        if(this.$refs.video) {
          this.$refs.video.play();
        }
      }
    }

  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    flushVal() {
      let returnVal = this.multiple ? this.videoList : this.videoList[0];
      this.$emit('input', returnVal);
      this.$emit('change', returnVal);
    },
    imageToFile() {
      if (this.multiple) {
        // 多图片上传
        this.fileList = (this.value||[]).map((t, idx) => ({
          uid: '-'+idx,
          name: 'video'+idx+'.mp4',
          status: 'done',
          url: t,
        }));
        this.videoList = this.value || [];
        console.log('multi-mode: ', this.fileList, this.videoList);
      } else {
        // 视频上传
        if (this.value) {
          let image = {
            uid: '-1',
            name: 'video-1.mp4',
            status: 'done',
            url: this.value,
          };
          if (this.fileList.length > 0) {
            this.fileList[0] = image;
          } else {
            this.fileList.push(image);
          }
          this.videoList = [this.value];

        }
        console.log('single-mode: ', this.fileList, this.videoList);
      }
    },
    fileToImage() {

    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewVideo = file.url || file.preview;
      this.previewVisible = true;
    },
    onBeforeUpload() {
      this.$store.commit('startLoading');
    },
    async handleChange({ file, fileList }) {
      this.fileList = fileList;
      console.log('handleFileChange: ', file, fileList)
      let {status, response} = file;
      if(status === 'done') {
        this.$store.commit('closeLoading')
        let {code, msg} = response;
        if (code !== 0) {
          this.$message.error(msg);
          for (let i = 0; i < this.fileList.length; i++) {
            if(this.fileList[i].uid === file.uid) {
              this.fileList.splice(i, 1);
            }
          }
        } else {
          for (let i = 0; i < this.fileList.length; i++) {
            if(this.fileList[i].uid === file.uid) {
              this.fileList[i].url = response.data.url;
            }
          }
          this.videoList = this.fileList.map(t => t.url);
        }
        this.flushVal();
      }
    },
    onRemoveHandler(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if(this.fileList[i].uid === file.uid) {
          this.fileList.splice(i, 1);
        }
      }
      this.videoList = this.fileList.map(t => t.url);
      this.flushVal();
      return true;
    },
  },
}
</script>

<style lang="scss" scoped>
.desc {
  /* 文字颜色/灰_888 */
  color: #888888;
  font-size: 12px;
}
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
