<template>
  <div class="form-item-container">
    <div>
      <a class="link" @click="onAddHandler">添加</a>
    </div>
    <table>
      <thead>
      <tr>
        <th>注射次数</th>
        <th>注射月份</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(b,idx) in innerValue" :key="idx">
        <td>{{b.name}}</td>
        <td>
          <input type="number" v-model="b.month" class="input">
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "VaccineInjectTimesFormItem",
  props: ['value'],
  data() {
    return {
      innerValue: this.value || []
    }
  },
  mounted() {
  },
  watch: {
    value() {
      this.innerValue = this.value || [];
    }
  },
  computed: {},
  methods: {
    onAddHandler() {
      let idx = '一二三四五六七八九十'.charAt(this.innerValue.length);
      this.innerValue.push({name: `第${idx}剂`, month: null})
    }
  }
}
</script>

<style scoped lang="scss">
.form-item-container {
  //background-color: #f1f1f1;
  padding: 10px;
  width: 320px;
  table {
    width: 300px;
    td, th {
      text-align: center;
      border: 1px solid #ddd;
    }
    .input {
      border: none;
      background-color: #fff;
      border-bottom: 1px solid #ddd;
      margin-bottom: 8px;
      line-height: 20px;
      outline: none;
      width: 80px;
      text-align: center;
    }
  }
}
</style>