<template>
  <div class="clearfix">
    <a-upload
        action="/api/admin/upload"
        list-type="picture-card"
        :accept="accept"
        :class="className"
        v-bind:file-list="fileList"
        :data="uploadParams"
        :disabled="disabled"
        :multiple="false"
        :remove="onImageRemoveHandler"
        @preview="handlePreview"
        :before-upload="onBeforeUpload"
        @change="handleChange"
    >
      <div v-if="!disabled && fileList.length < (multiple ? (maxFile||9) : 1)">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          选择图片
        </div>
      </div>
    </a-upload>
    <a-modal okText="确定" cancelText="取消" :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: "MyUpload",
  props: ['value', 'multiple', 'maxFile', 'className', 'name', 'disabled'],
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      accept: '.jpg,.png,.gif,.bmp,.svg',
      fileList: [
      ],
      imageList: [],
    };
  },
  computed: {
    uploadParams() {
      return {token: this.$store.state.token}
    }
  },
  mounted() {
    console.log('my-upload-images-mounted: ', this.name, this.value);
    this.refreshVal();
  },
  watch: {
    value(oldVal, newVal) {
      console.log('my-upload-images: ', this.name, newVal, oldVal);
      if (oldVal === newVal) {
        return;
      }
      this.refreshVal();
    }
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    refreshVal() {
      if (this.multiple) {
        // 多图片上传
        this.fileList = (this.value||[]).map((t, idx) => ({
          uid: '-'+(idx+1),
          name: 'image'+(-idx-1)+'.png',
          status: 'done',
          url: t,
        }));
        this.imageList = this.value || [];
        console.log('my-upload-images-multiple: ', this.name, this.fileList, this.imageList);
      } else {
        // 单图片上传
        if (this.value) {
          let image = {
            uid: '-1',
            name: 'image-1.png',
            status: 'done',
            url: this.value,
          };
          if (this.fileList.length > 0) {
            this.fileList[0] = image;
          } else {
            this.fileList.push(image);
          }
          this.imageList = [this.value];
        }
        console.log('single-mode: ', this.fileList, this.imageList);
      }
    },
    flushVal() {
      let returnVal = this.multiple ? this.imageList : this.imageList[0];
      this.$emit('input', returnVal);
      this.$emit('change', returnVal);
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    onBeforeUpload() {
      this.$store.commit('startLoading');
    },
    async handleChange({ file, fileList }) {
      this.fileList = fileList;
      console.log('handleFileChange: ', file, fileList)
      let {status, response} = file;
      if(status === 'done') {
        this.$store.commit('closeLoading')
        let {code, msg} = response;
        if (code !== 0) {
          this.$message.error(msg);
          for (let i = 0; i < this.fileList.length; i++) {
            if(this.fileList[i].uid === file.uid) {
              this.fileList.splice(i, 1);
            }
          }
        } else {
          for (let i = 0; i < this.fileList.length; i++) {
            if(this.fileList[i].uid === file.uid) {
              this.fileList[i].url = response.data.url;
            }
          }
          this.imageList = this.fileList.map(t => t.url);
        }
        this.flushVal();
      }
    },
    onImageRemoveHandler(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if(this.fileList[i].uid === file.uid) {
          this.fileList.splice(i, 1);
        }
      }
      this.imageList = this.fileList.map(t => t.url);
      this.flushVal();
      return true;
    },
  },
}
</script>

<style lang="scss" scoped>
.desc {
  /* 文字颜色/灰_888 */
  color: #888888;
  font-size: 12px;
}
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
