<template>
  <p></p>
</template>

<script>
export default {
name: "DashDuoCol"
}
</script>

<style scoped>

</style>
