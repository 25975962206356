<template>
  <div class="page-container">
    <a-row type="flex" >
      <a-col flex="1">
        <div class="agg">评测者 {{ data.selectedCount }} 人，已提交文章 {{ data.commentCount }} 人</div>
      </a-col>
    </a-row>
    <br>
    <a-row class="comment-list">
      <div class="comment-item" v-for="(b, idx) in dataList" :key="idx">
        <a-row class="top">
          <a-space>
            <img class="face-url" :src="b.headImageUrl" alt="">
            <span class="nickname">{{ b.userName }}</span>
            <div class="split"></div>
            <div class="desc-txt" v-if="b.status !== 3">未提交评测</div>
            <div class="desc-txt" v-if="b.status === 3">{{ b.usefulCount }}人觉得有用</div>
            <div class="split" v-if="b.status === 3"></div>
            <div class="desc-txt" v-if="b.status === 3">提交时间：{{b.commentTime}}</div>
          </a-space>
          <div class="top-right" v-if="b.status === 3">
            <a-popconfirm v-if="b.hasSelect !== 1" placement="bottom" ok-text="确定" cancel-text="取消" @confirm="onChooseHandler(b)">
              <template slot="title">
                <p>确认推荐该条评论展示在小程序端吗？</p>
              </template>
              <a-button type="primary" size="small">推荐</a-button>
            </a-popconfirm>
            <a-popconfirm v-if="b.hasSelect === 1" placement="bottom" ok-text="确定" cancel-text="取消" @confirm="onUnchooseHandler(b)">
              <template slot="title">
                <p>确认取消推荐该条评论展示在小程序端吗？</p>
              </template>
              <a-button type="default" size="small">取消推荐</a-button>
            </a-popconfirm>
          </div>
        </a-row>
        <div class="rate-score" v-if="b.status === 3">
          <span class="score-txt">{{b.score}}分</span>
          <a-rate v-model="b.scoreVal" :disabled="true"></a-rate>
        </div>
        <div class="content" v-if="b.status === 3">{{b.commentContent}}</div>
        <div class="img-list" v-if="b.status === 3">
          <div class="img-wrap" v-for="(m, idx) in b.commentImages" :key="idx">
            <img :src="m" alt="">
          </div>
        </div>
      </div>

    </a-row>
    <br>
    <a-row class="footer-page">
      <a-pagination :current="page.current" :total="page.total" @change="onDataListChange" />
    </a-row>
  </div>
</template>

<script>
export default {
  name: "EvaluationEnroll.vue",
  props: ['evaluationId', 'disabled'],
  data() {
    return {
      // 表单
      form: {
        fieldType: 1,
        keyword: '',
        evaluationId: this.evaluationId
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      columns: [
        {title: '报名头像', dataIndex:'headImageUrl', scopedSlots: {customRender: 'headImageUrl'}},
        {title: '昵称', dataIndex:'nickName'},
        {title: '姓名', dataIndex:'userName'},
        {title: '手机号', dataIndex:'mobile'},
        {title: '地址', dataIndex:'address'},
        {title: '报名时间', dataIndex:'createTime'},
        {title: '备注', dataIndex:'memo'},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ],
      dataList: [
      ],
      data: {
        commentCount: 0,
        selectedCount: 0,
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('EvaluationEnroll 初始化数据...', [this.disabled])
      // 先加载需要的数据
      this.refreshList();
    },
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.evaluationEnrollPage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page, selectedCount, commentCount} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.data.selectedCount = selectedCount;
      this.data.commentCount = commentCount;
      this.dataList = (page.records || []).map((t, idx) => ({...t, scoreVal: t.score/2, key: idx}));
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
    async onChooseHandler(b) {
      await this.$api.evaluationEnrollSelect(b.id, 1);
      this.refreshList();
    },
    async onUnchooseHandler(b) {
      await this.$api.evaluationEnrollSelect(b.id, 0);
      this.refreshList()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
.top_tab {
  border-bottom: 1px solid #EAEAEA;
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;

  .refresh-btn {
    padding-right: 20px;
    .btn {
      cursor: pointer;
    }
  }
}
.agg {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* or 18px */
  color: #000000;
}
.desc {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  font-size: 13px;
  /* or 18px */
  color: #bababa;
}

.comment-list {
  .comment-item {
    background: #FAFAFA;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    .top {
      $head-image-size: 20px;
      .face-url {
        width: $head-image-size;
        height: $head-image-size;
        border-radius: $head-image-size;
      }
      .nickname {
        color: #333333;
      }
      .split {
        border-right: 1px solid #ddd;
        height: 12px;
        line-height: $head-image-size;
        margin-top: 2px;
      }
      .desc-txt {
        color: #888888;
      }
      .desc-txt {}
    }
    .top-right {
      float: right;
    }
    .rate-score {
      margin-top: 20px;
      margin-bottom: 10px;
      .score-txt {
        margin-right: 10px;
      }
    }
    .content {
      margin-bottom: 20px;
    }
    .img-list {
      display: flex;
      .img-wrap {
        $imgSize: 100px;
        padding: 10px;
        background: #EEEFEE;
        margin-right: 10px;
        img {
          width: $imgSize;
          height: $imgSize;
        }
      }
    }
  }
}
.footer-page {
  text-align: right;
}
</style>
