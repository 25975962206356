<template>
  <div class="page-container">
    <a-row type="flex" >
      <a-col flex="1">
        <a-button @click="onGotoFormHandler(null)" type="primary">发布</a-button>
      </a-col>
      <a-col>
        <a-space size="middle">
          <a-input-search v-model="form.keyword" placeholder="请输入关键字" style="width: 200px" @search="refreshList" />
        </a-space>
      </a-col>
    </a-row>
    <br><br>
    <a-row class="data-list">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="imageUrl" slot-scope="imageUrl">
          <image-viewer :value="imageUrl" :show-hit="true" />
        </template>
        <template slot="range" slot-scope="row">
          <span>{{row.startTime}} 至 {{row.endTime}}</span>
        </template>
        <template slot="status" slot-scope="row">
          <a-space>
            <span v-if="row.status === 0">下架</span>
            <span v-if="row.status === 1">上架</span>
          </a-space>
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
            <a-popconfirm v-if="row.status === 0" title="确认要上架当前广告吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onChangeStatusHandler(row.id, 1)">
              <a class="link">上架</a>
            </a-popconfirm>
            <a-popconfirm v-if="row.status === 1" title="确认要下架当前广告吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onChangeStatusHandler(row.id, 0)">
              <a class="link">下架</a>
            </a-popconfirm>
<!--            <a @click="onUniqueHandler(row.id)" class="link">查看</a>-->
            <a @click="onEditHandler(row.id)" class="link">编辑</a>
            <a-popconfirm title="确认要删除当前数据吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onRemoveHandler(row.id)">
              <a class="link">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
import ImageViewer from "../../components/common/ImageViewer";
export default {
  name: "AppSplash.vue",
  components: {ImageViewer},
  data() {
    return {
      // 表单
      form: {
        startDate: '',
        endDate: '',
        keyword: '',
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      columns: [
        {title: '编号', dataIndex:'id'},
        {title: '广告', dataIndex:'imageUrl', scopedSlots: {customRender: 'imageUrl'}},
        {title: '品牌', dataIndex:'brandName'},
        {title: '周期', dataIndex:'' , scopedSlots: {customRender: 'range'}},
        {title: '创建时间', dataIndex:'createTime'},
        {title: '浏览量', dataIndex:'visitCount'},
        {title: '状态', dataIndex:'', scopedSlots: {customRender: 'status'}},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ],
      dataList: [
      ],
      data: {
        brands: [],
        provinces: [],
        cities: [],
        counties: [],
      },
    };
  },
  mounted() {
    this.$store.commit('clearRouteStack');
    this.$store.commit('changeSecondTabIdx', 12);
    this.initData();
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('初始化数据...')
      // 先加载需要的数据
      this.refreshList();
    },
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.appSplashPage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    // 导出
    onExportHandler() {
      console.log('导出列表');
      this.$api.adminStoreExport(this.form);
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看product: '+id);
      this.$message.info("待完善");
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑appSplash: '+id);
      this.onGotoFormHandler(id);
    },
    // 删除
    async onRemoveHandler(id) {
      console.log('删除appSplash: '+id);
      await this.$api.appSplashRemove({id});
      await this.refreshList();
    },
    // 删除
    async onChangeStatusHandler(id, status) {
      console.log('改变状态: '+id+', '+status);
      await this.$api.appSplashChangeStatus({id, status});
      await this.refreshList();
    },
    // 前往表单页面
    async onGotoFormHandler(id) {
      // 前往登录页面
      this.$router.push(`/appSplash/appSplashForm?id=${id}`);
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
.top_tab {
  border-bottom: 1px solid #EAEAEA;
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;

  .refresh-btn {
    padding-right: 20px;
    .btn {
      cursor: pointer;
    }
  }
}
</style>
