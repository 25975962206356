<template>
  <div class="page-container">
    <a-row type="flex" >
      <a-col flex="1">
      </a-col>
      <a-input-search v-model="form.keyword" placeholder="请输入关键字" style="width: 200px" @search="refreshList" />
    </a-row>
    <br>
    <a-row class="data-list">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="productId" slot-scope="row">
          <div v-if="row.product">
<!--            <img style="width: 80px" :src="row.product.faceUrl" />-->
            <p>{{row.product.productName}}</p>
          </div>
          <div v-else>
            未知入口
          </div>
        </template>
        <template slot="hasContact" slot-scope="hasContact">
          <span v-if="hasContact === 0" style="color: #E54545">新</span>
          <span v-if="hasContact === 1">已联系</span>
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
            <a-popconfirm v-if="row.hasContact === 0" title="确定已联系该用户吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onContactedHandler(row.id)">
              <a class="link">未联系</a>
            </a-popconfirm>
<!--            <a-popconfirm v-if="row.status === 1" title="确认要删除当前数据吗?" ok-text="确认" cancel-text="取消"-->
<!--                          @confirm="onRemoveHandler(row.id)">-->
<!--              <a class="link">删除</a>-->
<!--            </a-popconfirm>-->
          </a-space>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "QfConsultList.vue",
  data() {
    return {
      // 表单
      form: {
        type: 1,
        keyword: ''
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      columns: [
        {title: '编号', dataIndex:'id'},
        {title: '负责人', dataIndex:'username'},
        {title: '联系电话', dataIndex:'mobile'},
        {title: '品牌', dataIndex:'brand'},
        {title: '填写时间', dataIndex:'createTime'},
        {title: '状态', dataIndex:'hasContact', scopedSlots: {customRender: 'hasContact'}},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ],
      dataList: [
      ]
    };
  },
  created() {
    this.refreshList();
  },
  computed: {
  },
  methods: {
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.qyConsultPage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    // 导出
    onExportHandler() {
      console.log('导出列表');
      this.$api.adminProductExport(this.form);
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看product: '+id);
      this.$message.info("待完善");
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑product: '+id);
      this.onGotoFormHandler(id);
    },
    // 删除
    async onRemoveHandler(id) {
      console.log('删除product: '+id);
      await this.$api.enrollApplyDelete({id});
      await this.refreshList();
    },
    // 前往表单页面
    async onGotoFormHandler(id) {
      // 前往登录页面
      this.$router.push(`productForm?id=${id}&productType=${this.productType}`);
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
    async onContactedHandler(id) {
      console.log('已联系: '+id);
      await this.$api.qyConsultAddOrSave({id, hasContact: 1});
      await this.refreshList();
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 10px;
}
</style>
