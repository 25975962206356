<template>
  <div class="step-list">
    <div class="step" v-for="(b, idx) in list" :key="idx">
      <div class="step-name">{{b.step}}</div>
      <div class="step-form" v-if="!disabled">
        <a-input class="step-title" @change="flushValue" v-model="b.title"></a-input>
        <a-input class="step-intro" @change="flushValue" v-model="b.intro"></a-input>
      </div>
      <div class="step-form" v-if="disabled">
        <div class="step-title">{{b.title}}</div>
        <div class="step-intro">{{b.intro}}</div>
      </div>
      <a-button v-if="!disabled" type="link" @click="onDeleteItem(idx)">删除</a-button>
    </div>
    <div class="activity" v-if="!disabled">
      <a-button type="link" @click="onAddItem">添加步骤</a-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    disabled: Boolean
  },
  data() {
    return {
      list: this.value,
      form: {
        step: '',
        title: '',
        intro: '',
      }
    };
  },
  watch: {
    value(oldVal, newVal) {
      // if (oldVal === newVal) {
      //   return ;
      // }
      console.log('oldVal=', oldVal, ', newVal=', newVal);
      this.list = this.value;
    }
  },
  methods: {
    flushValue() {
      this.$emit('input', this.list);
    },
    onAddItem() {
      this.list.push({
        step: '步骤'+(this.list.length+1),
        title: '',
        intro: '',
      });
    },
    onDeleteItem(idx) {
      console.log('delete evaluation idx: ', idx);
      this.list.splice(idx, 1);
      this.flushValue();
    }
  }
}
</script>

<style lang="scss" scoped>
.step-list {
  width: 100%;
  .step {
    width: 100%;
    background: #FAFAFA;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin-bottom: 15px;
    .step-name {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      margin-right: 16px;
      font-size: 12px;
      /* identical to box height, or 18px */
      color: #000000;
    }
    .title {
      width: 480px;
      margin-left: 20px;
    }
  }
}
</style>
