<template>
  <div>
    <!--  订单发货  -->
    <a-modal
        title="订单发货"
        :visible="state.showSendGoodsModal"
        @ok="submitSendGoodsHandler"
        okText="确定"
        cancelText="取消"
        @cancel="hideAllModal"
    >
      <div class="form-item">
        <div class="label">订单编号</div>
        <div class="label-val">{{curOrder.orderNo}}</div>
      </div>
      <div class="form-item">
        <div class="label">下单时间</div>
        <div class="label-val">{{curOrder.createTime}}</div>
      </div>
      <div class="form-item">
        <div class="label">收货信息</div>
        <div class="label-val">{{curOrder.buyerUserName}}·{{curOrder.buyerMobile}}·{{curOrder.buyerAddress}}</div>
      </div>
      <div class="form-item">
        <div class="label">发货方式</div>
        <div class="label-val">
          <div class="text">物流快递</div>
          <div class="express-area">
            <div class="form-item1">
              <div class="label1">快递公司</div>
              <div class="label-val1">
                <a-select class="label-input" :showSearch="true" v-model="curOrder.expressCompany" placeholder="请选择快递公司">
                  <a-select-option v-for="(b, idx) in data.expressCompanies" :key="idx" :value="b">{{ b }}</a-select-option>
                </a-select>
              </div>
            </div>
            <div class="form-item1">
              <div class="label1">快递单号</div>
              <div class="label-val1">
                <a-input class="label-input" v-model="curOrder.expressNo" placeholder="请输入快递单号" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!--  取消订单  -->
    <a-modal
        title="取消订单"
        :visible="state.showCancelOrderModal"
        okText="确定"
        cancelText="取消"
        @ok="submitCancelOrderHandler"
        @cancel="hideAllModal"
    >
      <div>
        <div class="warning-notification">温馨提示：建议商家不要直接取消用户订单，如果因为缺货或者其他原因无法发货，
          请与买家沟通一致后由买家自行取消。如果需要取消请选择相应的取消原因</div>
        <div class="form-item2">
          <div class="label">取消原因</div>
          <div class="label-val">
            <a-select class="label-input1" :show-search="true" v-model="curOrder.cancelReason">
              <a-select-option v-for="(b,idx) in data.cancelReasons" :key="idx" :value="b">{{b}}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-item2">
          <div class="label">补充说明</div>
          <div class="label-val">
            <a-textarea class="label-textarea" v-model="curOrder.cancelAddition" placeholder="请输入补充说明" />
          </div>
        </div>
      </div>
    </a-modal>
    <!--  订单备注  -->
    <a-modal
        title="订单备注"
        :visible="state.showMemoModal"
        okText="确定"
        cancelText="取消"
        @ok="submitMemoHandler"
        @cancel="hideAllModal"
    >
      <div>
        <div class="form-item2">
          <div class="label">备注</div>
          <div class="label-val">
            <a-textarea class="label-textarea" v-model="curOrder.memo" placeholder="请输入备注" />
            <div style="color: #888">订单备注仅商家可见，买家不可见</div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "OrderOperateModales",
  props: ['value', 'curOrder'],
  data() {
    return {
      state: {
        showSendGoodsModal: false,
        showCancelOrderModal: false,
        showMemoModal: false,
      },
      // curOrder: {
      //   id: '',
      //   orderNo:'SN-88888888',
      //   createTime: '2020-01-02',
      //   buyerAddress: '',
      //   buyerUserName: '',
      //   buyerMobile: '',
      //   expressNo: '',
      //   expressCompany: '',
      //   cancelReason: '',
      //   cancelAddition: '',
      //   memo: '',
      // },
      data: {
        expressCompanies: ["不用快递", "圆通速递","申通快递","韵达速递","顺丰速运","中通快递","EMS","天天快递","宅急送","优速物流","百世快递","速尔快递","全峰快递","AAE全球专递","Aramex","邮政包裹信件","比利时邮政","DHL中国件","EMS国际件","FedEx国际件","凡客配送","凡宇快递","Fedex","FedEx美国件","国通快递","韩国邮政","佳吉快运","京东快递","加拿大邮政","加运美","嘉里大通","京广速递","跨越速运","快捷快递","民邦速递","民航快递","OCS","全一快递","全晨快递","日本邮政","如风达","圣安物流","盛辉物流","TNT","UPS","USPS","万象物流","信丰物流","远成物流","运通中港快递","中铁物流","增益速递","德邦快递","天地华宇","龙邦速递","中邮物流","传喜物流","飞康达","安能物流","百福东方","大田物流","恒路物流","康力物流","联昊通","明亮物流","穗佳物流","邮政国际包裹","OnTrac"],
        cancelReasons: ['用户不想要了', '长时间未发货']
      }
    };
  },
  watch: {
    value(oldVal, newVal) {
      if (oldVal !== newVal) {
        if (this.value === 1) {
          this.state.showSendGoodsModal = true;
        } else if (this.value === 2) {
          this.state.showCancelOrderModal = true;
        } else if (this.value === 3) {
          this.state.showMemoModal = true;
        }
      }
    }
  },
  methods: {
    // 提交订单发货
    async submitSendGoodsHandler() {
      let {id, expressCompany, expressNo} = this.curOrder;
      if (!expressCompany) {
        return this.$message.error('请选择快递公司');
      }
      if (!expressNo) {
        return this.$message.error("请输入快递单号");
      }
      await this.$api.sendOrder({id, expressCompany, expressNo});
      this.hideAllModal();
      this.$emit('refresh');
    },
    // 提交取消订单
    async submitCancelOrderHandler() {
      let {id, cancelReason, cancelAddition} = this.curOrder;
      if (!cancelReason) {
        return this.$message.error('请选择取消原因');
      }
      await this.$api.cancelOrder({id, cancelAddition, cancelReason});
      this.state.showCancelOrderModal = false;
      this.hideAllModal();
      this.$emit('refresh');
    },
    // 提交订单备注
    async submitMemoHandler() {
      let {id, memo} = this.curOrder;
      if (!memo) {
        return this.$message.error('请输入备注内容');
      }
      await this.$api.memoOrder({id, memo});
      this.state.showCancelOrderModal = false;
      this.hideAllModal();
      this.$emit('refresh');
    },
    // 隐藏所有的弹窗
    hideAllModal() {
      this.state.showSendGoodsModal=false;
      this.state.showMemoModal=false;
      this.state.showCancelOrderModal=false;
      this.value = 0;
      this.$emit('input', 0);
    }
  }
}
</script>

<style lang="scss" scoped>

.form-item {
  display: flex;
  margin-bottom: 10px;
  .label {
    width: 100px;
    color: #888;
  }
  .label-val {
    flex: 1;
    color: #222;
    .express-area {
      width: 100%;
      margin-top: 10px;
      background: #FAFAFA;
      padding: 20px;
      .form-item1 {
        display: flex;
        margin-bottom: 10px;
        .label1 {
          width: 80px;
        }
        .label-input {
          width: 200px;
        }
      }
    }
  }
}
.warning-notification {
  /* iCON/蓝_浅底_EEEFFF */
  background: #EEEFFF;
  border-radius: 4px;
  padding: 10px;
  color: #3D438B;
  font-size: 12px;
}
.form-item2 {
  display: flex;
  margin-top: 20px;
  .label {
    width: 100px;
  }
  .label-val {
    .label-input1 {
      width: 320px;
    }
    .label-textarea {
      width: 320px;
      height: 80px;
    }
  }
}
</style>
