<template>
  <div class="page-container">
    <a-form-model
        ref="ruleForm"
        :model="form"
        :label-col="state.labelCol"
        :rules="rules"
        labelAlign="left"
        :wrapper-col="state.wrapperCol">
      <a-row>
        <a-col :span="state.formColSpan">
          <div class="title">评测商品</div>
          <a-form-model-item label="活动名称">
            <a-input v-model="form.activityName">
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="关联产品">
            <a-select v-model="form.productId" :show-search="true"
                      :filterOption="productFilterOption"
                      @search="fetchGoodsList"
                      @change="onGoodsChange"
            >
              <a-select-option v-for="(b, idx) in data.goods"
                               :key="idx"
                               :value="b.id">
                {{b.productName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="产品规格">
            <a-input v-model="form.productSpec" />
          </a-form-model-item>
          <a-form-model-item label="来源">
            <a-input v-model="form.origin" />
          </a-form-model-item>
          <a-form-model-item label="评测规则">
            <a-input v-model="form.testRule">
            </a-input>
          </a-form-model-item>
<!--          <a-form-model-item label="产品名">-->
<!--            <a-input v-model="form.productName">-->
<!--            </a-input>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="市场价">
            <a-input v-model="form.price">
              <span slot="addonAfter">元</span>
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="封面图片">
            <my-upload v-model="form.faceUrl" :multiple="false" />
          </a-form-model-item>
          <a-form-model-item label="产品图片">
            <my-upload v-model="form.images" :multiple="true" />
            <p class="desc">最多上传9张，建议尺寸750*750像素，鼠标拖拽图片调整顺序，支持、JPG或JPEG格式图片，大小不超过5MB</p>
          </a-form-model-item>
          <a-divider />
          <div class="title">报名限制</div>
          <a-form-model-item label="评测名额">
            <a-input v-model="form.limitCount">
              <span slot="addonAfter">人</span>
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="报名截止时间">
            <a-date-picker v-model="form.expireDateObj" placeholder="请选择截止日期" @change="onExpiredDateChange" />
          </a-form-model-item>
          <a-form-model-item label="评测截止时间">
            <a-date-picker v-model="form.commentEndTimeObj" placeholder="请选择截止日期" @change="onCommentExpiredDateChange" />
          </a-form-model-item>
          <a-form-model-item label="流程步骤">
            <evaluation-step-form style="width: 600px" v-model="form.steps" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import EvaluationStepForm from "@/pages/evaluation/EvaluationStepForm";
import moment from 'moment'
export default {
  name: "BrandForm",
  components: {
    'evaluation-step-form': EvaluationStepForm
  },
  data() {
    return {
      form: {
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        activityName: '',
        productId: '',
        productType: '',
        productName: '',
        price: '',
        faceUrl: '',
        productSpec: '',
        origin: '',
        testRule: '',
        images: [],
        limitCount: '',
        expireDate: '',
        expireDateObj: '',
        commentEndTime: '',
        commentEndTimeObj: '',
        steps: [{"step": "步骤1", "intro": "截止时间内报名", "title": "开放报名"}, {"step": "步骤2", "intro": "5个工作日内审核", "title": "人员筛选"}, {"step": "步骤3", "intro": "审核通过的用户我们会以快递包邮的形式寄发产品", "title": "产品寄发"}, {"step": "步骤4", "intro": "产品收到后用户开始体验并提交使用心得", "title": "用户评测"}],
      },
      rules: {  },
      state: {
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 10},
      },
      data: {
        types: [],
        goods: []
      }
    };
  },
  mounted() {
    this.initData();
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: '评测', isCur: false, url: '/evaluation'},
      {name: '发布评测', isCur: true, url: ''},
    ]);
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      await this.fetchTypeConditionList();
      await this.fetchGoodsList();
      if (!this.isAddForm) {
        // 加载数据
        await this.fetchGoodsList('', true);
        this.fetchEntity();
      }
    },
    // 获取分类的数据
    async fetchTypeConditionList() {
      let res = await this.$api.commonTypeByType(1);
      if (res.code === 0) {
        this.data.types = res.list
      }
    },
    onGoodsSearchHandler(inputVal) {
      console.log(inputVal);
    },
    // 获取商品数据
    async fetchGoodsList(keyword='', isForce=false) {
      if(!isForce && !keyword) return ;
      let res = await this.$api.productListAll({keyword: keyword});
      if (res.code === 0) {
        this.data.goods = res.list
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.evaluationUnqiue({id: this.$route.query.id});
      console.log('evalutaion-fetchentity: ', this.id, res);
      if(res.data) {
        let {activityName, productId, productType, productName, price, faceUrl, images, limitCount, expireDate, commentEndTime, steps, id, origin} = res.data;
        let expireDateObj = moment(expireDate);
        let commentEndTimeObj = moment(commentEndTime);
        this.form = {id, activityName, productId, productType, productName, price, faceUrl, images, limitCount, origin
          , expireDate: expireDate?expireDate.substr(0, 10):'', expireDateObj
          , commentEndTime: commentEndTime ? commentEndTime.substr(0, 10) : '', commentEndTimeObj, steps};
      }
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,};
      console.log('evaluation-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.evaluationAddOrSave(form);
      if (res.code === 0) {
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
    onExpiredDateChange() {
      this.form.expireDate = this.form.expireDateObj.format('YYYY-MM-DD');
    },
    onCommentExpiredDateChange() {
      this.form.commentEndTime = this.form.commentEndTimeObj.format('YYYY-MM-DD');
    },
    // 商品
    onGoodsChange() {
      // 找到商品
      this.data.goods.forEach(t => {
        if (t.id === this.form.productId) {
          this.form.productName = t.productName;
          this.form.productType = t.productType;
        }
      });
    },
    productFilterOption(inputValue, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
  .ant-form-item-control {
    width: 260px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
</style>
