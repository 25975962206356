<template>
  <div class="page-container">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :rules="rules"
                  labelAlign="left"
                  :wrapper-col="state.wrapperCol">
      <a-row>
        <a-col :span="state.formColSpan">
          <a-form-model-item label="父级分类">
            <a-select placeholder="请选择类别" v-model="form.pid" style="width:230px">
              <a-select-option :value="0">无</a-select-option>
              <a-select-option v-for="(t, idx) in data.topList" :key="idx" :value="t.id">{{t.name}}</a-select-option>
            </a-select>
            <p class="desc">不选择默认为一级分类</p>
          </a-form-model-item>
          <a-form-model-item label="名称">
            <a-input v-model="form.name" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
export default {
  name: "ArticleTypeForm",
  data() {
    return {
      form: {
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        name: '',
        pid: this.$route.query.pid === 'null' ? '' : this.$route.query.pid,
      },
      rules: {  },
      state: {
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 10},
      },
      data: {
        topList: []
      }
    };
  },
  mounted() {
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: '文章类别', isCur: false, url: '/article'},
      {name: '发布文章类别', isCur: true, url: ''},
    ]);
    this.$store.commit('changeSecondTabIdx', 1);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      await this.fetchTopList();
      if (!this.isAddForm) {
        // 加载数据
        this.fetchEntity();
      }
    },
    async fetchTopList() {
      console.log("article-type: 获取一级分类");
      let res = await this.$api.articleTypeListAll({pid: 0});
      if (res.list && res.list.length > 0) {
        this.data.topList = res.list;
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.articleTypeUnqiue({id: this.$route.query.id});
      console.log('article-type-fetchentity: ', this.id, res);
      if(res.data) {
        let {name, pid, id} = res.data;
        this.form = {name, pid, id};
      }
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,};
      console.log('article-type-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.articleTypeAddOrSave(form);
      if (res.code === 0) {
        this.$store.commit('changeSecondTabIdx', 1);
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
</style>
