<template>
  <div class="coupon-list">
    <div class="coupon" v-for="(b, idx) in list" :key="idx">
      <a-row type="flex" style="width: 100%">
        <a-col :span="16">
          <div class="form-item">
            <div class="label">优惠券名称</div>
            <div class="form-input">
              <a-input v-model="b.title" class="title"></a-input>
            </div>
          </div>
          <div class="form-item">
            <div class="label">使用门槛</div>
            <div class="form-input">
              <span>订单需满</span>
              <a-input v-model="b.minPrice" class="minPrice">
                <span slot="addonAfter">元</span>
              </a-input>
            </div>
          </div>
          <div class="form-item">
            <div class="label">优惠内容</div>
            <div class="form-input">
              <a-radio-group class="radio-group" name="radioGroup" @change="onDiscountTypeChanged(idx)" v-model="b.discountType">
                <a-radio :value="1" class="radio-item">
                  <span class="radio-txt">减免金额</span>
                  <a-input :disabled="b.discountType === 0" class="radio-input" v-model="b.discountMoney">
                    <span slot="addonAfter">元</span>
                  </a-input>
                </a-radio>
                <a-radio :value="0" class="radio-item">
                  <span class="radio-txt">商品折扣</span>
                  <a-input :disabled="b.discountType === 1" class="radio-input" v-model="b.discountRate">
                    <span slot="addonAfter">折</span>
                  </a-input>
                </a-radio>
              </a-radio-group>
            </div>
          </div>
          <div class="form-item">
            <div class="label">有效期</div>
            <div class="form-input">
              <a-date-picker v-model="b.startDate" @change="onStartDateChange(b)" placeholder="请选择开始日期" />
              <span class="date-split">至</span>
              <a-date-picker v-model="b.endDate" @change="onEndDateChange(b)" placeholder="请选择结束日期" />
            </div>
          </div>
        </a-col>
        <a-col :span="3" offset="5">
          <a-button type="link" @click="onDeleteCoupon(idx)">删除</a-button>
        </a-col>
      </a-row>
    </div>
    <div class="activity">
      <a-button type="link" @click="onAddCoupon">添加优惠券</a-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    value: Array
  },
  data() {
    return {
      list: this.value,
      form: {
        title: '',
        minPrice: '',
        // 0折扣，1金额
        discountType: 1,
        discountRate: '',
        discountMoney: '',
        startTime: '',
        endTime: '',
      },
      state: {
        discountMoney: false,
        discountRate: true,
      },
      dateFormat: 'YYYY-MM-DD HH:mm:ss'
    };
  },
  created() {
    if (this.value) {
      this.value.forEach(t => {
        if(t.startTime) {
          t.startDate = moment(t.startTime, 'YYYY-MM-DD');
        }
        if(t.endTime) {
          t.endDate = moment(t.endTime, 'YYYY-MM-DD');
        }
      })
    }
  },
  methods: {
    flushValue() {
      this.$emit('input', this.list);
    },
    onAddCoupon() {
      this.list.push({
        title: '',
        minPrice: '',
        // 0折扣，1金额
        discountType: 1,
        discountRate: '',
        discountMoney: '',
        startTime: '',
        endTime: '',
        startDate: '',
        endDate: ''
      });
    },
    onDeleteCoupon(idx) {
      console.log('delete activity idx: ', idx);
      this.list.splice(idx, 1);
    },
    getCurrentStyle(current) {
      const style = {};
      if (current.date() === 1) {
        style.border = '1px solid #1890ff';
        style.borderRadius = '50%';
      }
      return style;
    },
    onDiscountTypeChanged(idx) {
      console.log(JSON.stringify(this.list[idx]))
      if (this.form.discountType === 1) {
        // 金额
      } else {
        // 折扣
      }
    },
    onStartDateChange(item) {
      item.startTime = item.startDate.format(this.dateFormat);
    },
    onEndDateChange(item) {
      item.endTime = item.endDate.format(this.dateFormat);
    },
  }
}
</script>

<style lang="scss">
.coupon-list {
  .coupon {
    .form-item {
      .ant-input {
        width: 180px;
      }
      .radio-input {
        input {
          width: 110px;
        }
      }
    }
    .minPrice {
      input {
        width: 120px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.coupon-list {
  width: 900px;
  .coupon {
    width: 100%;
    background: #FAFAFA;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin-bottom: 15px;
    .form-item {
      display: flex;
      margin-bottom: 10px;
      .label {
        width: 100px;
        min-width: 100px;
      }
    }
    .minPrice {
      width: 120px;
      margin-left: 10px;
      input {
        width: 120px;
      }
    }
    .faceUrl {}
    .title {
      width: 460px;
    }
    .radio-group {
      display: flex;
    }
    .radio-item {
      margin-right: 20px;
    }
    .radio-txt {
    }
    .radio-input {
      width: 140px;
      margin-left: 10px;
    }
    .date-split {
      padding: 0 10px;
    }
  }
}
</style>
