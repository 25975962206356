import axios from 'axios';
import Qs from 'qs';
import store from '../../store/index'

// 服务器api前缀
const SERVER_BASE_URL = '/api';
// 接口请求超时时间
const SERVER_TIMEOUT = 1000 * 1000;
// 单次请求响应大小
const SERVER_MAX_CONTENT_LENGTH = 500 * 1024 * 1024;
// 请求的token的key
const AUTH_TOKEN_KEY = 'MILK_QUERY_TOKEN';
// 请求的token
let authToken = localStorage.getItem(AUTH_TOKEN_KEY);
store.commit('setToken', authToken);


// 获取配置参数
function combinedConfig(config = {}) {
    return {
        ...config,
        // `url` is the server URL that will be used for the request
        // url: '/user',
        // `method` is the request method to be used when making the request
        // method: 'get', // default
        // `baseURL` will be prepended to `url` unless `url` is absolute.
        // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
        // to methods of that instance.
        baseURL: SERVER_BASE_URL,
        // `transformRequest` allows changes to the request data before it is sent to the server
        // This is only applicable for request methods 'PUT', 'POST', 'PATCH' and 'DELETE'
        // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
        // FormData or Stream
        // You may modify the headers object.
        // transformRequest: [function (data, headers) {
        //     // Do whatever you want to transform the data
        //     console.log('data', data)
        //     return Qs.stringify(data);
        // }],

        // `transformResponse` allows changes to the response data to be made before
        // it is passed to then/catch
        transformResponse: [function (data) {
            // Do whatever you want to transform the data
            return typeof data === 'string' ? JSON.parse(data) : data;
        }],

        // `headers` are custom headers to be sent
        headers: {},
        // `params` are the URL parameters to be sent with the request
        // Must be a plain object or a URLSearchParams object
        params: {token: authToken, ...(config.params||{})},
        // `paramsSerializer` is an optional function in charge of serializing `params`
        // (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
        // `data` is the data to be sent as the request body
        // Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
        // When no `transformRequest` is set, must be of one of the following types:
        // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
        // - Browser only: FormData, File, Blob
        // - Node only: Stream, Buffer
        // data: {},

        // syntax alternative to send data into the body
        // method post
        // only the value is sent, not the key
        // data: 'Country=Brasil&City=Belo Horizonte',
        // `timeout` specifies the number of milliseconds before the request times out.
        // If the request takes longer than `timeout`, the request will be aborted.
        timeout: SERVER_TIMEOUT, // default is `0` (no timeout)

        // `withCredentials` indicates whether or not cross-site Access-Control requests
        // should be made using credentials
        withCredentials: false, // default
        // `adapter` allows custom handling of requests which makes testing easier.
        // Return a promise and supply a valid response (see lib/adapters/README.md).
        // adapter: function (config) {
        //     /* ... */
        // },
        // `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
        // This will set an `Authorization` header, overwriting any existing
        // `Authorization` custom headers you have set using `headers`.
        // Please note that only HTTP Basic auth is configurable through this parameter.
        // For Bearer tokens and such, use `Authorization` custom headers instead.
        // auth: {
        //   username: 'janedoe',
        //   password: 's00pers3cret'
        // },
        // `responseType` indicates the type of data that the server will respond with
        // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
        //   browser only: 'blob'
        responseType: 'json', // default
        // `responseEncoding` indicates encoding to use for decoding responses (Node.js only)
        // Note: Ignored for `responseType` of 'stream' or client-side requests
        responseEncoding: 'utf8', // default
        // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
        xsrfCookieName: 'XSRF-TOKEN', // default
        // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
        xsrfHeaderName: 'X-XSRF-TOKEN', // default
        // `onUploadProgress` allows handling of progress events for uploads
        // browser only
        // onUploadProgress: function (progressEvent) {
        //     // Do whatever you want with the native progress event
        // },
        // `onDownloadProgress` allows handling of progress events for downloads
        // browser only
        // onDownloadProgress: function (progressEvent) {
        //     // Do whatever you want with the native progress event
        // },
        // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
        maxContentLength: SERVER_MAX_CONTENT_LENGTH,
        // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
        // maxBodyLength: 2000,

        // `validateStatus` defines whether to resolve or reject the promise for a given
        // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
        // or `undefined`), the promise will be resolved; otherwise, the promise will be
        // rejected.
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },

        // `maxRedirects` defines the maximum number of redirects to follow in node.js.
        // If set to 0, no redirects will be followed.
        maxRedirects: 5, // default

        // `socketPath` defines a UNIX Socket to be used in node.js.
        // e.g. '/var/run/docker.sock' to send requests to the docker daemon.
        // Only either `socketPath` or `proxy` can be specified.
        // If both are specified, `socketPath` is used.
        socketPath: null, // default

        // `httpAgent` and `httpsAgent` define a custom agent to be used when performing http
        // and https requests, respectively, in node.js. This allows options to be added like
        // `keepAlive` that are not enabled by default.
        // httpAgent: new http.Agent({ keepAlive: true }),
        // httpsAgent: new https.Agent({ keepAlive: true }),

        // `proxy` defines the hostname and port of the proxy server.
        // You can also define your proxy using the conventional `http_proxy` and
        // `https_proxy` environment variables. If you are using environment variables
        // for your proxy configuration, you can also define a `no_proxy` environment
        // variable as a comma-separated list of domains that should not be proxied.
        // Use `false` to disable proxies, ignoring environment variables.
        // `auth` indicates that HTTP Basic auth should be used to connect to the proxy, and
        // supplies credentials.
        // This will set an `Proxy-Authorization` header, overwriting any existing
        // `Proxy-Authorization` custom headers you have set using `headers`.
        // proxy: {
        //   host: '127.0.0.1',
        //   port: 9000,
        //   auth: {
        //     username: 'mikeymike',
        //     password: 'rapunz3l'
        //   }
        // },

        // `cancelToken` specifies a cancel token that can be used to cancel the request
        // (see Cancellation section below for details)
        // cancelToken: new CancelToken(function (cancel) {
        // }),

        // `decompress` indicates whether or not the response body should be decompressed
        // automatically. If set to `true` will also remove the 'content-encoding' header
        // from the responses objects of all decompressed responses
        // - Node only (XHR cannot turn off decompression)
        // decompress: true // default
    }
}

// 获取实例
function getAxiosClient(config = {}) {
    let client = axios.create(combinedConfig(config));
    return client;
}

export default {
    updateAuthToken(token) {
        if (token) {
            authToken = token;
            store.commit('setToken', authToken);
            localStorage.setItem(AUTH_TOKEN_KEY, token);
        }
    },
    getAuthToken() {
        return authToken;
    },
    async get(uri, params) {
        try {
            store.commit('startLoading');
            let client = getAxiosClient({params});
            let {config, data, headers, request, status, statusText} = await client.get(uri);
            store.commit('closeLoading');
            return {config, data, headers, request, status, statusText};
        } catch (e) {
            console.error('请求[GET - ' + uri + ']时发生了错误: ', e.message);
            store.commit('closeLoading');
            return {status: 500, msg: '请求[GET - ' + uri + ']时发生了错误: '};
        }
    },
    async downloadFile(uri, params) {
        params.token = authToken;
        window.open(`/api${uri}?${Qs.stringify(params)}`);
    },
    async post(uri, formData) {
        try {
            store.commit('startLoading');
            let client = getAxiosClient({
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: [function (data) {
                    // Do whatever you want to transform the data // headers
                    return Qs.stringify(data);
                }],
                transformResponse: [function (data) {
                    // Do whatever you want to transform the data
                    console.log('response: ', data)
                    return data;
                }],
            });
            let response = await client.post(uri, formData);
            store.commit('closeLoading');
            return {status: response.status, data: response.data};
        } catch (e) {
            console.error('请求[POST - ' + uri + ']时发生了错误: ', e.message);
            store.commit('closeLoading');
            return {status: 500, msg: '请求[POST - ' + uri + ']时发生了错误: '};
        }
    },
    async postJson(uri, formData) {
        try {
            formData.token = authToken;
            store.commit('startLoading');
            let client = getAxiosClient({
                headers: {'Content-Type': 'application/json'},
                // transformRequest: [function (data) {
                //     // Do whatever you want to transform the data // headers
                //     console.log('transformRequest: ', data);
                //     data.token = authToken;
                //     return data;
                // }],
            });
            let response = await client.post(uri, formData);
            store.commit('closeLoading');
            return {status: response.status, data: response.data};
        } catch (e) {
            console.error('请求[POST - ' + uri + ']时发生了错误: ', e.message);
            store.commit('closeLoading');
            return {status: 500, msg: '请求[POST - ' + uri + ']时发生了错误: '};
        }
    },
    async upload(uri, form) {
        try {
            store.commit('startLoading');
            store.commit('startUpload');
            let client = getAxiosClient({
                data: form,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                // 这里上传超时设置为10分钟
                timeout: 10 * 60 * 1000,
                onUploadProgress: function (progressEvent) {
                    // Do whatever you want with the native progress event
                    let {loaded, total} = progressEvent;
                    console.log(progressEvent);
                    store.commit('uploadProgress', parseFloat((loaded / total).toFixed(2)));
                },
            });
            let {config, data, headers, request, status, statusText} = await client.post(uri, form);
            store.commit('endUpload');
            store.commit('closeLoading');
            return {config, data, headers, request, status, statusText};
        } catch (e) {
            console.error('请求[POST - ' + uri + ']时发生了错误: ', e);
            store.commit('endUpload');
            store.commit('closeLoading');
            return {status: 500, msg: '请求[POST - ' + uri + ']时发生了错误: '};
        }
    }
};
