<template>
  <div class="page-container">
    <a-row type="flex" >
      <a-col flex="1">
        <a-button @click="onGotoFormHandler(null)" type="primary">发布疫苗</a-button>
      </a-col>
      <a-col>
        <a-space size="small">
          <a-button @click="onTemplateDownloadHandler" type="link">导入模板下载</a-button>
          <a-button type="link" @click="onImportDataHandler">数据导入</a-button>
          <a-input-search v-model="form.keyword" placeholder="请输入关键字" style="width: 200px" @search="refreshList" />
        </a-space>
      </a-col>
    </a-row>
    <br><br>
    <a-row class="data-list">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="status" slot-scope="status">
          <div v-if="status === 1">正常疫苗</div>
          <div v-if="status === 0" style="color: #E54545">异常疫苗</div>
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
<!--            <a @click="onUniqueHandler(row.id)" class="link">查看</a>-->
            <a @click="onEditHandler(row.id)" class="link">编辑</a>
            <a-popconfirm title="确认要删除当前数据吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onRemoveHandler(row.id)">
              <a class="link">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "Product.vue",
  data() {
    return {
      // 表单
      form: {
        startDate: '',
        endDate: '',
        keyword: '',
        brandId: '',
        province: '',
        city: '',
        county: '',
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      columns: [
        {title: '编号', dataIndex:'id'},
        {title: '产品名称', dataIndex:'vaccineName'},
        {title: '产品批号', dataIndex:'batchNo'},
        {title: '收检编号', dataIndex:'checkNum'},
        {title: '生产企业', dataIndex:'companyName'},
        {title: '签发日期', dataIndex:'deliverDate'},
        {title: '结果', dataIndex:'status', scopedSlots: {customRender: 'status'}},
        {title: '访问量', dataIndex:'visitCount'},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ],
      dataList: [
      ],
      data: {
        brands: [],
        provinces: [],
        cities: [],
        counties: []
      },
    };
  },
  mounted() {
    this.$store.commit('clearRouteStack');
    this.$store.commit('changeCurRouteStack', 5);
    this.initData();
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('初始化数据...')
      // 先加载需要的数据
      this.refreshList();
    },
    // 获取筛选条件
    async fetchConditions() {
      let res = await this.$api.brandListAll();
      if(res.code === 0) {
        this.data.brands = res.list;
      }
    },

    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.vaccinePage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    // 导出
    onExportHandler() {
      console.log('导出列表');
      this.$api.adminStoreExport(this.form);
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看product: '+id);
      this.$message.info("待完善");
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑product: '+id);
      this.onGotoFormHandler(id);
    },
    // 删除
    async onRemoveHandler(id) {
      console.log('删除product: '+id);
      await this.$api.vaccineDelete({id});
      await this.refreshList();
    },
    // 前往表单页面
    async onGotoFormHandler(id) {
      // 前往登录页面
      this.$router.push(`vaccine/vaccineForm?id=${id}`);
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
    onTemplateDownloadHandler() {
      window.open('https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/templates/vaccine_template.xlsx', '_blank');
    },
    onImportDataHandler() {
      let that = this;
      let inputTag = document.createElement('input');
      inputTag.type = 'file';
      inputTag.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      inputTag.onchange = async function() {
        console.log('选中的文件: ', inputTag.files);
        if (inputTag.files.length > 0) {
          let file = inputTag.files[0];

          // 上传图片
          let res = await that.$api.vaccineImportData({file});
          if(res) {
            that.refreshList();
          }
        }
      };
      inputTag.click();
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
</style>
