<template>
  <a-space>
    <a-select class="addr-select" v-model="form.provinceId" @change="onProvinceChangedHandler">
      <a-select-option value="">请选择省</a-select-option>
      <a-select-option v-for="(b, idx) in provinceList" :key="idx" :value="b.id">
        {{b.name}}
      </a-select-option>
    </a-select>
    <a-select class="addr-select" v-if="!onlyProvince" v-model="form.cityId" @change="onCityChangedHandler">
      <a-select-option value="">请选择市</a-select-option>
      <a-select-option v-for="(b, idx) in cityList" :key="idx" :value="b.id">
        {{b.name}}
      </a-select-option>
    </a-select>
    <a-select class="addr-select" v-if="!onlyProvince" v-model="form.countyId" @change="onCountyChangedHandler">
      <a-select-option value="">请选择区县</a-select-option>
      <a-select-option  v-for="(b, idx) in countyList" :key="idx" :value="b.id">
        {{b.name}}
      </a-select-option>
    </a-select>
  </a-space>
</template>

<script>
export default {
  name: "InlineAddress",
  props: ['province', 'city', 'county', 'single', 'onlyProvince'],
  mounted() {
    this.initData(0);
  },
  data() {
    return {
      form: {
        provinceId: '',
        cityId: '',
        countyId: '',
      },
      provinceList: [],
      cityList: [],
      countyList: []
    }
  },
  watch: {
    province(oldVal, newVal) {
      if (oldVal === newVal) {
        return ;
      }
      console.log('province-changed: ', newVal);
      // this.initData(0, newVal);
    },
  },
  methods: {
    async initData(pos, pid) {
      await this.fetchRegionList(pos, pid);
      if (pos === 0) {
        // 初始化省份
        console.log('provinceId: ', this.province)
        if(this.province > 0) {
          this.form.provinceId = this.province;
          this.initData(1, this.province);
        }
      }else if(pos === 1) {
        // 初始化城市
        if (this.city > 0) {
          this.form.cityId = this.city;
          this.initData(2, this.city);
        }
      }else if (pos === 2) {
        if (this.county > 0) {
          this.form.countyId = this.county;
        }
      }
    },
    // 加载地址数据
    async fetchRegionList(type, pid) {
      let res = await this.$api.regionListByParent(pid);
      if(res.list) {
        console.log('fetch-region-list', type, pid);
        if(type === 0){
          this.provinceList = res.list;
        }else if (type === 1) {
          this.cityList = res.list;
        }else if (type === 2) {
          this.countyList = res.list;
        }
      }
    },
    // 省份改变了
    onProvinceChangedHandler(obj) {
      console.log('省份: ', obj);
      this.form.cityId = null;
      this.form.countyId = null;
      if (this.single || this.onlyProvince) {
        this.$emit('changed', this.form.provinceId, this.form.cityId, this.form.countyId);
      }
      this.fetchRegionList(1, obj);
    },
    // 城市改变了
    onCityChangedHandler(obj) {
      console.log('城市: ', JSON.stringify(obj));
      this.form.countyId = null;
      if (this.single) {
        this.$emit('changed', this.form.provinceId, this.form.cityId, this.form.countyId);
      }
      this.fetchRegionList(2, obj);
    },
    // 县区改变了
    onCountyChangedHandler(obj) {
      console.log('区县: ', JSON.stringify(obj));
      this.$emit('changed', this.form.provinceId, this.form.cityId, this.form.countyId);
    },
  }
}
</script>

<style scoped>
.addr-select {
  min-width: 150px;
}
</style>
