<template>
  <div>
    <div class="in-editor-wrapper">
      <div class="in-editor"></div>
    </div>
  </div>
</template>

<script>
import Quill from 'quill'
// 引入核心样式和主题样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  name: "Summernote",
  props: {
    value: String
  },
  data() {
    return {
      // 待初始化的编辑器
      editor: null,
      // 配置参数
      options: {
        theme: 'snow',
        modules: {
          // 工具栏的具体配置
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{'script': 'super'}],
              [{'indent': '-1'}, {'indent': '+1'}],
              [{'size': ['small', false, 'large', 'huge']}],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              [{'color': []}, {'background': []}],
              [{'align': []}],
              ['link', 'image']
            ]
          }
        },
        placeholder: '请输入内容 ...'
      }
    };
  },
  watch: {
    // 监听外部值的传入，用于将值赋予编辑器
    'value' (val) {
      // 如果编辑器没有初始化，则停止赋值
      if (!this.editor) {
        return
      }

      // 获取编辑器当前内容
      let content = this.editor.root.innerHTML

      // 外部传入了新值，而且与当前编辑器的内容不一致
      if (val && val !== content) {
        // 将外部传入的HTML内容转换成编辑器识别的delta对象
        let delta = this.editor.clipboard.convert({
          html: val
        })

        // 编辑器的内容需要接收delta对象
        this.editor.setContents(delta)
      }
    }
  },
  mounted () {
    // 初始化编辑器
    this._initEditor()
  },
  methods: {
    // 初始化编辑器
    _initEditor () {
      // 获取编辑器的DOM容器
      let editorDom = this.$el.querySelector('.in-editor')

      // 初始化编辑器
      this.editor = new Quill(editorDom, this.options);
        // window.editor = this.editor;

      // 双向绑定
      this.editor.on('text-change', () => {
        let content = this.editor.root.innerHTML;
        let briefContent = this.editor.root.innerText;
        // console.log('summernote-changed: ', content)
        this.$emit('input', content)
        this.$emit('changed', briefContent);
      });
      let toolbar = this.editor.getModule('toolbar');
      toolbar.addHandler('image', this.onSelectImageHandler.bind(this));
      // 图片自定义上传
      setTimeout(() =>
          this.editor.pasteHTML(this.value), 1000);
    },
    async onSelectImageHandler() {
      let fi = document.createElement('input');
      fi.type='file'
      fi.accept='image/jpeg,image/png,image/jpg,image/gif'
      fi.onchange = (evt) => this.onImageChoosedHandler.call(this, evt.target.files[0]);
      fi.click();
    },
    async onImageChoosedHandler(file) {
      // console.log('choosed file', file);
      let res = await this.$api.publicUpload({file});
      if (res.code === 0) {
        let url = res.data.url;
        // console.log('upload file url: ', url);
        // let contents = this.editor.getContents();
          // console.log('editor contents: ', contents, this.editor.getLength());
        this.editor.insertEmbed(this.editor.getSelection().index, 'image', url);
        // this.editor.appendImage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.in-editor-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .ql-toolbar, .ql-formats, .ql-picker-label &::before {
    position: relative;
    top: -5px;
  }
}




</style>
