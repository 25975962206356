<template>
  <div class="page-container">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :rules="rules"
                  labelAlign="left"
                  :wrapper-col="state.wrapperCol">
      <a-row>
        <a-col :span="state.formColSpan">
          <a-form-model-item label="批签发机构">
            <a-input v-model="form.approveDeliverIndustry" />
          </a-form-model-item>
          <a-form-model-item label="产品批号">
            <a-input v-model="form.batchNo" />
          </a-form-model-item>
          <a-form-model-item label="证书编号">
            <a-input v-model="form.certNo" />
          </a-form-model-item>
          <a-form-model-item label="收检编号">
            <a-input v-model="form.checkNum" />
          </a-form-model-item>
          <a-form-model-item label="生产企业">
            <a-input v-model="form.companyName" />
          </a-form-model-item>
          <a-form-model-item label="签发量">
            <a-input v-model="form.deliverCount" />
          </a-form-model-item>
          <a-form-model-item label="签发日期">
            <a-date-picker v-model="form.deliverDateObj" @change="onDateChange1" :format="state.dateFormat" />
          </a-form-model-item>
          <a-form-model-item label="签发结论">
            <a-input v-model="form.deliverResult" />
          </a-form-model-item>
          <a-form-model-item label="报告编号">
            <a-input v-model="form.reportNo" />
          </a-form-model-item>
          <a-form-model-item label="产品名称">
            <a-input v-model="form.vaccineName" />
          </a-form-model-item>
          <a-form-model-item label="产品规格">
            <a-input v-model="form.vaccineSpec" />
          </a-form-model-item>
          <a-form-model-item label="有效日期">
            <a-date-picker v-model="form.defaultExpireDate" @change="onDateChange" :format="state.dateFormat" />
          </a-form-model-item>
          <a-form-model-item label="结果">
            <a-radio-group name="radioGroup" v-model="form.status">
              <a-radio :value="0"> 该批次属于问题疫苗</a-radio>
              <a-radio :value="1"> 该批次疫苗信息正常</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "VaccineForm",
  data() {
    return {
      form: {
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        approveDeliverIndustry: '',
        batchNo: '',
        certNo: '',
        checkNum: '',
        companyName: '',
        deliverCount: '',
        deliverDate: '',
        deliverResult: '',
        expireDate: '',
        reportNo: '',
        status: 1,
        vaccineName: '',
        vaccineSpec: '',
      },
      rules: {  },
      state: {
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 10},
        dateFormat: 'YYYY-MM-DD'
      },
      data: {
      }
    };
  },
  mounted() {
    this.form.defaultExpireDate = moment(this.form.expireDate, this.state.dateFormat);
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: '疫苗', isCur: false, url: '/vaccine'},
      {name: '发布疫苗', isCur: true, url: ''},
    ]);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      if (!this.isAddForm) {
        // 加载数据
        this.fetchEntity();
      }
    },
    // 获取分类的数据
    async fetchTypeConditionList() {
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.vaccineUnqiue({id: this.$route.query.id});
      console.log('vaccine-fetchentity: ', this.id, res);
      if(res.data) {
        res.data.defaultExpireDate = moment(res.data.expireDate, this.state.dateFormat);
        res.data.deliverDateObj = moment(res.data.deliverDate, this.state.dateFormat);
        this.form = res.data;
      }
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,
        images: JSON.stringify(this.form.images),
      };
      console.log('vaccine-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.vaccineAddOrSave(form);
      if (res.code === 0) {
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
    onIntroductionChanged(content) {
      this.form.briefIntro = (content && content.length > 40) ? content.substr(0, 40) : content;
    },
    onDateChange(m, dateStr) {
      this.form.expireDate = dateStr;
    },
    onDateChange1(m, dateStr) {
      this.form.deliverDate = dateStr;
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
</style>
