<template>
  <div class="form-item-container">
    <div style="padding-left: 10px">
      <a class="link" @click="innerValue.push({name: '', desc: ''})">添加</a>
    </div>
    <table>
      <tbody>
      <tr v-for="(b,idx) in innerValue" :key="idx">
        <td><a-input v-model="b.name" placeholder="请输入检查项" style="width: 120px!important;" /></td>
        <td><a-input v-model="b.desc" placeholder="请输入检查介绍" style="width: 500px!important;" /></td>
        <td><a @click="innerValue.splice(idx, 1)" class="link">删除</a></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "PregnantCheckItemFormItem",
  props: ['value'],
  data() {
    return {
      innerValue: this.value || [],
    }
  },
  watch: {
    value() {
      this.innerValue = this.value || [];
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.form-item-container {
  padding: 10px;
  background-color: #f6f6f6;
  td {
    padding: 5px;
  }
}
</style>