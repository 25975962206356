<template>
  <div class="page-container">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :rules="rules"
                  labelAlign="left"
                  :wrapper-col="state.wrapperCol">
      <a-row>
        <a-col :span="state.formColSpan">
          <a-form-model-item label="品牌名称">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item label="品牌图片">
            <my-upload v-model="form.logoUrl" :multiple="false" />
          </a-form-model-item>
<!--          <a-form-model-item label="介绍图片">-->
<!--            <my-upload v-model="form.images" :multiple="true" />-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="品牌介绍">
            <div style="width: 800px; height: 600px">
              <f-summernote v-model="form.introduction" v-on:changed="onIntroductionChanged" />
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
export default {
  name: "BrandForm",
  data() {
    return {
      form: {
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        productType: this.$route.query.productType === 'null' ? '' : this.$route.query.productType,
        briefIntro: '',
        images: [],
        introduction: '',
        logoUrl: '',
        name: '',
      },
      rules: {  },
      state: {
        activeKey: '0',
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 10},
      },
      data: {
      }
    };
  },
  mounted() {
    let productName = ['奶粉', '辅食', '营养品', '纸尿裤', '液奶'][this.$route.query.productType - 1];
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: productName+'品牌', isCur: false, url: '/settings/brand?curTab='+this.$route.query.curTab},
      {name: '发布'+productName, isCur: true, url: ''},
    ]);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      if (!this.isAddForm) {
        // 加载数据
        this.fetchEntity();
      }
    },
    // 获取分类的数据
    async fetchTypeConditionList() {
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.brandUnqiue({id: this.$route.query.id});
      console.log('brand-fetchentity: ', this.id, res);
      if(res.data) {
        this.form = res.data;
      }
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,
        images: JSON.stringify(this.form.images),
      };
      console.log('brand-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.brandAddOrSave(form);
      if (res.code === 0) {
        this.$router.replace('/settings/brand?curTab='+this.$route.query.curTab);
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
    onIntroductionChanged(content) {
      this.form.briefIntro = (content && content.length > 40) ? content.substr(0, 40) : content;
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
</style>
