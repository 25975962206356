<template>
  <div class="page-container">
    <a-row type="flex" >
      <a-col flex="1">
          <a-space>
              <a-button @click="onGotoFormHandler(null)" type="primary">添加招聘企业</a-button>
          </a-space>
      </a-col>
      <a-col>
        <a-space size="middle">
          <a-input-search v-model="form.keyword" placeholder="请输入关键字" style="width: 200px" @search="refreshList" />
          <a-button type="link" @click="onTemplateDownloadHandler">下载模板</a-button>
          <a-button type="link" @click="onImportDataHandler">导入</a-button>
        </a-space>
      </a-col>
    </a-row>
    <br><br>
    <a-row class="data-list">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="logoUrl" slot-scope="logoUrl">
          <img :src="logoUrl" style="width: 40px; height: 40px" alt="">
        </template>
        <template slot="companyType" slot-scope="companyType">
          {{['-', '奶粉', '辅食', '营养品', '纸尿裤', '商贸', '连锁门店'][companyType]}}
        </template>
        <template slot="status" slot-scope="status">
          <a-tag v-if="status === 0">禁用</a-tag>
          <a-tag v-if="status === 1" color="green">启用</a-tag>
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
<!--            <a @click="onUniqueHandler(row.id)" class="link">查看</a>-->
            <a v-if="row.status === 1" @click="onDisabledHandler(row.id, 0)" class="link">禁用</a>
            <a v-if="row.status === 0" @click="onDisabledHandler(row.id, 1)" class="link">启用</a>
            <a @click="onEditHandler(row.id)" class="link">编辑</a>
            <a-popconfirm title="确认要删除当前数据吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onRemoveHandler(row.id)">
              <a class="link">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "RecruitCompanyList.vue",
  components: {},
  data() {
    return {
      // 表单
      form: {
        startDate: '',
        endDate: '',
          category: 1,
        keyword: '',
        brandId: '',
        province: '',
        city: '',
        county: '',
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      dataList: [
      ],
    };
  },
  mounted() {
    this.$store.commit('clearRouteStack');
    this.initData();
  },
  computed: {
    columns(){
      let c = [
        {title: '编号', dataIndex:'id'},
        {title: '企业名称', dataIndex:'companyName'},
        {title: 'logo', dataIndex:'logoUrl', scopedSlots: {customRender: 'logoUrl'}},
        {title: '企业类型', dataIndex:'companyType', scopedSlots: {customRender: 'companyType'}},
        {title: '企业规模', dataIndex:'scale'},
        {title: '联系人', dataIndex:'username'},
        {title: '联系电话', dataIndex:'linkMobile'},
        {title: '联系邮箱', dataIndex:'linkEmail'},
        {title: '浏览量', dataIndex:'viewCount'},
        {title: '状态', dataIndex:'status', scopedSlots: {customRender: 'status'}},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ]
      if ([1, 2].indexOf(this.form.category) === -1) {
        c.splice(2, 1);
      }
      return c;
    }
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('初始化数据...')
      // 先加载需要的数据
      this.refreshList();
    },
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.recruitCompanyPage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    onTemplateDownloadHandler() {
      window.open('https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/templates/recruit_company_template.xlsx', '_blank');
    },
    onImportDataHandler() {
      let that = this;
      let inputTag = document.createElement('input');
      inputTag.type = 'file';
      inputTag.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      inputTag.onchange = async function() {
        console.log('选中的文件: ', inputTag.files);
        if (inputTag.files.length > 0) {
          let file = inputTag.files[0];

          // 上传图片
          let res = await that.$api.recruitCompanyImportData({file, type: 1});
          if(res) {
            that.refreshList();
          }
        }
      };
      inputTag.click();
    },
    // 导出
    onExportHandler() {
      console.log('导出列表');
      this.$api.adminStoreExport(this.form);
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看product: '+id);
      this.$message.info("待完善");
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑product: '+id);
      this.onGotoFormHandler(id);
    },
    // 删除
    async onRemoveHandler(id) {
      console.log('删除product: '+id);
      await this.$api.recruitCompanyDelete({id});
      await this.refreshList();
    },
    // 前往表单页面
    async onGotoFormHandler(id) {
      // 前往登录页面
      this.$router.push(`/recruitCompany/recruitCompanyForm?id=${id}`);
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
    async onDisabledHandler(id, status) {
      await this.$api.recruitCompanyAddOrSave({id, status })
      this.refreshList();
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
.top_tab {
  border-bottom: 1px solid #EAEAEA;
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;

  .refresh-btn {
    padding-right: 20px;
    .btn {
      cursor: pointer;
    }
  }
}
</style>
