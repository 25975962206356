<template>
  <div class="page-container">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :rules="rules"
                  labelAlign="left"
                  :wrapper-col="state.wrapperCol">
      <a-row>
        <a-col :span="state.formColSpan">
          <a-form-model-item label="疫苗名称">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item label="注射时间">
            <vaccine-inject-times-form-item v-model="form.injectTimes" />
          </a-form-model-item>
          <a-form-model-item label="疫苗介绍">
            <wang-editor v-model="form.description" />
          </a-form-model-item>
          <a-form-model-item label="可预防传染病">
            <wang-editor v-model="form.protection" />
          </a-form-model-item>
          <a-form-model-item label="接种对象和时间">
            <wang-editor v-model="form.immuneProgress" />
          </a-form-model-item>
          <a-form-model-item label="免疫程序">
            <wang-editor v-model="form.injectTarget" />
          </a-form-model-item>
          <a-form-model-item label="注意事项">
            <wang-editor v-model="form.warning" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import VaccineInjectTimesFormItem from "./VaccineInjectTimesFormItem";
export default {
  name: "VaccineMetaForm",
  components: {
    VaccineInjectTimesFormItem
  },
  data() {
    return {
      //provinceId=310000, cityId=310100, countyId=310113
      form: {
        // 门店信息
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        name: '',
        description: '',
        protection: '',
        immuneProgress: '',
        injectTarget: '',
        warning: '',
        injectTimes: [],
      },
      rules: {  },
      state: {
        activeKey: '0',
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 18},
      },
      data: {
        brands: [],
      }
    };
  },
  mounted() {
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: '疫苗元数据列表', isCur: false, url: '/vaccineMeta/vaccineMetaList'},
      {name: '添加疫苗元数据', isCur: true, url: ''},
    ]);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      if (!this.isAddForm) {
        // 加载数据
        this.fetchEntity();
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.vaccineMetaUnqiue({id: this.$route.query.id});
      console.log('store-fetchentity: ', this.$route.query.id, res);
      if(res.data) {
        console.log('store-fetchentity-result: ', res.data)
        if (!res.data.injectTimes) {
          res.data.injectTimes = [];
        }
        this.form = res.data;
      }
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,};
      console.log('store-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.vaccineMetaAddOrSave(form);
      if (res.code === 0) {
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  $input-width: 300px;
  .ant-input {
    width: $input-width;
  }
  .ant-select {
    width: $input-width;
  }
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
</style>
