<template>
  <div class="page-container">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :wrapper-col="state.wrapperCol"
                  :rules="rules"
                  labelAlign="left">
      <a-row>
        <a-col :span="state.formColSpan">
            <a-form-model-item label="分组">
              <article-category-form-item v-model="form.category" />
            </a-form-model-item>
            <a-form-model-item label="关联品牌" v-show="form.category===3">
              <brand-form-item v-model="form.brandId" />
            </a-form-model-item>
            <a-form-model-item label="关联产品" v-show="form.category===2">
                <a-space>
                    <a-select v-model="form.productType" style="width: 100px"
                              @change="() => fetchBrandList(null, true)"
                    >
                        <a-select-option :value="1">奶粉</a-select-option>
                        <a-select-option :value="2">辅食</a-select-option>
                        <a-select-option :value="3">营养品</a-select-option>
                        <a-select-option :value="4">纸尿裤</a-select-option>
                        <a-select-option :value="5">液奶</a-select-option>
                    </a-select>
                    <a-select v-model="form.brandId" :show-search="true" style="width: 200px"
                              :filterOption="productFilterOption"
                              @change="() => fetchGoodsList(null, true)"
                    >
                        <a-select-option v-for="(b, idx) in data.brands"
                                         :key="idx"
                                         :value="b.id">
                            {{b.name}}
                        </a-select-option>
                    </a-select>
                    <a-select v-model="form.productId" :show-search="true" style="width: 200px"
                              :filterOption="productFilterOption"
                              @search="fetchGoodsList"
                              @change="onGoodsChange"
                    >
                        <a-select-option v-for="(b, idx) in data.goods"
                                         :key="idx"
                                         :value="b.id">
                            {{b.productName}}
                        </a-select-option>
                    </a-select>
                </a-space>
            </a-form-model-item>
          <a-form-model-item label="文章标题">
            <a-input v-model="form.title" />
          </a-form-model-item>
          <a-form-model-item label="来源">
            <a-input v-model="form.origin" />
          </a-form-model-item>
          <a-form-model-item v-if="form.category === 1 || form.category === 2" label="文章分组">
            <a-select class="addr-select" v-model="form.typeId" style="width: 400px">
              <a-select-opt-group v-for="(g, idx2) in data.types" :key="idx2" :label="g.name">
                <a-select-option v-for="(b, idx) in g.childs" :key="idx" :value="b.id+''">
                  {{b.name}}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="文章封面">
            <my-upload v-model="form.faceUrl" :multiple="false" />
            <p class="desc">建议尺寸200*120像素，支持、JPG或JPEG格式图片，大小不超过5MB</p>
          </a-form-model-item>
<!--          <a-form-model-item label="文章视频">-->
<!--            <my-video-upload v-model="form.videoUrl" :multiple="false" />-->
<!--          </a-form-model-item>-->
          <a-form-model-item class="full-text-editor" label="文章内容">
<!--            <f-summernote v-model="form.content" />-->
            <div style="width: 800px">
              <wang-editor v-model="form.content" />
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import ArticleCategoryFormItem from "./ArticleCategoryFormItem";
import BrandFormItem from "../brand/BrandFormItem";
export default {
  name: "ArticleForm",
  components: {BrandFormItem, ArticleCategoryFormItem},
  data() {
    return {
      form: {
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        title: '',
        productType: '',
        origin: '',
        category: parseInt(this.$route.query.category),
        typeId: '',
        faceUrl: '',
        videoUrl: '',
        content: '',
        brandId: '',
        productId: '',
      },
      rules: {  },
      state: {
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 19},
      },
      data: {
        types: [],
        goods: [],
        brands: [],
      }
    };
  },
  mounted() {
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: '文章', isCur: false, url: '/article?category='+this.$route.query.category},
      {name: '发布文章', isCur: true, url: ''},
    ]);
    this.$store.commit('changeSecondTabIdx', 0);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      this.fetchTypeConditionList();
        if (!this.isAddForm) {
            // 加载数据
            await this.fetchEntity();
        } else {
            await this.fetchBrandList();
        }
    },
    // 获取分类的数据
    async fetchTypeConditionList() {
      let res = await this.$api.articleTypeListAllAutoGroup();
      if (res.code === 0) {
        this.data.types = res.list
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.articleUnqiue({id: this.$route.query.id});
      console.log('article-fetchentity: ', this.id, res);
      if(res.data) {
        let {title, typeId, faceUrl, videoUrl, content, id, productId, origin, category, brandId, productType} = res.data;
        this.form = {title, typeId, faceUrl, videoUrl, content, id, productId, origin, category, brandId, productType};
      }

        await this.fetchBrandList();
        await this.fetchGoodsList('', true);
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,};
      console.log('article-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.articleAddOrSave(form);
      if (res.code === 0) {
        this.$store.commit('changeSecondTabIdx', 0);
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
    productFilterOption(inputValue, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      );
    },
    // 商品
    onGoodsChange() {
      // 找到商品
      this.data.goods.forEach(t => {
        if (t.id === this.form.productId) {
          this.form.productName = t.productName;
        }
      });
    },
    // 获取商品数据
    async fetchGoodsList(keyword='', isForce=false) {
      if(!isForce && !keyword) return ;
        if (this.isAddForm) {
            this.form.productId = '';
        }
      let res = await this.$api.productListAll({keyword: keyword, brandId: this.form.brandId});
      if (res.code === 0) {
        this.data.goods = res.list
      }
    },
    // 获取品牌数据
    async fetchBrandList() {
        if (this.isAddForm) {
            this.form.brandId = '';
        }
      let res = await this.$api.brandListAll(this.form.productType);
      if (res.code === 0) {
        this.data.brands = res.list
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
</style>
