<template>
  <a-range-picker v-model="valObj"
                 :show-time="getShowTimeConfig()"
                 @change="onDateChangeHandler"
                 :placeholder="placeholder" />
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
export default {
  name: "DateFormItem",
  props: ['startDate', 'endDate', 'placeholder', 'defaultTime'],
  data() {
    return {
      valObj: [null, null]
    }
  },
  mounted() {
    this.transformDateVal();
  },
  watch: {
    startDate() {
      this.transformDateVal();
    },
    endDate() {
      this.transformDateVal();
    }
  },
  methods: {
    getShowTimeConfig() {
      if (!this.defaultTime) {
        return false;
      }
      return {open:true, defaultValue: [moment(this.defaultTime, 'HH:mm:ss'), moment(this.defaultTime, 'HH:mm:ss')]};
    },
    onDateChangeHandler(_, dateStr) {
      console.log('dateStr: ', dateStr)
      this.$emit('change', ...dateStr);
    },
    transformDateVal() {
        let valObj = [...this.valObj];
      try {
        if (!this.startDate) {
          valObj[0] = null;
        } else {
          valObj[0] = moment(this.startDate);
        }
      } catch (e) {
        console.log(e)
      }
      try {
        if (!this.endDate) {
          valObj[1] = null;
        } else {
          valObj[1] = moment(this.endDate);
        }
      } catch (e) {
        console.log(e)
      }
        this.valObj = valObj;
    }
  }
}
</script>

<style scoped>

</style>
