import Vue from 'vue'
import router from './router'
import store from './store/index'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import apiService from "@/utils/axios/api-service";
import utils from '@/plugins/utils'
import qs from 'qs'
import * as clipboard from 'clipboard-polyfill'

import MenuItem from './components/MenuItem'
import Summernote from './components/Summernote'
import DashTriCol from './components/DashTriCol'
import DashBlockTwo from './components/DashBlockTwo'
import DashDuoCol from './components/DashDuoCol'
import DateGroupPicker from './components/DateGroupPicker'
import MyUpload from './components/MyUpload'
import PageTab from './components/PageTab'
import InlineAddress from './components/InlineAddress'
import MyVideoUpload from "@/components/MyVideoUpload";
import Article from "@/pages/article/Article";
import ArticleType from "@/pages/article/ArticleType";
import EvaluationEnroll from "@/pages/evaluation/EvaluationEnroll";
import EvaluationBasic from "@/pages/evaluation/EvaluationBasic";
import EvaluationUser from "@/pages/evaluation/EvaluationUser";
import BannerConfig from "@/pages/setting/BannerConfig";
import HotItemConfig from "@/pages/setting/HotItemConfig";
import EvaluationCommon from "@/pages/evaluation/EvaluationCommon";
import OrderOperateModales from "@/pages/order/OrderOperateModales";
import TecentMap from "@/components/TecentMap";
import DateFormItem from "./components/common/DateFormItem";
import DateRangeFormItem from "./components/common/DateRangeFormItem";
import SelectFormItem from "./components/common/SelectFormItem";
import WangEditor from "./components/common/WangEditor";

Vue.component('select-form-item', SelectFormItem);
Vue.component('date-form-item', DateFormItem);
Vue.component('date-range-form-item', DateRangeFormItem);
Vue.component('f-summernote', Summernote);
Vue.component('menu-item', MenuItem);
Vue.component('dash-tri-col', DashTriCol);
Vue.component('dash-block-two', DashBlockTwo);
Vue.component('dash-duo-col', DashDuoCol);
Vue.component('date-group-picker', DateGroupPicker);
Vue.component('my-upload', MyUpload);
Vue.component('inline-address', InlineAddress);
Vue.component('my-video-upload', MyVideoUpload);
Vue.component('my-page-tab', PageTab);
Vue.component('my-article', Article);
Vue.component('my-article-type', ArticleType);
Vue.component('my-evaluation-enroll', EvaluationEnroll);
Vue.component('my-evaluation-basic', EvaluationBasic);
Vue.component('my-evaluation-comment', EvaluationCommon);
Vue.component('my-evaluation-user', EvaluationUser);
Vue.component('my-banner-config', BannerConfig);
Vue.component('my-hot-item-config', HotItemConfig);
Vue.component('my-order-operate-modales', OrderOperateModales);
Vue.component('tencent-map', TecentMap);
Vue.component('wang-editor', WangEditor);

Vue.use(apiService);
Vue.use(utils);
Vue.use(Antd);
Vue.prototype.clipboard = clipboard;
Vue.config.productionTip = false

let app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
apiService.bindVue(app);
app.$qs = qs;
