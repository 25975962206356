<template>
  <div class="page-container">
    <div class="block-one">
      <h3>昨日数据</h3>
      <a-row type="flex" align="middle" justify="space-around">
        <dash-tri-col title="支付金额" :num="data.yesterday.payMoney" unit="元" :total="data.overall.payMoney" desc="总计" :red="true"/>
        <dash-tri-col title="支付订购单数" :num="data.yesterday.payOrderCount" unit="" :total="data.overall.payOrderCount" desc="总计"/>
        <dash-tri-col title="支付人数" :num="data.yesterday.payUserCount" unit="人" :total="data.overall.payUserCount" desc="总计"/>
        <dash-tri-col title="小程序打开次数" :num="data.yesterday.appOpenCount" unit="次" :total="data.overall.appOpenCount" desc="总计" :divider="true"/>
        <dash-tri-col title="订单" :num="data.orderGroup.count1" unit="单" :total="data.orderGroup.count2" desc="总计"/>
        <dash-tri-col title="小程序打开人数" :num="data.yesterday.appVisitUserCount" unit="人" :total="data.overall.appVisitUserCount" desc="总计"/>
      </a-row>
    </div>
    <div class="block-one" style="padding: 30px 30px">
      <a-row type="flex" align="middle" justify="space-between" class="block-two-line">
<!--        <dash-block-two title="订单" t1="待发货订单" :num1="data.orderGroup.count1" red1="true" t2="已完成订单" :num2="data.orderGroup.count2" divider="true"/>-->
        <dash-block-two title="纸尿裤" t1="已有纸尿裤" :num1="data.dipperGroup.count1" t2="昨日新增" :num2="data.dipperGroup.count2" divider="true"/>
<!--        <dash-block-two title="商品" t1="在售商品" :num1="data.goodsGroup.count1" t2="售罄商品" :num2="data.goodsGroup.count2" divider="true"/>-->
        <dash-block-two title="招聘" t1="已有招聘" :num1="data.recruitGroup.count1" t2="昨日新增" :num2="data.recruitGroup.count2" divider="true"/>
        <dash-block-two title="门店" t1="已有门店" :num1="data.storeGroup.count1" t2="昨日新增" :num2="data.storeGroup.count2" />
      </a-row>
      <a-row type="flex" align="middle" justify="space-between" class="block-two-line">
        <dash-block-two title="奶粉" t1="已有奶粉" :num1="data.productMilkGroup.count1" t2="昨日新增" :num2="data.productMilkGroup.count2" divider="true"/>
        <dash-block-two title="辅食" t1="已有辅食" :num1="data.productAssistGroup.count1" t2="昨日新增" :num2="data.productAssistGroup.count2" divider="true"/>
        <dash-block-two title="营养品" t1="已有营养品" :num1="data.productNutritionGroup.count1" t2="昨日新增" :num2="data.productNutritionGroup.count2"/>
      </a-row>
      <a-row type="flex" align="middle" justify="space-between" class="block-two-line">
        <dash-block-two title="文章" t1="已有文章" :num1="data.articleGroup.count1" t2="昨日新增" :num2="data.articleGroup.count2" divider="true"/>
        <dash-block-two title="疫苗" t1="已有疫苗" :num1="data.vaccineGroup.count1" t2="昨日新增" :num2="data.vaccineGroup.count2" divider="true"/>
        <dash-block-two title="代理商" t1="已有代理商" :num1="data.agentGroup.count1" t2="昨日新增" :num2="data.agentGroup.count2" divider="true"/>
<!--        <dash-block-two title="评测" t1="已有评测" :num1="data.evaluationGroup.count1" t2="已有评测" :num2="data.evaluationGroup.count2"/>-->
      </a-row>
      <a-row type="flex" align="middle" justify="space-between" class="block-two-line">
        <dash-block-two title="液奶" t1="已有液奶" :num1="data.liquidMilkGroup.count1" t2="昨日新增" :num2="data.liquidMilkGroup.count2" divider="true"/>
<!--        <dash-block-two title="疫苗" t1="已有疫苗" :num1="data.vaccineGroup.count1" t2="昨日新增" :num2="data.vaccineGroup.count2" divider="true"/>-->
<!--        <dash-block-two title="代理商" t1="已有代理商" :num1="data.agentGroup.count1" t2="昨日新增" :num2="data.agentGroup.count2" divider="true"/>-->
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  mounted() {
    this.$store.commit('clearRouteStack');
    this.$store.commit('changeCurRouteStack', 0);
    this.fetchEntity()
  },
  data() {
    return {
      data: {
        yesterday: {payMoney: 0, payOrderCount: 0, payUserCount: 0, appOpenCount: 0, appVisitUserCount: 0},
        overall: {payMoney: 0, payOrderCount: 0, payUserCount: 0, appOpenCount: 0, appVisitUserCount: 0},
        orderGroup: {count1: 0, count2: 0},
        dipperGroup: {count1: 0, count2: 0},
          liquidMilkGroup: {count1: 0, count2: 0},
        recruitGroup: {count1: 0, count2: 0},
        agentGroup: {count1: 0, count2: 0},
        goodsGroup: {count1: 0, count2: 0},
        productMilkGroup: {count1: 0, count2: 0},
        productAssistGroup: {count1: 0, count2: 0},
        productNutritionGroup: {count1: 0, count2: 0},
        articleGroup: {count1: 0, count2: 0},
        storeGroup: {count1: 0, count2: 0},
        vaccineGroup: {count1: 0, count2: 0},
        evaluationGroup: {count1: 0, count2: 0},
      }
    };
  },
  methods: {
    async fetchEntity() {
      let res = await this.$api.adminDashBoard();
      if(res && res.data) {
        this.data = res.data;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.block-one {
  padding: 20px 15px;
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;

  h3 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 15px;
  }
}
</style>
