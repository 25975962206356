<template>
  <div ref="tencentMap" :style="mapStyle"></div>
</template>

<script>
/**
 * 实现功能：
 * 1. 点击地图转义坐标点到文本框，并持有point
 * 2. 输入文本输入的时候，地图坐标发生变化
 */
import {TMapLoader} from '../utils/TMap';
const API_KEY = 'O2CBZ-JI7WW-XXIRI-O6CZH-RG4CF-WCBNL';
let MyMap = null;
export default {
  name: "TecentMap",
  props: ['width', 'height', 'lat', 'lng', 'value'],
  data() {
    return {
      mapStyle: {
        width: this.width+'px',
        height: this.height+'px',
      },
      mapHolder: null,
      map: {
        lat: 0,
        lng: 0,
        address: ''
      }
    };
  },
  mounted() {
    this.map.lat = this.lat;
    this.map.lng = this.lng;
    this.map.address = this.value;
    this.initMap();
  },
  watch: {
    value(newVal) {
      if (this.map.address === newVal) {
        return ;
      }
      this.map.address = newVal;
      this.qqGeoDecoder(newVal);
    }
  },
  methods: {
    initMap() {
      let that = this;
      this.$nextTick(() => {
        TMapLoader(API_KEY).then(TMap => {
          //定义地图中心点坐标
          MyMap = TMap;
          var center = new TMap.LatLng(that.lat, that.lng);
          //定义map变量，调用 TMap.Map() 构造函数创建地图
          var map = new TMap.Map(this.$refs.tencentMap, {
            center: center,//设置地图中心点坐标
            zoom: 16,   //设置地图缩放级别
            pitch: 43.5,  //设置俯仰角
            rotation: 45    //设置地图旋转角度
          });
          // 点击地图转换坐标点为文本地址
          map.on('click', function(evt) {
            // console.log(evt);
            let lagLng = evt.latLng;
            that.qqGeocoder(lagLng.lat, lagLng.lng);
          });
          that.mapHolder = map;
        });
      });
    },
    async qqGeoDecoder(address) {
      let res = await this.$api.adminTentcentProxy('https://apis.map.qq.com/ws/geocoder/v1/', {address, key: API_KEY})
      if (res && res.data.result && res.data.result.location) {
        let point = res.data.result.location;
        this.map.lat = point.lat;
        this.map.lng = point.lng;
        this.submitAddress()
        this.mapHolder.setCenter(new MyMap.LatLng(point.lat, point.lng));
      }
    },
    async qqGeocoder(lat, lng) {
      // https://apis.map.qq.com/ws/geocoder/v1/?location=
      let res = await this.$api.adminTentcentProxy('https://apis.map.qq.com/ws/geocoder/v1/'
          , {location: `${lat},${lng}`, key: API_KEY})
      if (res) {
        let result = res.data.result;
        console.log(res);
        // let point = res.data.result.location;
        this.map.lat = lat;
        this.map.lng = lng;
        this.map.address = result.address;
        if (result.formatted_addresses && result.formatted_addresses.recommend) {
          this.map.address = result.formatted_addresses.recommend;
        }
        this.submitAddress();
        // this.mapHolder.setCenter(new MyMap.LatLng(this.lat, this.lng));
      }
    },
    submitAddress() {
      let {lat, lng, address} = this.map;
      this.$emit('change', lat, lng, address);
    }
  }
}
</script>

<style scoped>

</style>
