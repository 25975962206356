<template>
  <div>
    <!-- 订单基本当前进度 -->
    <div class="page-container">
      <a-steps :current="state.curStep" :status="state.curStepStatus" labelPlacement="vertical">
        <a-step v-for="(st, idx) in curSteps" :key="idx" :title="st.title" :description="st.desc" />
      </a-steps>
    </div>
    <!-- 订单基本信息&买家信息 -->
    <div class="layout-order">
      <div class="layout-order-basic page-container">
        <div class="title">订单基本信息</div>
        <div class="form-item">
          <div class="label">订单编号</div>
          <div class="label-val">{{entity.orderNo}}</div>
        </div>
        <div class="form-item">
          <div class="label">下单时间</div>
          <div class="label-val">{{entity.createTime}}</div>
        </div>
        <div class="form-item">
          <div class="label">订单类型</div>
          <div class="label-val">{{mapOrderType(entity.goodsType)}}订单</div>
        </div>
      </div>
      <!--   订单状态有几个状态   -->
      <!--   1. 买家已下单，待付款   -->
      <div v-if="entity.status === 0" class="layout-order-buyer page-container">
        <a-icon class="icon" type="exclamation-circle" :style="{color:'#6794FF', fontSize: '28px'}" />
        <div class="right">
          <div class="title">买家已下单，待付款</div>
          <div class="pay-info">需付款<span class="money">￥{{mapPrice(entity.totalPrice)}}</span></div>
          <div class="warn-time">还有<span class="time">{{state.remainTimerTxt}}</span>订单将自动取消</div>
          <div>
            <a-button @click="onCancelOrderHandler">取消订单</a-button>
          </div>
          <div class="order-memo-area">
            <span class="label">订单备注</span>
            <span class="content">{{ entity.memo }}</span>
            <a class="link" @click="showMemoOrderHandler">添加备注</a>
          </div>
        </div>
      </div>
      <!--   2. 买家已付款，待商家发货   -->
      <div v-if="entity.status === 2" class="layout-order-buyer page-container">
        <a-icon class="icon" type="exclamation-circle" :style="{color:'#6794FF', fontSize: '28px'}" />
        <div class="right">
          <div class="title">买家已付款，等待商家发货</div>
          <a-space>
            <a-button @click="onSendGoodsHandler" type="primary">发货</a-button>
            <a-button @click="onCancelOrderHandler">取消订单</a-button>
          </a-space>
          <div class="order-memo-area">
            <span class="label">订单备注</span>
            <span class="content">{{ entity.memo }}</span>
            <a class="link" @click="showMemoOrderHandler">添加备注</a>
          </div>
        </div>
      </div>
      <!--   3. 商家已发货，待买家收货   -->
      <div v-if="entity.status === 3" class="layout-order-buyer page-container">
        <a-icon class="icon" type="exclamation-circle" :style="{color:'#6794FF', fontSize: '28px'}" />
        <div class="right">
          <div class="title">商家已发货，待买家收货</div>
          <div class="warn-time">还有<span class="time">{{state.remainTimerTxt}}</span>订单将自动确认收货</div>
          <div>
            <a-button @click="onCancelOrderHandler">取消订单</a-button>
          </div>
          <div class="order-memo-area">
            <span class="label">订单备注</span>
            <span class="content">{{ entity.memo }}</span>
            <a class="link" @click="showMemoOrderHandler">添加备注</a>
          </div>
        </div>
      </div>
      <!--   4. 订单完成   -->
      <div v-if="entity.status === 4" class="layout-order-buyer page-container">
        <a-icon class="icon" type="exclamation-circle" :style="{color:'#6794FF', fontSize: '28px'}" />
        <div class="right">
          <div class="title">商家已发货，待买家收货</div>
          <div class="warn-time">还有<span class="time">19小时23分40秒</span>订单将自动确认收货</div>
          <div>
            <a-button @click="onCancelOrderHandler">取消订单</a-button>
          </div>
          <div class="order-memo-area">
            <span class="label">订单备注</span>
            <span class="content">{{ entity.memo }}</span>
            <a class="link" @click="showMemoOrderHandler">添加备注</a>
          </div>
        </div>
      </div>
      <!--   5. 订单已取消   -->
      <div v-if="entity.status === 9" class="layout-order-buyer page-container">
        <a-icon class="icon" type="exclamation-circle" :style="{color:'#6794FF', fontSize: '28px'}" />
        <div class="right">
          <div class="title">订单已取消（未支付取消）</div>
          <div class="warn-time">订单取消原因：{{ entity.cancelReason }} - {{entity.cancelAddition}}</div>
          <div class="order-memo-area">
            <span class="label">订单备注</span>
            <span class="content">{{ entity.memo }}</span>
            <a class="link" @click="showMemoOrderHandler">添加备注</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 收货人信息 -->
    <div class="page-container layout-order-get-and-pay">
      <div class="layout-order-recieve">
        <div class="title">收货人信息</div>
        <div class="form-item">
          <div class="label">买家</div>
          <div class="label-val">{{entity.buyerNickname}}</div>
        </div>
        <div class="form-item">
          <div class="label">收货人</div>
          <div class="label-val">{{entity.buyerUserName}}</div>
        </div>
        <div class="form-item">
          <div class="label">手机号</div>
          <div class="label-val">{{entity.buyerMobile}}</div>
        </div>
        <div class="form-item">
          <div class="label">收货地址</div>
          <div class="label-val">{{entity.buyerAddress}}</div>
        </div>
        <div class="form-item">
          <div class="label">买家留言</div>
          <div class="label-val">
            <div class="gray-area">{{entity.buyerMemo}}</div>
          </div>
        </div>
      </div>
      <a-divider type="vertical" class="divider" />
      <div class="layout-order-pay">
        <div class="title">付款信息</div>
        <div class="form-item">
          <div class="label">支付方式</div>
          <div class="label-val">{{mapNullTxt(entity.payType)}}</div>
        </div>
        <div class="form-item">
          <div class="label">支付状态</div>
          <div class="label-val">{{mapNullTxt(entity.payStatus)}}</div>
        </div>
        <div class="form-item">
          <div class="label">支付时间</div>
          <div class="label-val">{{mapNullTxt(entity.payTime)}}</div>
        </div>
        <div class="form-item">
          <div class="label">支付金额</div>
          <div class="label-val">{{mapNullTxt(mapPrice(entity.payMoney))}}</div>
        </div>
        <div class="form-item">
          <div class="label">支付流水号</div>
          <div class="label-val">{{mapNullTxt(entity.payTradeNo)}}</div>
        </div>
      </div>
    </div>
    <!-- 商品详情 -->
    <div class="page-container">
      <div class="data-table">
        <div class="theader">
          <div class="col1">商品信息</div>
          <div class="col2">价格（元）</div>
          <div class="col3">数量</div>
          <div class="col4">商品实收（元）</div>
        </div>
        <div class="tbody">
          <div class="trow" v-for="(ordi, idx) in entity.detailList" :key="idx">
            <div class="trow-cols">
              <div class="col1">
                <div class="goods-item">
                  <img :src="ordi.itemImageUrl" alt="">
                  <div class="goods-content">
                    <div class="title">{{ordi.itemName}}</div>
                    <div class="desc">
                      <a-space>
                        <span>颜色: {{ordi.specification.color}}</span>
                        <span>尺寸: {{ordi.specification.size}}</span>
                      </a-space>
                    </div>
                    <div class="no">注册号: {{ordi.product.registerNo}}</div>
                  </div>
                </div>
              </div>
              <div class="col2">
                <div class="price">￥{{mapPrice(ordi.price)}}</div>
              </div>
              <div class="col3">
                <div>{{ordi.buyCount}}</div>
              </div>
              <div class="col4">{{mapPrice(ordi.price*ordi.buyCount)}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="sum-area">
        <div class="sum-block">
          <div class="fb">商品总价: <span class="spec">￥{{mapPrice(entity.originalPrice)}}</span></div>
          <div class="fb">+运费: <span class="spec">￥{{mapPrice(entity.expressPrice)}}</span></div>
          <a-divider class="divider" />
          <div class="fi">订单实收：<span class="spec">￥{{mapPrice(entity.totalPrice)}}</span></div>
        </div>
      </div>
    </div>
    <!--  弹窗  -->
    <my-order-operate-modales v-model="state.curModal" :curOrder="entity" @refresh="fetchEntity" />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "OrderDetail",
  data() {
    return {
      entity: {},
      curSteps: [],
      state: {
        curStep: 0,
        curModal: 0,
        // wait process finish error
        curStepStatus: 'process',
        remainTimerTxt: '0小时0分0秒'
      },
      data: {
      }
    };
  },
  mounted() {
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: '订单', isCur: false, url: '/order'},
      {name: '订单详情', isCur: true, url: ''},
    ]);
    this.fetchEntity();
  },
  computed: {
    entityId() {
      return this.$route.query.id;
    }
  },
  methods: {
    // 获取订单信息
    async fetchEntity() {
      let res = await this.$api.orderUnqiue({id: this.entityId});
      if (res) {
        console.log('[order-detail] entity: ', res.data);
        this.entity = res.data;
        let orderStatus = res.data.status;
        let steps = [];
        console.log('[order-detail] status: ', orderStatus);
        // 确定当前是到哪一步了
        switch (orderStatus) {
          // 下单未支付
          case 0:
            this.state.curStep = 1;
            this.state.curStepStatus = 'process';
            steps.push({title: '买家下单', desc: this.entity.createTime});
            steps.push({title: '买家付款', desc: ''});
            steps.push({title: '商家发货', desc: ''});
            steps.push({title: '交易完成', desc: ''});
            this.startTimerCount(this.entity.payExpireTime);
            break;
          // 助力中
          case 1:
            this.state.curStep = 2;
            this.state.curStepStatus = 'process';
            steps.push({title: '买家下单', desc: this.entity.createTime});
            steps.push({title: '买家付款', desc: this.entity.payTime});
            steps.push({title: '商家发货', desc: ''});
            steps.push({title: '交易完成', desc: ''});
            break;
          // 代发货
          case 2:
            this.state.curStep = 2;
            this.state.curStepStatus = 'process';
            steps.push({title: '买家下单', desc: this.entity.createTime});
            steps.push({title: '买家付款', desc: this.entity.payTime});
            steps.push({title: '商家发货', desc: ''});
            steps.push({title: '交易完成', desc: ''});
            break;
          // 待收货
          case 3:
            this.state.curStep = 3; this.state.curStepStatus = 'process';
            console.log(this.state);
            steps.push({title: '买家下单', desc: this.entity.createTime});
            steps.push({title: '买家付款', desc: this.entity.payTime});
            steps.push({title: '商家发货', desc: this.entity.expressSendTime});
            steps.push({title: '交易完成', desc: ''});
            this.startTimerCount(this.entity.ensureExpireTime);
            break;
          // 完成
          case 4:
            this.state.curStep = 3;
            this.state.curStepStatus = 'finish';
            steps.push({title: '买家下单', desc: this.entity.createTime});
            steps.push({title: '买家付款', desc: this.entity.payTime});
            steps.push({title: '商家发货', desc: this.entity.expressSendTime});
            steps.push({title: '交易完成', desc: this.entity.updateTime});
            break;
          // 超时
          case 8:
            this.state.curStep = 1; this.state.curStepStatus = 'error';
            steps.push({title: '买家下单', desc: this.entity.createTime});
            steps.push({title: '支付超时', desc: ''});
          break;
          // 取消订单
          case 9:
            this.state.curStep = 1; this.state.curStepStatus = 'error';
            steps.push({title: '买家下单', desc: this.entity.createTime});
            steps.push({title: '已取消', desc: ''});
          break;
          // 助力失败
          case 10:
            this.state.curStep = 1; this.state.curStepStatus = 'error';
            steps.push({title: '买家下单', desc: this.entity.createTime});
            steps.push({title: '助力失败', desc: ''});
          break;
        }
        // 根据订单状态决定是显示什么步骤
        this.curSteps = steps;
      }
    },
    // 开始一次倒计时显示
    startTimerCount(expireTime) {
      let that = this;
      let timerIdx = setInterval(() => {
        let duration = moment.duration(moment(expireTime).unix()*1000-Date.now());
        let arr = [];
        if (duration.days() > 0) {
          arr.push(`${duration.days()}天`);
        }
        if (duration.hours() > 0) {
          arr.push(`${duration.hours()}小时`);
        }
        if (duration.minutes() > 0) {
          arr.push(`${duration.minutes()}分`);
        }
        if (duration.seconds() > 0) {
          arr.push(`${duration.seconds()}秒`);
        }
        if (duration.length === 0) {
          clearInterval(timerIdx);
        }
        that.state.remainTimerTxt = arr.join('');
      }, 1000);

    },
    mapOrderType(type) {
      return ['', '商品', '基因'][type];
    },
    mapOrderStatus(type) {
      return ['待付款', '助力中', '待发货', '待收货', '完成', '','','','超时', '取消', '助力失败'][type];
    },
    mapPrice(price) {
      return (price || 0).toFixed(2);
    },
    mapExpressFee(fee) {
      if(fee > 0) {
        return '邮费: ￥' + this.mapPrice(fee) + '元';
      }
      return '包邮';
    },
    mapNullTxt(val) {
      if (this.entity.status === 0 || this.entity.status > 7) {
        return '-';
      }
      if (!val || val.length === 0) {
        return '-';
      }
      return val;
    },
    // 订单发货
    showMemoOrderHandler() {
      this.curOrder = this.entity;
      this.state.curModal = 3;
    },
    // 订单发货
    async onSendGoodsHandler() {
      this.curOrder = this.entity;
      this.state.curModal = 1;
    },
    // 订单发货
    async onCancelOrderHandler() {
      this.curOrder = this.entity;
      this.state.curModal = 2;
    },
  }
}
</script>

<style scoped lang="scss">
$layoutGap: 20px;
.page-container {
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  font-size: 12px;
  min-height: 100px;
  padding: 20px;
  margin-bottom: $layoutGap;
  .title {
    /* text-14 加粗 */
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    height: 30px;
    line-height: 22px;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
  }
}
.layout-order-get-and-pay {
  display: flex;
  .layout-order-recieve {
    flex: 1;
  }
  .divider {
    height: 180px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .layout-order-pay {
    flex: 1;
  }
}
.form-item {
  margin-bottom: 10px;
  display: flex;
  .label {
    width: 80px;
    color: #888;
  }
  .label-val {
    color: #222;
    flex: 1;
    .gray-area {
      padding: 10px;
      background: #FAFAFA;
      min-height: 20px;
    }
  }
}
.layout-order {
  display: flex;
  .layout-order-basic {
    width: 400px;
  }
  .layout-order-buyer {
    flex: 1;
    margin-left: $layoutGap;
    display: flex;
    .icon {
      margin-right: 10px;
    }
    .right {
      flex: 1;
    }
    .pay-info {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 10px;
      /* 文字颜色/黑_222 */
      color: #222222;
      .money {
        color: #E54545;
      }
    }
    .warn-time {
      margin-bottom: 10px;
      .time {
        color: #E54545;
      }
    }
    .order-memo-area {
      padding: 10px;
      margin-top: 10px;
      background: #FAFAFA;
      color: #888;
      .label {}
      .content {
        color: #222222;
        margin-left: 10px;
        margin-right: 10px;
      }
      .link {
        color: #5A66FF;
      }
    }
  }
}

.data-table {
  .col1 {
    width: 42%;
  }
  .col2 {
    width: 9%;
  }
  .col3 {
    width: 23%;
  }
  .col4 {
    width: 9%;
  }
  .col5 {
    width: 17%;
  }
  $grayColor: #f5f5f5;
  $borderColor: #EAEAEA;
  .theader {
    background: $grayColor;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    padding: 10px 20px;
    font-weight: 500;
    color: #222;
  }
  .tbody {
    .trow {
      color: #222222;
      line-height: 20px;
      .trow-head {
        padding: 10px 20px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background: $grayColor;
      }
      .trow-cols {
        display: flex;
        border: 1px solid $borderColor;
        &>div {
          border-right: 1px solid $borderColor;
          padding: 20px;
          font-size: 12px;
        }
        &>div:last-child {
          border-right: none;
        }
        .col1 {
          .goods-item {
            $goodsImgSize: 60px;
            display: flex;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
            img {
              background: $grayColor;
              width: $goodsImgSize;
              height: $goodsImgSize;
              border-radius: 3px;
            }
            .goods-content {
              color: #888;
              flex: 1;
              margin-left: 10px;
              line-height: 20px;
              .title {
                color: #222222;
              }
              .desc {}
              .no {}
            }
            .goods-num {
              text-align: right;
            }
          }
        }
        .col2 {
          .price {
            color: #E54545;
          }
          .desc {

          }
        }
        .col4 {
          font-weight: 500;
        }
        .col5 {
          text-align: center;
        }
      }
    }
  }
}
.sum-area {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  line-height: 23px;
  color: #222222;
  .divider {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .fi {
    font-size: 14px;
  }
  .spec {
    color: #E54545;
  }
}
</style>
<style>
.ant-steps-item-description {
  width: 130px;
  font-size: 12px!important;
}
.ant-steps-item-title::after {
  font-size: 14px;
}
</style>
