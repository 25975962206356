<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <a-spin :spinning="this.$store.state.isLoading" tip="加载中...">
        <router-view></router-view>
      </a-spin>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      locale: zhCN
    };
  },
}
</script>

<style>
#app {
}
</style>
