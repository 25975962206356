export function TMapLoader(key) {
    return new Promise(function(resolve, reject) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://map.qq.com/api/gljs?v=1.exp&key='+key;
        script.onerror = reject;
        script.onload = function () {
            resolve(window.TMap);
        };
        document.head.appendChild(script);
    });
}
