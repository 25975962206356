<template>
  <div>
    <!--  选择商品  -->
    <a-modal v-model="state.showSelectDialog" :title="'添加'+this.form.modelName"
             okText="确定"
             :width="960"
             cancelText="取消"
             @ok="onConfirmSelectedHandler">
      <div>
        <div style="height: 60px; width: 100%;">
          <a-input-search v-model="form.keyword" style="float: right; width: 500px;"
                          placeholder="请输入搜索关键字"
                          size="large"
                          enter-button @search="onKeywordChange" />
        </div>
        <div class="item-list">
          <div v-for="(b, idx) in data.optionList"
               :key="idx"
               @click="onOptionChoosedHandler(b)"
               :class="{item: true, checked: isChecked(b)}">
            <div class="triangle-topleft"><a-icon class="icon-check" type="check" /></div>
            <img :src="b.faceUrl" />
            <div class="block">
              <div class="title">{{b.title}}</div>
              <div class="no">{{ b.no }}</div>
            </div>
          </div>
        </div>
        <a-pagination :current="page.current" :total="page.total" :show-quick-jumper="true" @change="onDataListChange" />
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "ProductChooseConfig",
  props: {
    value: {
      required: true,
    },
    mode: {
      required: false,
      type: String, // single|multiple
      defaultValue: 'single'
    },
  },
  data() {
    return {
      form: {
        modalName: '',
        keyword: '',
        checkedList: []
      },
      data: {
        optionList: [],
      },
      page: { current: 1, pageNow: 1, pageSize: 12, total: 0, size: '10', showQuickJumper: true },
      state: {
        type: 1,
        showSelectDialog: false,
        isMultiSelect: false,
        curBannerAfterTrigger: () => console.log('not complemented!'),
      },
    };
  },
  mounted() {
    this.state.isMultiSelect = this.mode === 'multiple';
    this.transferValue();
  },
  watch: {
    value() {
      this.transferValue();
    },
  },
  methods: {
    transferValue() {
      // this.form.checkedList = this.mode === 'single' ? this.getSingleValue(this.value) : this.getMultiValue(this.value);
    },
    getSingleValue(val) {
      return val ? [val] : [];
    },
    getMultiValue(val) {
      return val ? val : [];
    },
    // 加载数据列表
    async fetchSelectedItemList(keyword) {
      // 1牛奶，2辅食，3营养品，4评测，5奶粉品牌，6辅食品牌，7营养品品牌，8评测, 10热搜奶粉，11热搜辅食，12热搜营养品
      let {current, pageSize} = this.page;
      let type = this.state.type;
      if (type < 4) {
        let res = await this.$api.productPage({pageNow: current, pageSize, productType: type, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
              .map(t => ({id: t.id, faceUrl: t.faceUrl, title: t.productName, no: t.registerNo}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      } else if (type === 4 || type === 25) {
        let res = await this.$api.evaluationPage({pageNow: current, pageSize, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
              .map(t => ({id: t.id, faceUrl: t.faceUrl, title: t.productName}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      } else if (type >=5 && type <=7) {
        let res = await this.$api.brandPage({pageNow: current, pageSize, productType: type-4, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
              .map(t => ({id: t.id, faceUrl: t.logoUrl, title: t.name}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      } else if (type === 8) {
        let res = await this.$api.evaluationEnrollPage({pageNow: current, pageSize, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
              .map(t => ({id: t.id, faceUrl: t.headImageUrl, title: t.userName, no: t.commentContent}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      }else if (type >= 10 && type <= 12) {
        let res = await this.$api.brandPage({pageNow: current, pageSize, productType: type-9, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
              .map(t => ({id: t.id, faceUrl: t.logoUrl, title: t.name}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      }else if (type >= 21 && type <= 23) {
        let res = await this.$api.brandPage({pageNow: current, pageSize, productType: type-20, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
              .map(t => ({id: t.id, faceUrl: t.logoUrl, title: t.name}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      } else if (type === 24) {
        let res = await this.$api.goodsPage({pageNow: current, pageSize, curTab: 1, keyword});
        if(res.data) {
          this.data.optionList = res.data.page.records
              .map(t => ({id: t.id, faceUrl: t.logoUrl, title: t.goodsName, no: t.introduction}));
          this.page.total = res.data.page.total;
          this.page.pages = res.data.page.pages;
        }
      }
    },
    // 选择商品
    async showChooseDialog(type, afterTrigger) {
      this.state.type = type;
      this.page.pageNow = 1;
      this.form.modelName = ['奶粉', '辅食', '营养品', '评测', '奶粉品牌', '辅食品牌', '营养品品牌', '评论', ''
        , '热搜奶粉', '热搜辅食', '热搜营养品'][type - 1];
      if (type > 20) {
        this.form.modelName = ['奶粉', '辅食', '营养品', '试用', '评测'][type - 21];
      }
      this.state.showSelectDialog = true;
      this.form.keyword = '';
      this.form.curBannerAfterTrigger = afterTrigger;
      // 打开商品选择界面
      this.fetchSelectedItemList();
    },
    isChecked(b) {
      let checkedList = this.getCheckedList();
      if (this.state.isMultiSelect) {
        for (let i = 0; i < checkedList.length; i++) {
          if (checkedList[i].id === b.id) {
            b.checked = true;
            return true;
          }
        }
        return false;
      } else {
        for (let i = 0; i < checkedList.length; i++) {
          if (checkedList[i].id === b.id) {
            b.checked = true;
            return true;
          }
        }
      }
    },
    getCheckedList() {
      return this.form.checkedList;
    },
    // 选择
    onOptionChoosedHandler(b) {
      // 先检查是否选择
      let checkedList = this.getCheckedList();
      if (this.state.isMultiSelect) {
        if (this.isChecked(b)) {
          for (let i = 0; i < checkedList.length; i++) {
            if (checkedList[i].id === b.id) {
              checkedList.splice(i, 1);
              break;
            }
          }
        } else {
          checkedList.push(b);
        }
        console.log('checkedList', checkedList);
      } else {
        this.$set(this.form.checkedList, 0, b);
        this.$emit('input', b.id);
        console.log('checkedList', checkedList);
        // if (this.form.curBannerItem) {
        //   this.form.curBannerItem.linkTargetId = b.id;
        // }
      }
    },
    // 选中商品
    async onConfirmSelectedHandler() {
      this.form.keyword = '';
      this.state.showSelectDialog = false;
    },
    // 移除商品
    async onRemoveChoosedHandler(b, type) {
      let checkedList = this.getCheckedList(type);
      for (let i = 0; i < checkedList.length; i++) {
        if (b.id === checkedList[i].id) {
          await this.$api.hotItemsRemoveBy({itemId: b.id, type: type});
          checkedList.splice(i, 1);
        }
      }
    },
    // 翻页
    onDataListChange(current) {
      console.log('表格列表发生了变更: ', current, this.form.keyword);
      this.page.current = current;
      this.fetchSelectedItemList(this.form.keyword);
    },
    // 关键字搜索
    onKeywordChange(kw) {
      this.fetchSelectedItemList(kw);
    },
  },
}
</script>

<style scoped lang="scss">

.item-list {
  display: flex;
  flex-wrap: wrap;
  .item {
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    width: 280px;
    position: relative;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    .triangle-topleft {
      display: none;
    }
    img {
      width: 48px;
      height: 48px;
      margin-right: 10px;
      /* 描边/分割线_EAEAEA */
      border: 1px solid #EAEAEA;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .block {
      .title {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        /* or 133% */
        color: #222222;
      }
      .no {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #888888;
      }
    }
    &.checked {
      border: 1px solid #5A66FF;
      $triangleSize: 30px;
      .triangle-topleft {
        display: inline-block;
        width: 0;
        height: 0;
        border-top: $triangleSize solid #5A66FF;
        border-right: $triangleSize solid transparent;
        position: absolute;
        top: 0;
        left: 0;
        .icon-check {
          position: absolute;
          left:3px;
          top: -28px;
          color: #fff;
        }
      }
    }
  }
}
</style>
