import { render, staticRenderFns } from "./HotItemSetting.vue?vue&type=template&id=7132705c&scoped=true"
import script from "./HotItemSetting.vue?vue&type=script&lang=js"
export * from "./HotItemSetting.vue?vue&type=script&lang=js"
import style0 from "./HotItemSetting.vue?vue&type=style&index=0&id=7132705c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7132705c",
  null
  
)

export default component.exports