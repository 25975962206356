<template>
  <a-select v-model="innerValue"
            :mode="innerMode"
            :showSearch="true"
            style="width: 400px"
            :placeholder="placeholder"
            :filterOption="onSearchHandler"
            @change="onChangeHandler">
    <a-select-option v-for="(b,idx) in list"
                     :key="idx"
                     :value="b[innerValueProp]">{{b[innerLabelProp]}}</a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "SelectFormItem",
  props: ['value', 'placeholder', 'list', 'labelProp', 'valueProp', 'mode'],
  mounted() {
  },
  data() {
    return {
      innerValue: this.mode === 'default' ? (this.value ? this.value : null) : (this.value ? this.value : []),
      innerLabelProp: this.labelProp || 'name',
      innerValueProp: this.valueProp || 'id',
      innerMode: !this.mode ?'default':'multiple'
    };
  },
  watch: {
    value() {
      if (this.mode === 'default') {
        this.innerValue = this.value ? this.value : null;
      } else {
        this.innerValue = this.value ? this.value : [];
      }
    }
  },
  methods: {
    onChangeHandler(val) {
      if (this.innerMode === 'default') {
        this.onDefaultChangeHandler(val);
      }else if (this.innerMode === 'multiple') {
        this.onMultipleChangeHandler(val);
      }
    },
    // 多选
    onMultipleChangeHandler(selectedList) {
      console.log('multiple selected: ', selectedList, this.list);
      let opts = [];
      for (let i = 0; i < this.list.length; i++) {
        if (selectedList.indexOf(this.list[i][this.innerValueProp]) > -1) {
          opts.push(this.list[i]);
        }
      }
      console.log('multi change: ', selectedList, opts);
      this.$emit('input', selectedList);
      this.$emit('change', opts);
    },
    // 默认改变处理
    onDefaultChangeHandler(val) {
      let opt = null;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i][this.innerValueProp] === val) {
          opt = this.list[i];
          break;
        }
      }
      console.log('change: ', opt);
      this.$emit('input', val);
      this.$emit('change', opt);
    },
    onSearchHandler(inputValue, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      );
    }
  }
}
</script>

<style scoped>

</style>
