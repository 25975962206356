<template>
  <div class="page-container">
    <a-row type="flex" >
      <a-col flex="1">
        <a-button @click="onGotoFormHandler(null)" type="primary">发布{{ productTypeName }}</a-button>
      </a-col>
      <date-group-picker v-on:change="onDateRangeChanged"></date-group-picker>
      <a-input-search v-model="form.keyword" placeholder="请输入关键字" style="width: 200px" @search="refreshList" />
      <a-button type="link" icon="export" @click="onExportHandler">导出</a-button>
    </a-row>
    <br><br>
    <a-row class="data-list">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="faceUrl" slot-scope="faceUrl">
          <img :src="faceUrl" width="100" height="100" />
        </template>
        <template slot="score" slot-scope="row">
          <span style="padding-right: 10px">{{row.score}}分</span>
          <a-rate :default-value="row.scoreVal" allow-half disabled />
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
<!--            <a @click="onUniqueHandler(row.id)" class="link">查看</a>-->
            <a @click="onEditHandler(row.id)" class="link">编辑</a>
            <a-popconfirm title="确认要删除当前数据吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onRemoveHandler(row.id)">
              <a class="link">删除</a>
            </a-popconfirm>
            <a-popconfirm title="确认要上架该产品吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onToppedHandler(row.id)">
              <a v-if="!row.hasTopped" class="link">上架</a>
            </a-popconfirm>
            <a-popconfirm title="确认要下架当前产品吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onUnToppedHandler(row.id)">
              <a v-if="row.hasTopped" class="link">下架</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "Product.vue",
  data() {
    return {
      // 表单
      form: {
        startDate: '',
        endDate: '',
        keyword: ''
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      dataList: [
      ]
    };
  },
  mounted() {
    this.refreshList();
    this.$store.commit('clearRouteStack');
    this.$store.commit('changeCurRouteStack', parseInt(this.$route.query.type));
  },
  computed: {
    productTypeName() {
      return ['奶粉', '辅食', '营养品', '纸尿裤', '液奶'][this.$route.query.type-1];
    },
    productType() {
      return this.$route.query.type;
    },
    columns() {
      if (this.$route.query.type < 4) {
        return [
          {title: '编号', dataIndex: 'id'},
          {title: '产品名称', dataIndex: 'productName'},
          {title: '产品类别', dataIndex: 'productGroup'},
          {title: '奶查评分', dataIndex: '', scopedSlots: {customRender: 'score'}},
          {title: '市场价（元）', dataIndex: 'price'},
          {title: '浏览量', dataIndex: 'viewCount'},
          {title: '操作', width: '210px', dataIndex: '', scopedSlots: {customRender: 'action'}},
        ];
      } else {
        return [
          {title: '编号', dataIndex:'id'},
          {title: '产品名称', dataIndex:'productName'},
          {title: '产品类别', dataIndex:'productGroup'},
          {title: '市场价（元）', dataIndex:'price'},
          {title: '浏览量', dataIndex:'viewCount'},
          {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
        ]
      }
    }
  },
  watch: {
    productType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$store.commit('changeCurRouteStack', parseInt(this.$route.query.type));
        this.refreshList();
      }
    }
  },
  methods: {
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.productPage({productType: this.productType,pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, scoreVal: t.score/2, key: idx}));
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    // 导出
    onExportHandler() {
      console.log('导出列表');
      this.$api.adminProductExport({...this.form, productType: this.productType});
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看product: '+id);
      this.$router.push(`/productView?id=${id}&productType=${this.productType}`);
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑product: '+id);
      this.onGotoFormHandler(id);
    },
    // 删除
    async onRemoveHandler(id) {
      console.log('删除product: '+id);
      await this.$api.productDelete({id});
      await this.refreshList();
    },
    // 置顶
    async onToppedHandler(id) {
      console.log('置顶product: '+id);
      await this.$api.productTopped({id, action: 1});
      await this.refreshList();
    },
    // 取消置顶
    async onUnToppedHandler(id) {
      console.log('取消置顶product: '+id);
      await this.$api.productTopped({id, action: 0});
      await this.refreshList();
    },
    // 前往表单页面
    async onGotoFormHandler(id) {
      // 前往登录页面
      this.$router.push(`productForm?id=${id}&productType=${this.productType}`);
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
</style>
