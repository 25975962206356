<template>
<div class="page-container">
  <a-tabs :active-key="state.curTab" @change="onTabChangeHandler">
    <a-tab-pane key="1" tab="奶粉品牌">
      <brand-list :productType="1" :curTab="1" />
    </a-tab-pane>
    <a-tab-pane key="2" tab="辅食品牌">
      <brand-list :productType="2" :curTab="2" />
    </a-tab-pane>
    <a-tab-pane key="3" tab="营养品品牌">
      <brand-list :productType="3" :curTab="3" />
    </a-tab-pane>
    <a-tab-pane key="8" tab="纸尿裤品牌">
      <brand-list :productType="4" :curTab="8" />
    </a-tab-pane>
    <a-tab-pane key="9" tab="液奶品牌">
      <brand-list :productType="5" :curTab="9" />
    </a-tab-pane>
<!--    <a-tab-pane key="4" tab="代理商留言" force-render>-->
<!--      <brand-enroll :curTab="4" />-->
<!--    </a-tab-pane>-->
<!--    <a-tab-pane key="5" tab="门店入驻申请">-->
<!--      <store-enroll :curTab="5" />-->
<!--    </a-tab-pane>-->
<!--    <a-tab-pane key="6" tab="页面配置">-->
<!--      <page-config :curTab="6" />-->
<!--    </a-tab-pane>-->
<!--    <a-tab-pane key="7" tab="启动图">-->
<!--      <app-splash :curTab="7" />-->
<!--    </a-tab-pane>-->
  </a-tabs>
</div>
</template>

<script>
import BrandList from "@/pages/setting/BrandList";
export default {
  name: "Settings",
  components: {
    'brand-list': BrandList,
    // 'page-config': PageConfig,
  },
  data() {
    return {
      // 表单
      form: {
        startDate: '',
        endDate: '',
        keyword: ''
      },
      // 状态
      state: {
        curTab: '1'
      },
    }
  },
  mounted() {
    this.$store.commit('clearRouteStack');
    this.$store.commit('changeCurRouteStack', 11);
    if (this.$route.query.curTab) {
      this.state.curTab = this.$route.query.curTab;
    }
  },
  methods: {
    onTabChangeHandler(curTab) {
      if (this.state.curTab === curTab) {
        return;
      }
      this.state.curTab = curTab;
    }
  }
}
</script>

<style scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
</style>
