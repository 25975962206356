import apiHelper from './api-helper';
import Vue from "vue";
import Qs from 'qs'

/**
 * 提示操作：this.$vue.$message.error("登录密码错误");
 *
 * 跳转操作: this.$vue.$route.push('/login')
 *
 */
class ApiService {
    $vue = null

    bindVue(vue) {
        this.$vue = vue;
    }

    /**
     * 处理结果, 主要是响应报错和路由
     * @param status
     * @param data
     * @returns {boolean}
     */
    handleResult({status, data}) {
        if(status !== 200) {
            this.$vue.$message.error("服务器请求发送了错误，请稍后重试!");
            return false;
        }
        if(typeof data === 'string') {
            data = JSON.parse(data);
        }
        if (!data || data.code === undefined) {
            this.$vue.$message.error("服务器响应错误: "+data);
            return false;
        }
        if (data.code === 500) {
            this.$vue.$message.error(data.msg);
            return false;
        }
        if (data.code === 302) {
            this.$vue.$message.error(data.msg);
            this.$vue.$router.replace('/login');
            return false;
        }
        if(data.code === 200 && !data.page && !data.list && !data.data) {
            this.$vue.$message.info('操作成功');
        }
        return true;
    }

    // ==============ProductCommentController===============
    /**
     *
     */
    async productCommentPage(form) {
        let response = await apiHelper.get('/admin/productComment/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productCommentListAll({_}) {
        let response = await apiHelper.get('/admin/productComment/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productCommentUnqiue({id}) {
        let response = await apiHelper.get('/admin/productComment/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productCommentUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/productComment/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productCommentAddOrSave({productId, userId, nickName, userName, score, content, id}) {
        let response = await apiHelper.post('/admin/productComment/addOrSave', {productId, userId, nickName, userName, score, content, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productCommentDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/productComment/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productCommentRemove({id}) {
        let response = await apiHelper.get('/admin/productComment/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productCommentApprove(id, action) {
        let response = await apiHelper.get('/admin/productComment/approve', {id, action});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productCommentDelete({id}) {
        let response = await apiHelper.get('/admin/productComment/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============HotItemsController===============

    /**
     *
     */
    async hotItemsPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/hotItems/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotItemsListAll({_}) {
        let response = await apiHelper.get('/admin/hotItems/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotItemsUnqiue({id}) {
        let response = await apiHelper.get('/admin/hotItems/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotItemsUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/hotItems/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotItemsAddOrSave({type, itemId, id}) {
        let response = await apiHelper.post('/admin/hotItems/addOrSave', {type, itemId, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
    async hotItemsBatchAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/hotItems/batchAddOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async hotItemsBatchUpdate(type, ids) {
        let response = await apiHelper.postJson('/admin/hotItems/hotItemsBatchUpdate', {type, ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotItemsDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/hotItems/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotItemsRemove({id}) {
        let response = await apiHelper.get('/admin/hotItems/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotItemsRemoveBy({itemId, type}) {
        let response = await apiHelper.get('/admin/hotItems/removeBy', {itemId, type});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotItemsDelete({id}) {
        let response = await apiHelper.get('/admin/hotItems/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotItemListMap() {
        let response = await apiHelper.get('/admin/hotItems/hotItemListMap', {});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============CouponsController===============

    /**
     *
     */
    async couponsPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/coupons/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async couponsListAll({_}) {
        let response = await apiHelper.get('/admin/coupons/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async couponsUnqiue({id}) {
        let response = await apiHelper.get('/admin/coupons/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async couponsUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/coupons/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async couponsAddOrSave({storeId, money, minPrice, discountType, discountMoney, discountRate, startTime, endTime, id}) {
        let response = await apiHelper.post('/admin/coupons/addOrSave', {storeId, money, minPrice, discountType, discountMoney, discountRate, startTime, endTime, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async couponsDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/coupons/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async couponsRemove({id}) {
        let response = await apiHelper.get('/admin/coupons/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async couponsDelete({id}) {
        let response = await apiHelper.get('/admin/coupons/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============OrderDetailController===============

    /**
     *
     */
    async orderDetailPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/orderDetail/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderDetailListAll({_}) {
        let response = await apiHelper.get('/admin/orderDetail/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderDetailUnqiue({id}) {
        let response = await apiHelper.get('/admin/orderDetail/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderDetailUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/orderDetail/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderDetailAddOrSave({userId, orderId, itemId, itemName, specification, itemPrice, price, buyCount, id}) {
        let response = await apiHelper.post('/admin/orderDetail/addOrSave', {userId, orderId, itemId, itemName, specification, itemPrice, price, buyCount, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderDetailDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/orderDetail/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderDetailRemove({id}) {
        let response = await apiHelper.get('/admin/orderDetail/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderDetailDelete({id}) {
        let response = await apiHelper.get('/admin/orderDetail/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============ShopCartController===============

    /**
     *
     */
    async shopCartPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/shopCart/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async shopCartListAll({_}) {
        let response = await apiHelper.get('/admin/shopCart/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async shopCartUnqiue({id}) {
        let response = await apiHelper.get('/admin/shopCart/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async shopCartUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/shopCart/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async shopCartAddOrSave({goodsId, goodsSpecId, count, id}) {
        let response = await apiHelper.post('/admin/shopCart/addOrSave', {goodsId, goodsSpecId, count, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async shopCartDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/shopCart/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async shopCartRemove({id}) {
        let response = await apiHelper.get('/admin/shopCart/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async shopCartDelete({id}) {
        let response = await apiHelper.get('/admin/shopCart/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============GoodsController===============

    /**
     *
     */
    async goodsPage({pageNow, pageSize, keyword, gene, curTab}) {
        let response = await apiHelper.get('/admin/goods/page', {pageNow, pageSize, keyword, gene, curTab});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsListAll() {
        let response = await apiHelper.get('/admin/goods/listAll');
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsGoodsTypes(isGene) {
        let response = await apiHelper.get('/admin/goods/goodsTypes',{gene: isGene});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsUnqiue({id}) {
        let response = await apiHelper.get('/admin/goods/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/goods/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsPopup({id, action}) {
        let response = await apiHelper.get('/admin/goods/goodsPopup', {id, action});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/goods/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/goods/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsRemove({id}) {
        let response = await apiHelper.get('/admin/goods/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsDelete({id}) {
        let response = await apiHelper.get('/admin/goods/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============BannerController===============

    /**
     *
     */
    async bannerPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/banner/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async bannerListAll({_}) {
        let response = await apiHelper.get('/admin/banner/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async bannerUnqiue({id}) {
        let response = await apiHelper.get('/admin/banner/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async bannerUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/banner/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async bannerAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/banner/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async bannerBatchAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/banner/batchAddOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async bannerDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/banner/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async bannerRemove({id}) {
        let response = await apiHelper.get('/admin/banner/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async bannerDelete({id}) {
        let response = await apiHelper.get('/admin/banner/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async bannerListMap() {
        let response = await apiHelper.get('/admin/banner/bannerListMap', {});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============EvaluationEnrollController===============

    /**
     *
     */
    async evaluationEnrollPage({pageNow, pageSize, fieldType, keyword, evaluationId}) {
        let response = await apiHelper.get('/admin/evaluationEnroll/page', {pageNow, pageSize, fieldType, keyword, evaluationId});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationEnrollListAll({_}) {
        let response = await apiHelper.get('/admin/evaluationEnroll/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationEnrollUnqiue({id}) {
        let response = await apiHelper.get('/admin/evaluationEnroll/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationEnrollUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/evaluationEnroll/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationEnrollAddOrSave({productId, evaluationId, nickName, userName, mobile, address, headImageUrl, score, usefulCount, commentContent, commentImages, commentTime, status, id}) {
        let response = await apiHelper.post('/admin/evaluationEnroll/addOrSave', {productId, evaluationId, nickName, userName, mobile, address, headImageUrl, score, usefulCount, commentContent, commentImages, commentTime, status, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationEnrollDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/evaluationEnroll/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationEnrollRemove({id}) {
        let response = await apiHelper.get('/admin/evaluationEnroll/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationEnrollElect({id, action}) {
        let response = await apiHelper.get('/admin/evaluationEnroll/electAsEvaluator', {id, action});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationEnrollSelect(id, hasSelect) {
        let response = await apiHelper.get('/admin/evaluationEnroll/choose', {id, hasSelect});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationEnrollDelete({id}) {
        let response = await apiHelper.get('/admin/evaluationEnroll/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============StoreController===============

    /**
     *
     */
    async storePage(form) {
        let response = await apiHelper.get('/admin/store/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeListAll({_}) {
        let response = await apiHelper.get('/admin/store/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeUnqiue({id}) {
        let response = await apiHelper.get('/admin/store/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/store/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/store/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/store/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeRemove({id}) {
        let response = await apiHelper.get('/admin/store/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeDelete({id}) {
        let response = await apiHelper.get('/admin/store/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============UserTokenController===============

    /**
     *
     */
    async userTokenPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/userToken/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userTokenListAll({_}) {
        let response = await apiHelper.get('/admin/userToken/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userTokenUnqiue({id}) {
        let response = await apiHelper.get('/admin/userToken/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userTokenUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/userToken/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userTokenAddOrSave({userId, expireTime, id}) {
        let response = await apiHelper.post('/admin/userToken/addOrSave', {userId, expireTime, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userTokenDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/userToken/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userTokenRemove({id}) {
        let response = await apiHelper.get('/admin/userToken/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userTokenDelete({id}) {
        let response = await apiHelper.get('/admin/userToken/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============BrandController===============

    /**
     *
     */
    async brandPage({pageNow, pageSize, productType, keyword}) {
        let response = await apiHelper.get('/admin/brand/page', {pageNow, pageSize, productType, keyword});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
    /**
     *
     */
    async brandListConditions(productType) {
        let response = await apiHelper.get('/admin/productCondition/conditions', {productType});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async brandListAll(productType) {
        let response = await apiHelper.get('/admin/brand/listAll', {productType});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async brandUnqiue({id}) {
        let response = await apiHelper.get('/admin/brand/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async brandUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/brand/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async brandAddOrSave({name, logoUrl, images, briefIntro, productType, introduction, hasTopped, id}) {
        let response = await apiHelper.post('/admin/brand/addOrSave', {name, logoUrl, images, briefIntro, productType, introduction, hasTopped, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async brandDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/brand/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async brandRemove({id}) {
        let response = await apiHelper.get('/admin/brand/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async brandDelete({id}) {
        let response = await apiHelper.get('/admin/brand/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async brandTopped({id, action}) {
        let response = await apiHelper.get('/admin/brand/topped', {id, action});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

// ==============UserAddressController===============

    /**
     *
     */
    async userAddressPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/userAddress/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userAddressListAll({_}) {
        let response = await apiHelper.get('/admin/userAddress/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userAddressUnqiue({id}) {
        let response = await apiHelper.get('/admin/userAddress/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userAddressUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/userAddress/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userAddressAddOrSave({userName, mobile, area, address, tag, asDefault, id}) {
        let response = await apiHelper.post('/admin/userAddress/addOrSave', {userName, mobile, area, address, tag, asDefault, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userAddressDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/userAddress/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userAddressRemove({id}) {
        let response = await apiHelper.get('/admin/userAddress/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userAddressDelete({id}) {
        let response = await apiHelper.get('/admin/userAddress/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============GoodsSpecController===============

    /**
     *
     */
    async goodsSpecPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/goodsSpec/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsSpecListAll({_}) {
        let response = await apiHelper.get('/admin/goodsSpec/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsSpecUnqiue({id}) {
        let response = await apiHelper.get('/admin/goodsSpec/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsSpecUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/goodsSpec/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsSpecAddOrSave({specificationData, price, stockCount, id}) {
        let response = await apiHelper.post('/admin/goodsSpec/addOrSave', {specificationData, price, stockCount, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsSpecDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/goodsSpec/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsSpecRemove({id}) {
        let response = await apiHelper.get('/admin/goodsSpec/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async goodsSpecDelete({id}) {
        let response = await apiHelper.get('/admin/goodsSpec/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============EvaluationController===============

    /**
     *
     */
    async evaluationPage({pageNow, pageSize, keyword}) {
        let response = await apiHelper.get('/admin/evaluation/page', {pageNow, pageSize, keyword});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationListAll({_}) {
        let response = await apiHelper.get('/admin/evaluation/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationUnqiue({id}) {
        let response = await apiHelper.get('/admin/evaluation/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/evaluation/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/evaluation/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/evaluation/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationRemove({id}) {
        let response = await apiHelper.get('/admin/evaluation/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async evaluationDelete({id}) {
        let response = await apiHelper.get('/admin/evaluation/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============UserCollectionController===============

    /**
     *
     */
    async userCollectionPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/userCollection/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCollectionListAll({_}) {
        let response = await apiHelper.get('/admin/userCollection/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCollectionUnqiue({id}) {
        let response = await apiHelper.get('/admin/userCollection/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCollectionUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/userCollection/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCollectionAddOrSave({userId, type, itemId, id}) {
        let response = await apiHelper.post('/admin/userCollection/addOrSave', {userId, type, itemId, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCollectionDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/userCollection/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCollectionRemove({id}) {
        let response = await apiHelper.get('/admin/userCollection/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCollectionDelete({id}) {
        let response = await apiHelper.get('/admin/userCollection/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============ProductConditionController===============

    /**
     *
     */
    async productConditionPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/productCondition/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productConditionListAll({_}) {
        let response = await apiHelper.get('/admin/productCondition/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productConditionUnqiue({id}) {
        let response = await apiHelper.get('/admin/productCondition/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productConditionUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/productCondition/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productConditionAddOrSave({productType, conditionType, name, code, id}) {
        let response = await apiHelper.post('/admin/productCondition/addOrSave', {productType, conditionType, name, code, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productConditionDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/productCondition/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productConditionRemove({id}) {
        let response = await apiHelper.get('/admin/productCondition/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productConditionDelete({id}) {
        let response = await apiHelper.get('/admin/productCondition/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============CommonTypeController===============

    /**
     *
     */
    async commonTypePage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/commonType/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async commonTypeListAll({_}) {
        let response = await apiHelper.get('/admin/commonType/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
    async commonTypeByType(typeId) {
        let response = await apiHelper.get('/admin/commonType/listByType', {type: typeId});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async commonTypeUnqiue({id}) {
        let response = await apiHelper.get('/admin/commonType/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async commonTypeUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/commonType/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async commonTypeAddOrSave({type, typeCode, typeName, id}) {
        let response = await apiHelper.post('/admin/commonType/addOrSave', {type, typeCode, typeName, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async commonTypeDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/commonType/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async commonTypeRemove({id}) {
        let response = await apiHelper.get('/admin/commonType/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async commonTypeDelete({id}) {
        let response = await apiHelper.get('/admin/commonType/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============VaccineController===============

    /**
     *
     */
    async vaccinePage({pageNow, pageSize, keyword}) {
        let response = await apiHelper.get('/admin/vaccine/page', {pageNow, pageSize, keyword});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineListAll({_}) {
        let response = await apiHelper.get('/admin/vaccine/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineUnqiue({id}) {
        let response = await apiHelper.get('/admin/vaccine/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/vaccine/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineAddOrSave({approveDeliverIndustry,batchNo,certNo,checkNum,companyName,deliverCount,deliverDate,deliverResult,expireDate,id,reportNo,status,vaccineName,vaccineSpec}) {
        let response = await apiHelper.post('/admin/vaccine/addOrSave', {approveDeliverIndustry,batchNo,certNo,checkNum,companyName,deliverCount,deliverDate,deliverResult,expireDate,id,reportNo,status,vaccineName,vaccineSpec});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/vaccine/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineRemove({id}) {
        let response = await apiHelper.get('/admin/vaccine/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineDelete({id}) {
        let response = await apiHelper.get('/admin/vaccine/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     * 疫苗 - 导入
     */
    async vaccineImportData({file}) {
        let form = new FormData();
        form.append('file', file);
        let response = await apiHelper.upload('/admin/vaccine/importData', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============HotSearchController===============

    /**
     *
     */
    async hotSearchPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/hotSearch/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotSearchListAll({_}) {
        let response = await apiHelper.get('/admin/hotSearch/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotSearchUnqiue({id}) {
        let response = await apiHelper.get('/admin/hotSearch/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotSearchUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/hotSearch/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotSearchAddOrSave({userId, keyword, searchTimes, type, id}) {
        let response = await apiHelper.post('/admin/hotSearch/addOrSave', {userId, keyword, searchTimes, type, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
    async hotSearchBatchAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/hotSearch/batchAddOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotSearchDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/hotSearch/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotSearchRemove({id}) {
        let response = await apiHelper.get('/admin/hotSearch/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async hotSearchDelete({id}) {
        let response = await apiHelper.get('/admin/hotSearch/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============StoreTagController===============

    /**
     *
     */
    async storeTagPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/storeTag/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeTagListAll({_}) {
        let response = await apiHelper.get('/admin/storeTag/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeTagUnqiue({id}) {
        let response = await apiHelper.get('/admin/storeTag/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeTagUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/storeTag/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeTagAddOrSave({name, iconPicUrl, id}) {
        let response = await apiHelper.post('/admin/storeTag/addOrSave', {name, iconPicUrl, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeTagDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/storeTag/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeTagRemove({id}) {
        let response = await apiHelper.get('/admin/storeTag/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeTagDelete({id}) {
        let response = await apiHelper.get('/admin/storeTag/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============ProductController===============

    /**
     *
     */
    async productPage({pageNow, pageSize, productType, startDate, endDate, keyword}) {
        let response = await apiHelper.get('/admin/product/page', {pageNow, pageSize, productType, startDate, endDate, keyword});
        console.log('productPage: ', response);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productListAll(form={}) {
        let response = await apiHelper.get('/admin/product/listAll', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productUnqiue({id}) {
        let response = await apiHelper.get('/admin/product/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/product/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productAddOrSave(form) {
        let response = await apiHelper.post('/admin/product/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/product/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productRemove({id}) {
        let response = await apiHelper.get('/admin/product/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async productDelete({id}) {
        let response = await apiHelper.get('/admin/product/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
    async productTopped({id, action}) {
        let response = await apiHelper.get('/admin/product/topped', {id, action});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
    async storePopup({id, action}) {
        let response = await apiHelper.get('/admin/store/popup', {id, action});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async storeImportData({file,type}) {
        let form = new FormData();
        form.append('file', file);
        form.append('type', type);
        let response = await apiHelper.upload('/admin/store/importData', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============EnrollApplyController===============

    /**
     *
     */
    async enrollApplyPage({pageNow, pageSize, type, keyword}) {
        let response = await apiHelper.get('/admin/enrollApply/page', {pageNow, pageSize, type, keyword});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async enrollApplyListAll({_}) {
        let response = await apiHelper.get('/admin/enrollApply/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async enrollApplyUnqiue({id}) {
        let response = await apiHelper.get('/admin/enrollApply/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async enrollApplyApprove(form) {
        let response = await apiHelper.post('/admin/enrollApply/approve', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async enrollApplyUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/enrollApply/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async enrollApplyAddOrSave({type, storeName, address, storeCount, city, userName, mobile, memo, id}) {
        let response = await apiHelper.post('/admin/enrollApply/addOrSave', {type, storeName, address, storeCount, city, userName, mobile, memo, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async enrollApplyDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/enrollApply/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async enrollApplyRemove({id}) {
        let response = await apiHelper.get('/admin/enrollApply/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async enrollContactUser({id, memo}) {
        let response = await apiHelper.post('/admin/enrollApply/contactUser', {id, memo});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async enrollApplyDelete({id}) {
        let response = await apiHelper.get('/admin/enrollApply/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============LocationController===============

    /**
     *
     */
    async locationPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/location/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async locationListAll({_}) {
        let response = await apiHelper.get('/admin/location/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async locationUnqiue({id}) {
        let response = await apiHelper.get('/admin/location/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async locationUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/location/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async locationAddOrSave({level, name, parentId, id}) {
        let response = await apiHelper.post('/admin/location/addOrSave', {level, name, parentId, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async locationDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/location/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async locationRemove({id}) {
        let response = await apiHelper.get('/admin/location/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async locationDelete({id}) {
        let response = await apiHelper.get('/admin/location/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============AppUserController===============

    /**
     *
     */
    async appUserPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/appUser/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appUserListAll({_}) {
        let response = await apiHelper.get('/admin/appUser/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appUserUnqiue({id}) {
        let response = await apiHelper.get('/admin/appUser/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appUserUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/appUser/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appUserAddOrSave({userName, nickname, headImageUrl, age, mobile, sex, appOpenId, province, city, county, id}) {
        let response = await apiHelper.post('/admin/appUser/addOrSave', {userName, nickname, headImageUrl, age, mobile, sex, appOpenId, province, city, county, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appUserDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/appUser/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appUserRemove({id}) {
        let response = await apiHelper.get('/admin/appUser/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appUserDelete({id}) {
        let response = await apiHelper.get('/admin/appUser/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============OrderController===============

    /**
     *
     */
    async orderPage(form) {
        let response = await apiHelper.get('/admin/order/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderListAll({_}) {
        let response = await apiHelper.get('/admin/order/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderUnqiue({id}) {
        let response = await apiHelper.get('/admin/order/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async sendOrder({id, expressCompany, expressNo}) {
        let response = await apiHelper.post('/admin/order/sendOrder', {id, expressCompany, expressNo});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async cancelOrder({id, cancelReason, cancelAddition}) {
        let response = await apiHelper.post('/admin/order/cancelOrder', {id, cancelReason, cancelAddition});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async memoOrder({id, memo}) {
        let response = await apiHelper.post('/admin/order/memoOrder', {id, memo});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/order/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderAddOrSave({orderNo, orderPayNo, userId, orderName, specification, type, totalPrice, expressPrice, discountPrice, originalPrice, buyerNickname, buyerUserName, buyerMobile, buyerAddress, buyerMemo, expressCompany, expressNo, cancelReason, cancelAddition, memo, payType, payStatus, payTime, payMoney, payTradeNo, payExpireTime, ensureExpireTime, expressSendTime, userComment, status, id}) {
        let response = await apiHelper.post('/admin/order/addOrSave', {orderNo, orderPayNo, userId, orderName, specification, type, totalPrice, expressPrice, discountPrice, originalPrice, buyerNickname, buyerUserName, buyerMobile, buyerAddress, buyerMemo, expressCompany, expressNo, cancelReason, cancelAddition, memo, payType, payStatus, payTime, payMoney, payTradeNo, payExpireTime, ensureExpireTime, expressSendTime, userComment, status, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/order/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderRemove({id}) {
        let response = await apiHelper.get('/admin/order/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async orderDelete({id}) {
        let response = await apiHelper.get('/admin/order/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============StoreScoreController===============

    /**
     *
     */
    async storeScorePage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/storeScore/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeScoreListAll({_}) {
        let response = await apiHelper.get('/admin/storeScore/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeScoreUnqiue({id}) {
        let response = await apiHelper.get('/admin/storeScore/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeScoreUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/storeScore/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeScoreAddOrSave({storeId, serviceScore, priceScore, totalScore, id}) {
        let response = await apiHelper.post('/admin/storeScore/addOrSave', {storeId, serviceScore, priceScore, totalScore, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeScoreDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/storeScore/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeScoreRemove({id}) {
        let response = await apiHelper.get('/admin/storeScore/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeScoreDelete({id}) {
        let response = await apiHelper.get('/admin/storeScore/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============ArticleController===============

    /**
     *
     */
    async articlePage({pageNow, pageSize, keyword, category}) {
        let response = await apiHelper.get('/admin/article/page', {pageNow, pageSize, keyword, category});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleListAll({_}) {
        let response = await apiHelper.get('/admin/article/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleUnqiue({id}) {
        let response = await apiHelper.get('/admin/article/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/article/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleAddOrSave(form) {
        let response = await apiHelper.post('/admin/article/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/article/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleRemove({id}) {
        let response = await apiHelper.get('/admin/article/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleDelete({id}) {
        let response = await apiHelper.get('/admin/article/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============UserController===============

    /**
     *
     */
    async userPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/user/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userListAll({_}) {
        let response = await apiHelper.get('/admin/user/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userUnqiue({id}) {
        let response = await apiHelper.get('/admin/user/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/user/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userAddOrSave({loginName, loginPass, headImageUrl, salt, lastLoginTime, lastLoginIp, id}) {
        let response = await apiHelper.post('/admin/user/addOrSave', {loginName, loginPass, headImageUrl, salt, lastLoginTime, lastLoginIp, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/user/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userRemove({id}) {
        let response = await apiHelper.get('/admin/user/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userDelete({id}) {
        let response = await apiHelper.get('/admin/user/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============UserCouponController===============

    /**
     *
     */
    async userCouponPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/userCoupon/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCouponListAll({_}) {
        let response = await apiHelper.get('/admin/userCoupon/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCouponUnqiue({id}) {
        let response = await apiHelper.get('/admin/userCoupon/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCouponUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/userCoupon/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCouponAddOrSave({couponId, userId, money, minPrice, discountType, discountMoney, discountRate, startTime, endTime, status, id}) {
        let response = await apiHelper.post('/admin/userCoupon/addOrSave', {couponId, userId, money, minPrice, discountType, discountMoney, discountRate, startTime, endTime, status, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCouponDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/userCoupon/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCouponRemove({id}) {
        let response = await apiHelper.get('/admin/userCoupon/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userCouponDelete({id}) {
        let response = await apiHelper.get('/admin/userCoupon/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============StoreApplyController===============

    /**
     *
     */
    async storeApplyPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/storeApply/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeApplyListAll({_}) {
        let response = await apiHelper.get('/admin/storeApply/listAll', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeApplyUnqiue({id}) {
        let response = await apiHelper.get('/admin/storeApply/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeApplyUnqiueBy({_}) {
        let response = await apiHelper.get('/admin/storeApply/unqiueBy', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeApplyAddOrSave({userId, storeName, storeCount, city, userName, mobile, id}) {
        let response = await apiHelper.post('/admin/storeApply/addOrSave', {userId, storeName, storeCount, city, userName, mobile, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeApplyDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/storeApply/批量删除', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeApplyRemove({id}) {
        let response = await apiHelper.get('/admin/storeApply/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async storeApplyDelete({id}) {
        let response = await apiHelper.get('/admin/storeApply/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
    // ==============ArticleTypeController===============

    /**
     *
     */
    async articleTypeRemove({id}) {
        let response = await apiHelper.get('/admin/articleType/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleTypeDelete({id}) {
        let response = await apiHelper.get('/admin/articleType/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleTypePage({pageNow, pageSize, pid}) {
        let response = await apiHelper.get('/admin/articleType/page', {pageNow, pageSize, pid});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleTypeListAll({pid}) {
        let response = await apiHelper.get('/admin/articleType/listAll', {pid});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async articleTypeListAllAutoGroup() {
        let res = await this.articleTypeListAll({});
        if (res) {
            // 重组数据
            let firstLevelList = {};
            let list = res.list;
            list.filter(t => t.pid < 1).forEach(t => firstLevelList[t.id] = t);
            list.filter(t => t.pid > 0).forEach(t => {
                let group = firstLevelList[t.pid];
                if(!group.childs) group.childs = [];
                let groupList = group.childs;
                groupList.push(t);
            });
            let flist = [];
            for(let key in firstLevelList) {
                flist.push(firstLevelList[key]);
            }
            res.list = flist;
            return res;
        }
        return null;
    }

    /**
     *
     */
    async articleTypeUnqiue({id}) {
        let response = await apiHelper.get('/admin/articleType/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleTypeUnqiueBy({id}) {
        let response = await apiHelper.get('/admin/articleType/unqiueBy', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleTypeAddOrSave({name, pid, id}) {
        let response = await apiHelper.post('/admin/articleType/addOrSave', {name, pid, id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async articleTypeDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/articleType/batchRemoveByIds', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============PublicController===============

    /**
     * 公共 - 上传文件
     */
    async publicUpload({file, id}) {
        let form = new FormData();
        form.append('file', file);
        form.append('id', id);
        let response = await apiHelper.upload('/admin/upload', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     * 公共 - 所有枚举类别
     */
    async publicAllEnums({_}) {
        let response = await apiHelper.get('/allEnums', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     * 公共 - 查询用户信息
     */
    async getUserInfo() {
        let response = await apiHelper.get('/admin/getUserInfo', {});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     * 公共 - 刷新token
     */
    async publicRefreshToken({_}) {
        let response = await apiHelper.post('/refreshToken', {_});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async publicLogin({loginName, loginPass}) {
        let response = await apiHelper.post('/login', {loginName, loginPass});
        if (this.handleResult(response)) {
            console.log(response)
            await apiHelper.updateAuthToken(response.data.data.token);
            return response.data;
        }
        return {};
    }
    async regionListByParent(pid=100000) {
        let response = await apiHelper.get('/admin/region/listByParent', {pid});
        if (this.handleResult(response)) {
            return response.data;
        }
        return {};
    }

    async adminProductExport(form={}) {
        form.token = apiHelper.getAuthToken();
        window.open('/api/admin/product/export?'+Qs.stringify(form));
    }

    async adminStoreExport(form={}) {
        form.token = apiHelper.getAuthToken();
        window.open('/api/admin/store/export?'+Qs.stringify(form));
    }

    async adminDashBoard() {
        let response = await apiHelper.get('/admin/dashboard');
        if (this.handleResult(response)) {
            return response.data;
        }
        return {};
    }

    async adminTentcentProxy(url, params={}) {
        let paramsArr = [];
        for (let key in params) {
            paramsArr.push(key+'='+params[key]);
        }
        let fullUrl = url+'?'+paramsArr.join('&');
        let response = await apiHelper.get('/tencent/map/proxy', {fullUrl});
        if (this.handleResult(response)) {
            return response.data;
        }
        return {};
    }
    // ==============AppSplashController===============

    /**
     *
     */
    async appSplashRemove({id}) {
        let response = await apiHelper.get('/admin/appSplash/remove', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
    /**
     *
     */
    async appSplashChangeStatus(form) {
        let response = await apiHelper.get('/admin/appSplash/changeStatus', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appSplashDelete({id}) {
        let response = await apiHelper.get('/admin/appSplash/delete', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appSplashPage({pageNow, pageSize}) {
        let response = await apiHelper.get('/admin/appSplash/page', {pageNow, pageSize});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appSplashListAll(form) {
        let response = await apiHelper.get('/admin/appSplash/listAll', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appSplashUnqiue({id}) {
        let response = await apiHelper.get('/admin/appSplash/unqiue', {id});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appSplashUnqiueBy(form) {
        let response = await apiHelper.get('/admin/appSplash/unqiueBy', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appSplashAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/appSplash/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async appSplashDeleteByBatch({ids}) {
        let response = await apiHelper.get('/admin/appSplash/batchRemoveByIds', {ids});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async sysConfigGet() {
        let response = await apiHelper.get('/admin/sysConfig/unqiue', {id: 1});
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async sysConfigSave(form={}) {
        form.id = 1;
        let response = await apiHelper.postJson('/admin/sysConfig/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    // ==============PregnantBxIndexController===============

    /**
     *
     */
    async pregnantBxIndexAddOrSave(form) {
        let response = await apiHelper.post('/admin/pregnantBxIndex/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantBxIndexDeleteByBatch(form) {
        let response = await apiHelper.get('/admin/pregnantBxIndex/batchRemoveByIds', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantBxIndexPage(form) {
        let response = await apiHelper.get('/admin/pregnantBxIndex/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantBxIndexListAll(form) {
        let response = await apiHelper.get('/admin/pregnantBxIndex/listAll', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantBxIndexUnqiue(form) {
        let response = await apiHelper.get('/admin/pregnantBxIndex/unqiue', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantBxIndexUnqiueBy(form) {
        let response = await apiHelper.get('/admin/pregnantBxIndex/unqiueBy', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantBxIndexRemove(form) {
        let response = await apiHelper.get('/admin/pregnantBxIndex/remove', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantBxIndexDelete(form) {
        let response = await apiHelper.get('/admin/pregnantBxIndex/delete', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============PregnantCheckController===============

    /**
     *
     */
    async pregnantCheckAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/pregnantCheck/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantCheckDeleteByBatch(form) {
        let response = await apiHelper.get('/admin/pregnantCheck/batchRemoveByIds', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantCheckPage(form) {
        let response = await apiHelper.get('/admin/pregnantCheck/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantCheckListAll(form) {
        let response = await apiHelper.get('/admin/pregnantCheck/listAll', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantCheckUnqiue(form) {
        let response = await apiHelper.get('/admin/pregnantCheck/unqiue', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantCheckUnqiueBy(form) {
        let response = await apiHelper.get('/admin/pregnantCheck/unqiueBy', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantCheckRemove(form) {
        let response = await apiHelper.get('/admin/pregnantCheck/remove', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async pregnantCheckDelete(form) {
        let response = await apiHelper.get('/admin/pregnantCheck/delete', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============QyConsultController===============

    /**
     *
     */
    async qyConsultAddOrSave(form) {
        let response = await apiHelper.post('/admin/qyConsult/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async qyConsultDeleteByBatch(form) {
        let response = await apiHelper.get('/admin/qyConsult/batchRemoveByIds', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async qyConsultPage(form) {
        let response = await apiHelper.get('/admin/qyConsult/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async qyConsultListAll(form) {
        let response = await apiHelper.get('/admin/qyConsult/listAll', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async qyConsultUnqiue(form) {
        let response = await apiHelper.get('/admin/qyConsult/unqiue', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async qyConsultUnqiueBy(form) {
        let response = await apiHelper.get('/admin/qyConsult/unqiueBy', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async qyConsultRemove(form) {
        let response = await apiHelper.get('/admin/qyConsult/remove', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async qyConsultDelete(form) {
        let response = await apiHelper.get('/admin/qyConsult/delete', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============RecruitCompanyController===============

    /**
     *
     */
    async recruitCompanyAddOrSave(form) {
        let response = await apiHelper.post('/admin/recruitCompany/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async recruitCompanyOnlineOrOffline(form) {
        let response = await apiHelper.post('/admin/recruitCompany/onlineOrOffline', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async recruitCompanyImportData({file}) {
        let form = new FormData();
        form.append('file', file);
        let response = await apiHelper.upload('/admin/recruitCompany/importData', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitCompanyDeleteByBatch(form) {
        let response = await apiHelper.get('/admin/recruitCompany/batchRemoveByIds', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitCompanyPage(form) {
        let response = await apiHelper.get('/admin/recruitCompany/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitCompanyListAll(form) {
        let response = await apiHelper.get('/admin/recruitCompany/listAll', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitCompanyUnqiue(form) {
        let response = await apiHelper.get('/admin/recruitCompany/unqiue', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitCompanyUnqiueBy(form) {
        let response = await apiHelper.get('/admin/recruitCompany/unqiueBy', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitCompanyRemove(form) {
        let response = await apiHelper.get('/admin/recruitCompany/remove', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitCompanyDelete(form) {
        let response = await apiHelper.get('/admin/recruitCompany/delete', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============RecruitConsultController===============

    /**
     *
     */
    async recruitConsultAddOrSave(form) {
        let response = await apiHelper.post('/admin/recruitConsult/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitConsultDeleteByBatch(form) {
        let response = await apiHelper.get('/admin/recruitConsult/batchRemoveByIds', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitConsultPage(form) {
        let response = await apiHelper.get('/admin/recruitConsult/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitConsultListAll(form) {
        let response = await apiHelper.get('/admin/recruitConsult/listAll', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitConsultUnqiue(form) {
        let response = await apiHelper.get('/admin/recruitConsult/unqiue', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitConsultUnqiueBy(form) {
        let response = await apiHelper.get('/admin/recruitConsult/unqiueBy', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitConsultRemove(form) {
        let response = await apiHelper.get('/admin/recruitConsult/remove', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitConsultDelete(form) {
        let response = await apiHelper.get('/admin/recruitConsult/delete', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitConsultApprove(form) {
        let response = await apiHelper.post('/admin/recruitConsult/approve', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============RecruitJobsController===============

    /**
     *
     */
    async recruitJobsAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/recruitJobs/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async recruitJobsOnlineOrOffline(form) {
        let response = await apiHelper.post('/admin/recruitJobs/onlineOrOffline', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    async recruitJobsImportData({file}) {
        let form = new FormData();
        form.append('file', file);
        let response = await apiHelper.upload('/admin/recruitJobs/importData', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitJobsDeleteByBatch(form) {
        let response = await apiHelper.get('/admin/recruitJobs/batchRemoveByIds', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitJobsPage(form) {
        let response = await apiHelper.get('/admin/recruitJobs/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitJobsListAll(form) {
        let response = await apiHelper.get('/admin/recruitJobs/listAll', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitJobsUnqiue(form) {
        let response = await apiHelper.get('/admin/recruitJobs/unqiue', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitJobsUnqiueBy(form) {
        let response = await apiHelper.get('/admin/recruitJobs/unqiueBy', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitJobsRemove(form) {
        let response = await apiHelper.get('/admin/recruitJobs/remove', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async recruitJobsDelete(form) {
        let response = await apiHelper.get('/admin/recruitJobs/delete', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============UserFetalRecordController===============

    /**
     *
     */
    async userFetalRecordAddOrSave(form) {
        let response = await apiHelper.post('/admin/userFetalRecord/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userFetalRecordDeleteByBatch(form) {
        let response = await apiHelper.get('/admin/userFetalRecord/batchRemoveByIds', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userFetalRecordPage(form) {
        let response = await apiHelper.get('/admin/userFetalRecord/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userFetalRecordListAll(form) {
        let response = await apiHelper.get('/admin/userFetalRecord/listAll', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userFetalRecordUnqiue(form) {
        let response = await apiHelper.get('/admin/userFetalRecord/unqiue', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userFetalRecordUnqiueBy(form) {
        let response = await apiHelper.get('/admin/userFetalRecord/unqiueBy', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userFetalRecordRemove(form) {
        let response = await apiHelper.get('/admin/userFetalRecord/remove', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async userFetalRecordDelete(form) {
        let response = await apiHelper.get('/admin/userFetalRecord/delete', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
// ==============VaccineMetaController===============

    /**
     *
     */
    async vaccineMetaAddOrSave(form) {
        let response = await apiHelper.postJson('/admin/vaccineMeta/addOrSave', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineMetaDeleteByBatch(form) {
        let response = await apiHelper.get('/admin/vaccineMeta/batchRemoveByIds', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineMetaPage(form) {
        let response = await apiHelper.get('/admin/vaccineMeta/page', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineMetaListAll(form) {
        let response = await apiHelper.get('/admin/vaccineMeta/listAll', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineMetaUnqiue(form) {
        let response = await apiHelper.get('/admin/vaccineMeta/unqiue', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineMetaUnqiueBy(form) {
        let response = await apiHelper.get('/admin/vaccineMeta/unqiueBy', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineMetaRemove(form) {
        let response = await apiHelper.get('/admin/vaccineMeta/remove', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }

    /**
     *
     */
    async vaccineMetaDelete(form) {
        let response = await apiHelper.get('/admin/vaccineMeta/delete', form);
        if (this.handleResult(response)) {
            return response.data;
        }
        return null;
    }
}

const apiService = new ApiService();

// 外放绑定vue
export function bindVue($instance) {
    apiService.bindVue($instance);
}

const MyPlugin = {};
MyPlugin.install = function (Vue) {
    Vue.prototype.$api = apiService;
}

Vue.use(MyPlugin,{});

export default apiService;



