<template>
  <div class="page-container">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :wrapper-col="state.wrapperCol"
                  :rules="rules"
                  labelAlign="left">
      <a-row>
        <a-col :span="state.formColSpan">
            <a-form-model-item label="企业类型">
                <a-space>
                    <a-select v-model="form.companyType" style="width: 100px"
                    >
                        <a-select-option :value="0">不限</a-select-option>
                        <a-select-option :value="1">奶粉</a-select-option>
                        <a-select-option :value="2">辅食</a-select-option>
                        <a-select-option :value="3">营养品</a-select-option>
                        <a-select-option :value="4">纸尿裤</a-select-option>
                        <a-select-option :value="5">商贸</a-select-option>
                        <a-select-option :value="6">连锁门店</a-select-option>
                    </a-select>
                </a-space>
            </a-form-model-item>
          <a-form-model-item label="企业名称">
            <a-input v-model="form.companyName" />
          </a-form-model-item>
          <a-form-model-item label="企业logo">
            <my-upload v-model="form.logoUrl" :multiple="false" />
          </a-form-model-item>
          <a-form-model-item label="企业联系人">
            <a-input v-model="form.username" />
          </a-form-model-item>
          <a-form-model-item label="企业联系电话">
            <a-input v-model="form.linkMobile" />
          </a-form-model-item>
          <a-form-model-item label="企业联系邮箱">
            <a-input v-model="form.linkEmail" />
          </a-form-model-item>
          <a-form-model-item label="企业规模">
            <a-input v-model="form.scale" />
          </a-form-model-item>
          <a-form-model-item label="企业介绍">
            <wang-editor v-model="form.description" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
export default {
  name: "RecruitCompanyForm",
  components: {},
  data() {
    return {
      form: {
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        username: '',
        mobile: '',
        companyName: '',
        logoUrl: '',
        companyType: '',
        description: '',
        status: '',
        scale: '',
        linkMobile: '',
        linkEmail: '',
      },
      rules: {  },
      state: {
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 19},
      },
      data: {
        types: [],
        goods: [],
        brands: [],
      }
    };
  },
  mounted() {
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: '企业列表', isCur: false, url: '/article'},
      {name: '发布企业', isCur: true, url: ''},
    ]);
    this.$store.commit('changeSecondTabIdx', 0);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      // this.fetchTypeConditionList();
        if (!this.isAddForm) {
            // 加载数据
            await this.fetchEntity();
        } else {
            // await this.fetchBrandList();
        }
    },
    // 获取分类的数据
    async fetchTypeConditionList() {
      let res = await this.$api.articleTypeListAllAutoGroup();
      if (res.code === 0) {
        this.data.types = res.list
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.recruitCompanyUnqiue({id: this.$route.query.id});
      console.log('article-fetchentity: ', this.id, res);
      if(res.data) {
        this.form = res.data;
      }

        await this.fetchBrandList();
        await this.fetchGoodsList('', true);
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,};
      console.log('article-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.recruitCompanyAddOrSave(form);
      if (res.code === 0) {
        this.$store.commit('changeSecondTabIdx', 0);
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
    productFilterOption(inputValue, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      );
    },
    // 商品
    onGoodsChange() {
      // 找到商品
      this.data.goods.forEach(t => {
        if (t.id === this.form.productId) {
          this.form.productName = t.productName;
        }
      });
    },
    // 获取商品数据
    async fetchGoodsList(keyword='', isForce=false) {
      if(!isForce && !keyword) return ;
        if (this.isAddForm) {
            this.form.productId = '';
        }
      let res = await this.$api.productListAll({keyword: keyword, brandId: this.form.brandId});
      if (res.code === 0) {
        this.data.goods = res.list
      }
    },
    // 获取品牌数据
    async fetchBrandList() {
        if (this.isAddForm) {
            this.form.brandId = '';
        }
      let res = await this.$api.brandListAll(this.form.productType);
      if (res.code === 0) {
        this.data.brands = res.list
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
</style>
