<template>
  <div>
    <a-button v-if="!hasChildren" type="link"
              @click="onClickHandler" :class="{'menu-item': true, 'active': active}">{{name}}</a-button>
    <a-dropdown v-if="hasChildren" :overlayStyle="{zIndex: 10003}">
      <a-button type="link" :class="{'menu-item': true, 'active': active}">{{name}}<a-icon type="down" /></a-button>
      <a-menu slot="overlay">
        <a-menu-item v-for="(m, idx) in children" :key="idx">
          <a @click="onClickChildHandler(m)">{{m.name}}</a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>

</template>

<script>
export default {
  name: "MenuItem",
  props: ['name', 'url', 'active', 'index', 'children'],
  computed: {
    hasChildren() {
      return this.children && this.children.length > 0;
    }
  },
  methods: {
    onClickHandler() {
      this.$emit('menu-changed', this.index);
    },
    onClickChildHandler(m) {
      this.$emit('menu-changed', this.index);
      this.$router.push(m.url);
    }
  }
}
</script>

<style scoped>
.menu-item {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 157% */
  /* 文字颜色/黑_222 */
  color: #222222;
  border: none;
  border-radius: 0;
  padding: 0px 0px;
  margin: 0 15px;
  height: 69px;
}
.active {
  border-bottom: 2px solid #5A66FF;
  color: #5A66FF;
}
</style>
