<template>
  <a-select v-model="innerValue" style="width: 400px">
    <a-select-option v-for="(c, idx) in list" :key="idx"
                     @click="$emit('input', c.id)"
                     :value="c.id">{{ c.companyName }}</a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "RecruitCompanyFormItem",
  props: ['value'],
  data() {
    return {
      innerValue: this.value || '',
      list: []
    };
  },
  computed: {},
  mounted() {
    this.fetchCompanies();
  },
  watch: {
    value() {
      this.innerValue = this.value || '';
    }
  },
  methods: {
    async fetchCompanies() {
      let res = await this.$api.recruitCompanyListAll({});
      if (res) {
        this.list = res.list;
      }
    }
  }
}
</script>

<style scoped>

</style>