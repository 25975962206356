<template>
  <div class="page-container">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :rules="rules"
                  labelAlign="left"
                  :wrapper-col="state.wrapperCol">
      <a-row>
        <a-col :span="state.formColSpan">
<!--          <a-form-model-item label="分类">-->
<!--            <select-form-item v-model="form.productType"-->
<!--                @change="onProductTypeChangeHandler"-->
<!--                :list="data.types" value-prop="code" label-prop="name" />-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="品牌">
            <select-form-item v-model="form.brandId"
                              @change="item => form.brandName=item.name"
                              :list="data.brands"
                              value-prop="id"
                              label-prop="name" />
          </a-form-model-item>
          <a-form-model-item label="倒计时">
            <a-input v-model="form.remainDuration" addonAfter="秒" />
          </a-form-model-item>
          <a-form-model-item label="周期">
            <date-range-form-item :start-date="form.startTime" :end-date="form.endTime"
                                  @change="(start, end) => { form.startTime=start; form.endTime=end}"
                                  default-time="00:00:00" :placeholder="['请选择开始时间', '请选择结束时间']" />
          </a-form-model-item>
          <a-form-model-item label="广告图">
            <my-upload v-model="form.imageUrl" :multiple="false" />
            <p class="desc">建议尺寸200*120像素，支持、JPG或JPEG格式图片，大小不超过5MB</p>
          </a-form-model-item>
          <a-form-model-item label="跳转链接">
            <a-tag v-if="form.label" class="tag" @close="onClearLinkHandler" :closable="true">{{ form.label }}</a-tag>
            <a-cascader v-if="!form.label" @change="(_, ops) => onTypeChangeHandler(form, ops[ops.length-1], _)"
                        v-model="form.selectedItem"
                        class="cas-select"
                        :options="data.bannerOptions" size="small"
                        placeholder="跳转的页面" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>

    <!--  修改h5链接  -->
    <a-modal v-model="state.showH5PageForm" title="H5页面"
             okText="确定"
             cancelText="取消"
             @ok="onSubmitH5PageForm">
      <a-form-model
          :label-col="{span: 5, offset: 1}"
          labelAlign="left"
          :wrapper-col="{span: 16}">>
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="H5路径">
              <a-input v-model="form.linkUrl" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <!--  修改小程序链接  -->
    <a-modal v-model="state.showLittleAppForm" title="小程序页面"
             okText="确定"
             cancelText="取消"
             @ok="onSubmitLittleAppForm">
      <a-form-model
          :label-col="{span: 5, offset: 1}"
          labelAlign="left"
          :wrapper-col="{span: 16}">
        <a-row>
          <a-col :span="24">
            <div v-if="form.linkType === 2" class="warning-txt">使用该功能需要先配置小程序App名单</div>
            <a-form-model-item label="页面">
              <a-radio-group name="linkType" v-model="form.linkType">
                <a-radio :value="1">链接到我的小程序</a-radio>
                <a-radio :value="2">链接到其他小程序</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item v-if="form.linkType === 2" label="小程序AppID">
              <a-input v-model="form.linkTargetAppid" />
            </a-form-model-item>
            <a-form-model-item v-if="form.linkType === 1 || form.linkType === 2" label="小程序路径">
              <a-input v-model="form.linkUrl" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <!--  选择商品  -->
    <product-choose-config v-model="form.linkTargetId" ref="chooseProduct" />
  </div>
</template>

<script>
import ProductChooseConfig from "../../components/ProductChooseConfig";
export default {
  name: "AppSplashForm",
  components: {ProductChooseConfig},
  data() {
    return {
      form: {
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        productType: '',
        brandId: '',
        brandName: '',
        remainDuration: '3',
        startTime: '',
        endTime: '',
        linkType: '',
        linkUrl: '',
        linkTargetId: null,
        linkTargetAppid: '',
        label: '',
        selectedItem: [],
        imageUrl: '',
      },
      curItem: {},
      rules: {  },
      state: {
        showH5PageForm: false,
        showLittleAppForm: false,
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 19},
      },
      data: {
        types: [
          {code: 1, name: '奶粉'},
          {code: 2, name: '辅食'},
          {code: 3, name: '营养品'},
        ],
        brands: [],
        bannerOptions: [
          {value: 1, label: '系统链接', children: [
              {value: 101, label: '首页'},
              {value: 102, label: '不学就会'},
              {value: 103, label: '不信就试'},
              {value: 104, label: '附近门店'},
              {value: 105, label: '个人中心'},
            ]},
          {value: 2, label: '自定义路径', children: [
              {value: 201, label: 'H5页面'},
              {value: 202, label: '小程序页面'},
            ]},
          {value: 4, label: '奶粉'},
          {value: 5, label: '辅食'},
          {value: 6, label: '营养品'},
          {value: 7, label: '试用'},
          {value: 8, label: '评测'},
        ]
      }
    };
  },
  mounted() {
    // this.$store.commit('pushRouteStack', [
    //   {name: '首页', isCur: false, url: '/dashboard'},
    //   {name: '文章', isCur: false, url: '/article'},
    //   {name: '发布文章', isCur: true, url: ''},
    // ]);
    this.$store.commit('changeSecondTabIdx', 12);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      await this.fetchTypeConditionList();
      if (!this.isAddForm) {
        // 加载数据
        this.fetchEntity();
      }
    },
    // 获取分类的数据
    async fetchTypeConditionList(productType) {
      let res = await this.$api.brandListAll(productType);
      if (res.code === 0) {
        this.data.brands = res.list
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.appSplashUnqiue({id: this.$route.query.id});
      console.log('article-fetchentity: ', this.id, res);
      if(res.data) {
        this.form = res.data;
      }
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,};
      console.log('article-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.appSplashAddOrSave(form);
      if (res.code === 0) {
        this.$store.commit('changeSecondTabIdx', 0);
        this.$router.replace('/appSplash');
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
    // 修改品牌分类
    onProductTypeChangeHandler(item) {
      this.fetchTypeConditionList(item.code);
    },
    // 类别变动
    onTypeChangeHandler(item, selectedItems) {
      // let selectedOption = selectedOptions[0];
      item.type = selectedItems.value;
      item.label = selectedItems.label;
      this.curItem = item;
      if(item.type === 201) {
        // h5页面
        this.state.showH5PageForm = true;
      }else if (item.type === 202) {
        // 小程序页面
        this.state.showLittleAppForm = true;
      } else {
        console.log('修改type: ', JSON.stringify(item));
        this.form.linkType = item.type;
        switch (item.type) {
          case 101: this.form.linkUrl = 'pages/index/index'; break;
          case 102: this.form.linkUrl = 'pages/learn/index'; break;
          case 103: this.form.linkUrl = 'pages/mall/index'; break;
          case 104: this.form.linkUrl = 'pages/store/index'; break;
          case 105: this.form.linkUrl = 'pages/my/index'; break;
          case 4:
            this.form.linkUrl = 'pages/brand/detail';
            this.$refs.chooseProduct.showChooseDialog(21, this.form, () => this.submitForm());
            break;
          case 5:
            this.form.linkUrl = 'pages/brand/detail';
            // this.$emit('choose', 22, this.form.curItem, () => this.submitForm());
            this.$refs.chooseProduct.showChooseDialog(22, this.form, () => this.submitForm());
            break;
          case 6:
            this.form.linkUrl = 'pages/brand/detail';
            // this.$emit('choose', 23, this.form.curItem, () => this.submitForm());
            this.$refs.chooseProduct.showChooseDialog(23, this.form, () => this.submitForm());
            break;
          case 7:
            this.form.linkUrl = 'pages/invite';
            // this.$emit('choose', 24, this.form.curItem, () => this.submitForm());
            break;
          case 8:
            this.form.linkUrl = 'pages/evaluation/index';
            // this.$emit('choose', 25, this.form.curItem, () => this.submitForm());
            break;
        }
        if (item.type < 3 || item.type > 7) {
          this.submitForm();
        }
      }
    },
    // h5页面
    onSubmitH5PageForm() {
      this.state.showH5PageForm = false;
      this.submitForm();
    },
    // 小程序页面
    onSubmitLittleAppForm() {
      this.state.showLittleAppForm = false;
      this.submitForm();
    },
    // 删除调链接
    onClearLinkHandler() {
      this.form.label = null;
      this.form.linkType = '';
      this.form.linkUrl = '';
      this.form.linkTargetId = '';
      this.form.linkTargetAppid = '';
    },
    submitForm() {
      console.log('即将提交表单: ', JSON.stringify(this.form));
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
</style>
