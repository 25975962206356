<template>
  <div class="page-container">
    <a-row type="flex" >
      <a-col flex="1">
          <a-space>
              <a-button @click="onGotoFormHandler(null)" type="primary">发布文章</a-button>
<!--            <article-category-form-item v-model="form.category" @change="refreshList" />-->
          </a-space>
      </a-col>
      <a-col>
        <a-space size="middle">
          <a-input-search v-model="form.keyword" placeholder="请输入关键字" style="width: 200px" @search="refreshList" />
        </a-space>
      </a-col>
    </a-row>
    <br><br>
    <a-row class="data-list">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="faceUrl" slot-scope="faceUrl">
          <img :src="faceUrl" style="width: 100px; height: 100px" alt="">
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
<!--            <a @click="onUniqueHandler(row.id)" class="link">查看</a>-->
            <a @click="onEditHandler(row.id)" class="link">编辑</a>
            <a-popconfirm title="确认要删除当前数据吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onRemoveHandler(row.id)">
              <a class="link">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "Product.vue",
  components: {},
  data() {
    return {
      // 表单
      form: {
        startDate: '',
        endDate: '',
        category: this.$route.query.category,
        keyword: '',
        brandId: '',
        province: '',
        city: '',
        county: '',
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      dataList: [
      ],
      data: {
        brands: [],
        provinces: [],
        cities: [],
        counties: [],
        pageTabs: [
          {title: '文章列表', count: 100},
          {title: '分类列表', count: 20},
        ],
      },
    };
  },
  mounted() {
    this.$store.commit('clearRouteStack');
    this.initData();
  },
  watch: {
    queryCategory() {
      this.refreshList();
    }
  },
  computed: {
    queryCategory() {
      return this.$route.query.category;
    },
    columns(){
      let c = [
        {title: '编号', dataIndex:'id'},
        {title: '标题', dataIndex:'title'},
        {title: '文章分类', dataIndex:'typeName'},
        {title: '创建时间', dataIndex:'createTime'},
        {title: '有用数量', dataIndex:'usefulCount'},
        {title: '访问量', dataIndex:'visitCount'},
        {title: '来源', dataIndex:'origin'},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ]
      if ([1, 2].indexOf(this.form.category) === -1) {
        c.splice(2, 1);
      }
      return c;
    }
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('初始化数据...')
      // 先加载需要的数据
      this.refreshList();
    },
    // 刷新数据
    async refreshList() {
      this.form.category = this.queryCategory;
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.articlePage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    // 导出
    onExportHandler() {
      console.log('导出列表');
      this.$api.adminStoreExport(this.form);
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看product: '+id);
      this.$message.info("待完善");
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑product: '+id);
      this.onGotoFormHandler(id);
    },
    // 删除
    async onRemoveHandler(id) {
      console.log('删除product: '+id);
      await this.$api.articleDelete({id});
      await this.refreshList();
    },
    // 前往表单页面
    async onGotoFormHandler(id) {
      // 前往登录页面
      this.$router.push(`article/articleForm?id=${id}&category=${this.queryCategory}`);
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
.top_tab {
  border-bottom: 1px solid #EAEAEA;
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;

  .refresh-btn {
    padding-right: 20px;
    .btn {
      cursor: pointer;
    }
  }
}
</style>
