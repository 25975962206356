<template>
  <div class="page-container">
    <div class="container-block">
      <div class="static-list">
        <div class="static-item">
          <div class="title">当前状态</div>
          <div class="num text-success">{{ entity.statusTxt }}</div>
        </div>
        <div class="split"></div>
        <div class="static-item">
          <div class="title">访问数量</div>
          <div class="num">{{ entity.visitCount }}</div>
        </div>
        <div class="static-item">
          <div class="title">报名人数</div>
          <div class="num">{{ entity.enrollCount }}</div>
        </div>
        <div class="static-item">
          <div class="title">评测名额</div>
          <div class="num">{{ entity.evaluateCount }}</div>
        </div>
        <div class="static-item">
          <div class="title">报名截止</div>
          <div class="num">{{ entity.expireDate }}</div>
        </div>
        <div class="static-item">
          <div class="title">评测截止</div>
          <div class="num">{{ entity.commentEndTime }}</div>
        </div>
        <div class="static-item">
          <div class="title">创建时间</div>
          <div class="num">{{entity.createTime}}</div>
        </div>
      </div>
    </div>
    <div class="container-block">
      <a-tabs default-active-key="1" @change="onTabChangeHandler">
        <a-tab-pane v-if="entity.status > 1" key="1" tab="评测人员">
          <my-evaluation-user @opearted="fetchEntity" :evaluationId="this.$route.query.id" />
        </a-tab-pane>
        <a-tab-pane v-if="entity.status > 1" key="2" tab="评测文章">
          <my-evaluation-comment @opearted="fetchEntity" :evaluationId="this.$route.query.id" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="报名人员">
          <my-evaluation-enroll @opearted="fetchEntity" :disabled="entity.status > 1" :evaluationId="this.$route.query.id" />
        </a-tab-pane>
        <a-tab-pane key="4" tab="基础信息">
          <my-evaluation-basic :id="this.$route.query.id" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "EvaluationDetail",
  data() {
    return {
      entity: {
        status: 0,
        statusTxt: '',
        // 访问
        visitCount: 0,
        // 报名人数
        enrollCount: 0,
        // 评测名额
        evaluateCount: 0,
        // 报名截止时间
        expireDate: '',
        // 评测截止时间
        commentEndTime: '',
        // 创建时间
        createTime: '',
      },
      data: {
      }
    };
  },
  mounted() {
    this.fetchEntity();
  },
  methods: {
    async fetchEntity() {
      console.log(`EvaluationDetail-fetchEntity: `, this.$route.query.id);
      let res = await this.$api.evaluationUnqiue({id: this.$route.query.id});
      if(res.data) {
        res.data.statusTxt = ['', '报名中', '评测中', '评测结束', '未报名', '已报名', '未选中', '已选中', '待评测', '已评测'][res.data.status];
        res.data.expireDate = res.data.expireDate.substr(0, 10);
        res.data.createTime = res.data.createTime.substr(0, 10);
        this.entity = res.data;
      }
    },
    onTabChangeHandler() {

    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  min-height: 600px;
  .container-block {
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
  }
}

.static-list {
  position: relative;
  height: 60px;
  .static-item {
    float: left;
    width: 120px;
    $font-size: 14px;
    .title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      height: 30px;
      font-size: $font-size;
      line-height: 20px;
      color: #888888;
    }
    .num {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: $font-size;
      line-height: 150%;
      color: #222222;
    }
    .text-success {
      color: #00BD6C;
    }
  }
  .split {
    float: left;
    width: 1px;
    border-right: 1px solid #EAEAEA;
  }
}
</style>
