<template>
  <div class="tri-group">
    <a-divider v-if="showDivider" class="divider" type="vertical" />
    <div class="title">{{title}}</div>
    <div :class="{'num': true, 'red': showRed}">{{formattedNum}}<span class="unit">{{ unit }}</span></div>
    <div class="desc">{{ desc }} {{formattedTotal}}</div>
  </div>
</template>

<script>
export default {
  name: "DashTriCol",
  props: ['title', 'num', 'unit', 'desc', 'total', 'divider', 'red'],
  computed: {
    formattedNum() {
      return parseFloat(this.num || 0).toLocaleString();
    },
    formattedTotal() {
      return parseFloat(this.total || 0).toLocaleString();
    },
    showDivider() {
      return !!this.divider;
    },
    showRed() {
      return !!this.red;
    }
  }
}
</script>

<style lang="scss" scoped>
.tri-group {
  position: relative;
  .title, .desc {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    /* 文字颜色/灰_888 */
    color: #888888;
    height: 20px;
  }
  .num {
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 150%;
    /* identical to box height, or 48px */

    /* 文字颜色/黑_222 */
    color: #222222;
    .unit {
      color: #888888;
      font-size: 12px;
      padding-left: 4px;
    }
  }
  .red {
    color: #E54545;
  }
  .desc {}
  .divider {
    height: 60px;
    position: absolute;
    left: -20px;
    top: 10;
  }
}
</style>
