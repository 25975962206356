<template>
  <div class="page-container">
    <br>
    <a-form-model :model="form" ref="form" :rules="rules"
                 :label-col="{span: 2}"
                 :wrapper-col="{span: 16}"
    >
      <!--  奶查企服：首页入口，主页内容  -->
      <a-form-model-item label="企服入口图" prop="qfEntryImage" ref="qfEntryImage">
        <my-upload v-model="form.qfEntryImage" :multiple="false" />
      </a-form-model-item>
      <a-form-model-item label="企服主图" prop="qfMainImage" ref="qfMainImage">
        <my-upload v-model="form.qfMainImage" :multiple="false" />
      </a-form-model-item>
      <a-form-model-item style="display: flex; justify-content: center">
          <a-button type="primary" @click="submitForm">保存</a-button>
      </a-form-model-item>
    </a-form-model>

  </div>
</template>

<script>
export default {
  name: "UISetting",
  data() {
    return {
      form: {
        txOperateTutorial: null,
        txOperateExplain: null,
        qfEntryImage: null,
        qfMainImage: null,
      },
      rules: {
        txOperateTutorial: [{required: true, message: '请输入胎动操作', trigger: 'blur'}],
        txOperateExplain: [{required: true, message: '请输入胎动说明', trigger: 'blur'}],
        // qfEntryImage: [{required: true, message: '请上传企服入口图片'}],
        // qfMainImage: [{required: true, message: '请输入企服主图'}],
      },
    };
  },
  mounted() {
    this.loadConfig();
  },
  methods: {
    async loadConfig() {
      let res = await this.$api.sysConfigGet();
      console.log(res);
      if (res) {
        this.form.txOperateTutorial = res.data.txOperateTutorial;
        this.form.txOperateExplain = res.data.txOperateExplain;
        this.form.qfEntryImage = res.data.qfEntryImage;
        this.form.qfMainImage = res.data.qfMainImage;
      }
    },
    async submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$api.sysConfigSave(this.form);
        }
      })
    }
  }
}
</script>

<style>
.page-container .ant-form-item-control {
  width: 100%!important;
}
</style>
<style scoped>
.page-container {
  padding: 20px;
  background: #fff;
}
</style>