<template>
  <div>
    <div class="page-container condition-wrap">
      <a-row>
        <div class="single-input">
          <div class="label">留言时间</div>
          <date-group-picker v-on:change="onDateRangeChanged"></date-group-picker>
        </div>
      </a-row>
      <a-row>
        <a-space>
          <div class="single-input" style="width: 600px">
            <div class="label">商品</div>
            <a-select v-model="form.productId" :show-search="true" class="input-select" style="width: 300px"
                      :filterOption="productFilterOption"
                      @search="fetchGoodsList"
            >
              <a-select-option v-for="(b, idx) in data.goods"
                               :key="idx"
                               :value="b.id">
                {{b.productName}}
              </a-select-option>
            </a-select>
          </div>
        </a-space>
      </a-row>
      <a-row>
        <div class="label"></div>
        <a-space>
          <a-button @click="refreshList" type="primary">筛选</a-button>
          <a-button @click="resetForm" type="link">重置筛选</a-button>
        </a-space>
      </a-row>
    </div>
    <div class="page-container">
      <a-row class="top_tab" type="flex">
        <a-col flex="1">
          <my-page-tab :showNum="false" @change="onTabChangeHandler" v-model="data.pageTabs" />
        </a-col>
        <a-col class="refresh-btn">
        </a-col>
      </a-row>
      <br>
      <a-row class="data-list" style="padding: 0 20px">
        <div class="data-table">
          <div class="theader">
            <div class="col1">商品编号</div>
            <div class="col2">商品信息</div>
            <div class="col3">用户头像</div>
            <div class="col4">用户昵称</div>
            <div class="col5">留言内容</div>
            <div class="col6">留言时间</div>
            <div class="col8">操作</div>
          </div>
          <div class="tbody">
            <div class="trow" v-for="(ord, idx) in dataList" :key="idx">
              <div class="trow-cols">
                <div class="col1">{{ord.productId}}</div>
                <div class="col2">{{ord.productName}}</div>
                <div class="col3"><img :src="ord.headImageUrl" style="height: 30px" /></div>
                <div class="col4">{{ord.nickName}}</div>
                <div class="col5" style="overflow-wrap: break-word">{{ord.content}}</div>
                <div class="col6">{{ord.createTime}}</div>
                <div class="col8" style="text-align: center">
                  <a-button v-if="ord.hasApproved === 0" @click="onApproveHandler(ord.id, 1)" type="primary">审核</a-button>
                  <a-button v-if="ord.hasApproved === 1" @click="onApproveHandler(ord.id, 0)" type="default">取消审核</a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-row>
      <a-divider />
      <div class="page">
        <div class="left">共{{page.total}}条</div>
        <a-pagination :current="page.current" :total="page.total" :pageSize="page.pageSize"
                      @change="onPageNoChangeHandler"
                      :showQuickJumper="true" />
      </div>
    </div>
    <my-order-operate-modales v-model="state.curModal" :curOrder="curOrder" @refresh="refreshList" />
  </div>
</template>

<script>
export default {
  name: "Order.vue",
  data() {
    return {
      // 表单
      form: {
        keyword: '',
        startDate: '',
        endDate: '',
        productId: '',
        hasApproved: '',
        curTab: 0,
      },
      curOrder: {
        id: '',
        orderNo:'SN-88888888',
        createTime: '2020-01-02',
        buyerAddress: '',
        buyerUserName: '',
        buyerMobile: '',
        expressNo: '',
        expressCompany: '',
        cancelReason: '',
        cancelAddition: '',
      },
      // 状态
      state: {
        datePicker: [],
        curModal: 0,
      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      dataList: [
      ],
      data: {
        pageTabs: [
          {title: '全部'},
          {title: '未审核'},
          {title: '已审核'},
        ],
        goods: [],
      },
    };
  },
  computed: {
  },
  mounted() {
    this.$store.commit('clearRouteStack');
    this.$store.commit('changeCurRouteStack', 9);
    this.initData();
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('初始化数据...')
      // 先加载需要的数据
      this.fetchGoodsList();
      this.refreshList();
    },
    mapStatus(type) {
      return ['未审核', '已审核'][type];
    },
    // 获取商品数据
    async fetchGoodsList(keyword='') {
      if(!keyword) return ;
      let res = await this.$api.productListAll({keyword: keyword});
      if (res.code === 0) {
        this.data.goods = res.list
      }
    },
    productFilterOption(inputValue, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      );
    },
    async onApproveHandler(id, action) {
      await this.$api.productCommentApprove(id, action);
      this.refreshList()
    },
    onPageNoChangeHandler(current) {
      this.page.current = current;
      this.refreshList();
    },
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.productCommentPage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看order: '+id);
      this.onGotoFormHandler(id);
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑order: '+id);
      this.onGotoFormHandler(id);
    },
    // 前往表单页面
    async onGotoFormHandler(id) {
      // 前往登录页面
      this.$router.push(`order/orderDetail?id=${id}`);
    },
    // 页面tab页切换
    async onTabChangeHandler(idx) {
      console.log('page-productComment: 切换导航栏=', idx);
      this.form.curTab = idx;
      if (idx === 0) {
        this.form.hasApproved = null;
      } else {
        this.form.hasApproved = idx - 1;
      }
      this.refreshList();
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
    // 重置表单
    resetForm() {
      this.form = {
        keyword: '',
        startDate: '',
        endDate: '',
        productId: '',
        hasApproved: '',
        curTab: 0,
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  margin-bottom: 20px;
}
.condition-wrap {
  padding: 20px;
  border-radius: 5px;
  .single-input {
    margin-bottom: 15px;
    margin-right: 40px;
    .label {
      display: inline-block;
      width: 100px;
    }
    .input-txt {
      width: 200px;
    }
    .input-select {
      width: 160px;
    }
  }
}
.sell-goods-button {
  margin-right: 20px;
}
.top_tab {
  border-bottom: 1px solid #EAEAEA;
  height: 50px;
  line-height: 50px;

  .refresh-btn {
    padding-right: 20px;
    .btn {
      cursor: pointer;
    }
  }
}
.inline-edit-btn {
  margin-left: 10px;
  cursor: pointer;
}
.data-table {
  .col1 {
    width: 8%;
  }
  .col2 {
    width: 10%;
  }
  .col3 {
    width: 12%;
  }
  .col4 {
    width: 12%;
  }
  .col5 {
    width: 32%;
  }
  .col6 {
    width: 12%;
  }
  .col7 {
    width: 8%;
  }
  .col8 {
    width: 14%;
  }
  $grayColor: #f5f5f5;
  $borderColor: #EAEAEA;
  .theader {
    background: $grayColor;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    padding: 10px 0px;
    font-weight: 500;
    color: #222;
    text-align: center;
  }
  .tbody {
    .trow {
      margin-top: 20px;
      color: #222222;
      line-height: 20px;
      .trow-head {
        padding: 10px 20px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background: $grayColor;
      }
      .trow-cols {
        display: flex;
        border: 1px solid $borderColor;
        &>div {
          border-right: 1px solid $borderColor;
          padding: 20px;
          font-size: 12px;
        }
        &>div:last-child {
          border-right: none;
        }
        .col1 {
          .goods-item {
            $goodsImgSize: 60px;
            display: flex;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
            img {
              background: $grayColor;
              width: $goodsImgSize;
              height: $goodsImgSize;
              border-radius: 3px;
            }
            .goods-content {
              color: #888;
              flex: 1;
              margin-left: 10px;
              line-height: 20px;
              .title {
                color: #222222;
              }
              .desc {}
              .no {}
            }
            .goods-num {
              text-align: right;
            }
          }
        }
        .col2 {
          .price {
            color: #E54545;
          }
          .desc {

          }
        }
        .col4 {
          font-weight: 500;
        }
        .col5 {
          text-align: center;
        }
      }
    }
  }
}
.page {
  padding: 20px;
  display: flex;
  .left {
    flex: 1;
  }
}
</style>
