import { render, staticRenderFns } from "./PregnantBxSetting.vue?vue&type=template&id=7ecb2df6&scoped=true"
import script from "./PregnantBxSetting.vue?vue&type=script&lang=js"
export * from "./PregnantBxSetting.vue?vue&type=script&lang=js"
import style0 from "./PregnantBxSetting.vue?vue&type=style&index=0&id=7ecb2df6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ecb2df6",
  null
  
)

export default component.exports