<template>
  <div class="page-container">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :label-col="state.labelCol"
      :wrapper-col="state.wrapperCol"
      :rules="rules"
      labelAlign="left">
      <a-row>
        <a-col :span="state.formColSpan">
          <a-form-model-item label="" v-show="form.category===2">
            <a-select v-model="form.productType" style="width: 160px"
                      @change="() => fetchBrandList(null, true)">
              <a-select-option :value="1">奶粉</a-select-option>
              <a-select-option :value="2">辅食</a-select-option>
              <a-select-option :value="3">营养品</a-select-option>
              <a-select-option :value="4">纸尿裤</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="职位名称">
            <a-input v-model="form.name"/>
          </a-form-model-item>
          <a-form-model-item label="城市">
            <a-input v-model="form.city"/>
          </a-form-model-item>
          <a-form-model-item label="企业">
            <recruit-company-form-item v-model="form.companyId" />
          </a-form-model-item>
          <a-form-model-item label="薪资范围">
<!--            <a-select v-model="form.saleryType" style="width: 160px">-->
<!--              <a-select-option :value="0">不限</a-select-option>-->
<!--              <a-select-option :value="1">&lt;5k</a-select-option>-->
<!--              <a-select-option :value="2">5k-10k</a-select-option>-->
<!--              <a-select-option :value="3">10k-20k</a-select-option>-->
<!--              <a-select-option :value="4">20k以上</a-select-option>-->
<!--            </a-select>-->
            <a-space>
              <a-input v-model="form.salaryMin" style="width: 120px" placeholder="最低薪资" />
              <span>-</span>
              <a-input v-model="form.salaryMax" style="width: 120px" placeholder="最高薪资" />
            </a-space>
          </a-form-model-item>
          <a-form-model-item label="有效期">
            <date-range-form-item :start-date="form.startTime" :end-date="form.endTime"
                                  :default-time="false"
              @change="(start, end) => { form.startTime = start; form.endTime = end; }"/>
          </a-form-model-item>
          <a-form-model-item label="所需经验">
            <a-select v-model="form.expType" style="width: 160px" @change="onExpTypeChangeHandler">
              <a-select-option :value="0">不限</a-select-option>
              <a-select-option :value="1">&lt;3年</a-select-option>
              <a-select-option :value="2">3-5年</a-select-option>
              <a-select-option :value="3">5-8年</a-select-option>
              <a-select-option :value="4">8-10年</a-select-option>
              <a-select-option :value="5">10年以上</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="学历">
            <a-select v-model="form.education" style="width: 160px">
              <a-select-option :value="0">不限</a-select-option>
              <a-select-option :value="1">初中</a-select-option>
              <a-select-option :value="2">高中</a-select-option>
              <a-select-option :value="3">大专</a-select-option>
              <a-select-option :value="4">本科</a-select-option>
              <a-select-option :value="5">研究生</a-select-option>
            </a-select>
          </a-form-model-item>
<!--          <a-form-model-item label="联系电话">-->
<!--            <a-input v-model="form.linkMobile"/>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item label="联系邮箱">-->
<!--            <a-input v-model="form.linkEmail"/>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="职位描述">
            <wang-editor v-model="form.jobDescription"/>
          </a-form-model-item>
          <a-form-model-item label="任职要求">
            <wang-editor v-model="form.requirement"/>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider/>
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import RecruitCompanyFormItem from "../recruitCompany/RecruitCompanyFormItem";
export default {
  name: "RecruitJobsForm.vue",
  components: {RecruitCompanyFormItem},
  data() {
    return {
      form: {
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        name: '',
        city: '',
        companyId: '',
        saleryType: '',
        startTime: '',
        endTime: '',
        salaryMin: '',
        salaryMax: '',
        jobDescription: '',
        requirement: '',
        expType: '',
        education: '',
        linkMobile: '',
        linkEmail: '',
        status: '',
      },
      rules: {},
      state: {
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 19},
      },
      data: {
        types: [],
        goods: [],
        brands: [],
      }
    };
  },
  mounted() {
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: '工作列表', isCur: false, url: '/article'},
      {name: '发布工作', isCur: true, url: ''},
    ]);
    this.$store.commit('changeSecondTabIdx', 0);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id > 0);
    },
  },
  methods: {
    async initData() {
      // this.fetchTypeConditionList();
      if (!this.isAddForm) {
        // 加载数据
        await this.fetchEntity();
      } else {
        // await this.fetchBrandList();
      }
    },
    // 获取分类的数据
    async fetchTypeConditionList() {
      let res = await this.$api.articleTypeListAllAutoGroup();
      if (res.code === 0) {
        this.data.types = res.list
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.recruitJobsUnqiue({id: this.$route.query.id});
      console.log('article-fetchentity: ', this.id, res);
      if (res.data) {
        this.form = res.data;
      }

      // await this.fetchBrandList();
      // await this.fetchGoodsList('', true);
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,};
      if (!form.salaryMin && form.salaryMin !== 0) {
        return this.$message.error('请填写最低薪资');
      }
      if (parseFloat(form.salaryMax) < parseFloat(form.salaryMin)) {
          console.log(form.salaryMin, form.salaryMax, form.salaryMin > form.salaryMax);
        return this.$message.error('最高薪资应大于等于最低薪资');
      }
      if (!form.expType && form.expType !== 0) {
        return this.$message.error('请选择所需经验');
      }
      console.log('article-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.recruitJobsAddOrSave(form);
      if (res.code === 0) {
        // this.$store.commit('changeSecondTabIdx', 0);
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    onExpTypeChangeHandler() {
      switch (this.form.expType) {
        case 0:
          this.form.expMin = null;
          this.form.expMax = null;
          break;
        case 1:
          this.form.expMin = null;
          this.form.expMax = 3;
          break;
        case 2:
          this.form.expMin = 3;
          this.form.expMax = 5;
          break;
        case 3:
          this.form.expMin = 5;
          this.form.expMax = 8;
          break;
        case 4:
          this.form.expMin = 8;
          this.form.expMax = 10;
          break;
        case 5:
          this.form.expMin = 10;
          this.form.expMax = null;
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;

  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}

.ant-form-item-label > label {
  color: #888888 !important;
}

.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}

.ant-tag {
  padding: 4px 8px;

  a {
    color: #5A66FF;
  }
}
</style>
