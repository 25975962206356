<template>
  <div>
    <div class="page-container">
      <a-space>
        <div class="data-group">
          <div class="label">阅读数量</div>
          <div class="num">{{ form.viewCount }}</div>
        </div>
        <div class="data-group">
          <div class="label">收藏数量</div>
          <div class="num">{{ data.userCollectCount }}</div>
        </div>
        <div class="data-group">
          <div class="label">用户评论数</div>
          <div class="num">{{ data.userCommentCount }}</div>
        </div>
        <div class="data-group noborder">
          <div class="label">创建时间</div>
          <div class="num">{{ form.createTime.substr(0, 10) }}</div>
        </div>
      </a-space>
    </div>
    <div class="page-container">
      <a-form-model
          ref="ruleForm"
          :model="form"
          :label-col="state.labelCol"
          :rules="rules"
          labelAlign="left"
          :wrapper-col="state.wrapperCol">
        <a-tabs :active-key="state.activeKey" @change="onTabChangeHandler">
          <a-tab-pane key="0" tab="基础信息">
            <a-row>
              <a-col :span="state.formColSpan">
                <a-form-model-item label="品牌">
                  <div class="lval">{{form.brandName}}</div>
                </a-form-model-item>
                <a-form-model-item label="封面图">
                  <my-upload v-model="form.faceUrl" :multiple="false" />
                </a-form-model-item>
                <a-form-model-item label="产品名称">
                  <a-input v-model="form.productName" />
                  <div class="desc">不超过30个字，例如：椰树牌椰汁 250ml</div>
                </a-form-model-item>
                <a-form-model-item label="系列">
                  <a-input v-model="form.serialName"></a-input>
                  <!--                <a-select v-model="form.serialName" placeholder="请选择系列">-->
                  <!--                  <a-select-option v-for="(b, idx) in data.categories" :key="idx" :value="b.code">-->
                  <!--                    {{b.name}}-->
                  <!--                  </a-select-option>-->
                  <!--                </a-select>-->
                </a-form-model-item>
                <a-form-model-item v-if="productType === 1" label="段位">
                  <a-input v-model="form.level"></a-input>
                  <!--                <a-select v-model="form.level" placeholder="请选择段位">-->
                  <!--                  <a-select-option v-for="(b, idx) in data.levels" :key="idx" :value="b.code">-->
                  <!--                    {{b.name}}-->
                  <!--                  </a-select-option>-->
                  <!--                </a-select>-->
                </a-form-model-item>
                <a-form-model-item label="适用年龄">
                  <a-input v-model="form.suiteAge"></a-input>
                  <!--                <a-select v-model="form.suiteAge" placeholder="请选择试用年龄">-->
                  <!--                  <a-select-option v-for="(b, idx) in data.levels" :key="idx" :value="b.code">-->
                  <!--                    {{b.name}}-->
                  <!--                  </a-select-option>-->
                  <!--                </a-select>-->
                </a-form-model-item>
                <a-form-model-item label="规格">
                  <a-input v-model="form.specification" />
                </a-form-model-item>
                <a-form-model-item label="参考价">
                  <a-input v-model="form.price" style="width: 300px">
                    <span slot="addonAfter">元</span>
                  </a-input>
                </a-form-model-item>
                <a-form-model-item label="生产商">
                  <a-input v-model="form.producer">
                  </a-input>
                </a-form-model-item>
                <a-form-model-item label="注册号">
                  <a-input v-model="form.registerNo">
                  </a-input>
                </a-form-model-item>
                <a-form-model-item label="运营商">
                  <a-input v-model="form.operator">
                  </a-input>
                </a-form-model-item>
                <a-form-model-item label="产地">
                  <a-input v-model="form.produceAddress" />
                  <!--                <a-select v-model="form.produceAddress" placeholder="请选择产地">-->
                  <!--                  <a-select-option v-for="(b, idx) in data.countries" :key="idx" :value="b.code">-->
                  <!--                    {{b.name}}-->
                  <!--                  </a-select-option>-->
                  <!--                </a-select>-->
                </a-form-model-item>
                <a-form-model-item label="工艺">
                  <a-input v-model="form.produceTech">
                  </a-input>
                </a-form-model-item>
                <a-form-model-item label="产品品类">
                  <a-input v-model="form.productGroup" />
                  <!--                <a-select v-model="form.productGroup" placeholder="请选择试用产品品类">-->
                  <!--                  <a-select-option v-for="(b, idx) in data.types" :key="idx" :value="b.code">-->
                  <!--                    {{b.name}}-->
                  <!--                  </a-select-option>-->
                  <!--                </a-select>-->
                </a-form-model-item>
                <a-form-model-item label="类别">
                  <a-input v-model="form.version">
                  </a-input>
                </a-form-model-item>
                <a-form-model-item label="保质期">
                  <a-input v-model="form.expirationDate">
                  </a-input>
                </a-form-model-item>
                <a-form-model-item label="客服电话">
                  <a-input v-model="form.serviceMobile">
                  </a-input>
                </a-form-model-item>
                <!--              <a-form-model-item label="产品概要">-->
                <!--                <a-input v-model="form.productIntro">-->
                <!--                </a-input>-->
                <!--                <div class="desc">在产品卡片下面展示产品概要，最多支持10个字 </div>-->
                <!--              </a-form-model-item>-->
                <a-form-model-item label="产品评分">
                  <a-input v-model="form.score" style="width: 300px">
                    <span slot="addonAfter">分</span>
                  </a-input>
                </a-form-model-item>
                <a-form-model-item label="产品图片">
                  <my-upload v-model="form.images" :multiple="true" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane key="1" tab="营养成分">
            <div class="title">营养成分说明</div>
            <a-row>
              <a-col :span="state.formColSpan">
                <a-form-model-item label="产品信息">
                  <a-textarea v-model="form.nutritionDesc" :autoSize="{minRows: 6, maxRows: 8}" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-divider />
            <div class="title">营养成分列表</div>
            <a-row>
              <a-col :span="state.formColSpan">
                <a-form-model-item label="营养成分">
                  <a-row type="flex">
                    <a-col>
                      <a-radio-group v-model="form.nutritionUnit">
                        <a-radio-button value="每100g">每100g</a-radio-button>
                        <a-radio-button value="每100ml">每100ml</a-radio-button>
                        <a-radio-button value="每100kj">每100kj</a-radio-button>
                      </a-radio-group>
                    </a-col>
                    <a-col>
                      <a-button style="margin-left: 60px" @click="onAddNutritionSingle">添加成分</a-button>
                    </a-col>
                  </a-row>
                  <a-table style="margin-top: 10px" :columns="data.nutriColumns" :data-source="data.nutritions" :pagination="false">
                    <div slot="name" slot-scope="row">
                      <strong v-if="row.group === true">{{row.name}}</strong>
                      <div v-else>
                        {{row.name}} <a @click="onNutritionFormHandler(row)" href="javascript:;">详情</a>
                      </div>
                    </div>
                    <template slot="num" slot-scope="row">
                      <a-input style="width: 80px" v-if="row.group !== true" v-model="row.num" @change="onNutritionChangeHandler" />
                      <div v-else></div>
                    </template>
                    <template slot="action" slot-scope="row">
                      <div v-if="row.group !== true">
                        <a-icon type="delete" :style="{color: '#f00'}" @click="onRemoveNutritionSingle(row)" />
                      </div>
                      <div v-else></div>
                    </template>
                  </a-table>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane key="2" tab="扩展信息">
            <div class="title">产品特点</div>
            <a-row>
              <a-col :span="state.formColSpan">
                <a-form-model-item class="full-text-editor" label="优点">
                  <div><a-button @click="onAddAddvantageHandler" type="link">添加优点</a-button></div>
                  <div class="spec-area">
                    <a-checkbox-group v-model="state.checkedAdvantages">
                      <a-checkbox
                          v-for="(ad, idx) in data.advantages"
                          :checked="isFeatureChecked(form.advantages, ad)"
                          :value="idx"
                          @change="onToggleAdvantage"
                          :key="idx">
                        {{ad.name}} <a href="javascript:;" @click="onEditAddvantageHandler(ad)">详情</a>
                      </a-checkbox>
                    </a-checkbox-group>
                  </div>
                </a-form-model-item>
                <a-form-model-item class="full-text-editor" label="注意">
                  <div><a-button @click="onAddWarningHandler" type="link">添加注意</a-button></div>
                  <div class="spec-area">
                    <a-checkbox-group v-model="state.checkedWarnings">
                      <a-checkbox
                          v-for="(ad, idx) in data.warnings"
                          :checked="isFeatureChecked(form.warnings, ad)"
                          :value="idx"
                          @change="onToggleWarning"
                          :key="idx">
                        {{ad.name}} <a href="javascript:;" @click="onEditWarningHandler(ad)">详情</a>
                      </a-checkbox>
                    </a-checkbox-group>
                  </div>
                </a-form-model-item>
                <a-form-model-item class="full-text-editor" label="外链">
                  <div><a-button @click="onAddOutLinkHandler" type="link">添加外链</a-button></div>
                  <div class="spec-area">
                    <a-checkbox-group v-model="state.checkedLinks">
                      <a-checkbox
                          v-for="(ad, idx) in data.links"
                          :checked="isFeatureChecked(form.outerLinks, ad)"
                          :value="idx"
                          @change="onToggleOutLink"
                          :key="idx">
                        {{ad.name}} <a href="javascript:;" @click="onEditOutLinkHandler(ad)">详情</a>
                      </a-checkbox>
                    </a-checkbox-group>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </a-form-model>
      <a-divider />
      <a-space>
        <a-button @click="onCancelHandler">取消</a-button>
        <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
      </a-space>
      <!--  营养成分表单  -->
      <a-modal okText="确定" cancelText="取消" v-model="state.showNutritionFormModal" title="营养成分" @ok="onSubmitNutrition">
        <a-form-model
            ref="nutriForm"
            :model="nutriForm"
            :label-col="{span: 4}"
            labelAlign="left"
            :wrapper-col="{span: 20}">
          <a-form-model-item label="营养成分">
            <a-input v-model="nutriForm.name" />
          </a-form-model-item>
          <a-form-model-item label="说明">
            <a-textarea v-model="nutriForm.intro" :autoSize="{minRows: 4, maxRows: 6}" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!--  外链表单  -->
      <a-modal okText="确定" cancelText="取消"  v-model="state.showOutlinkFormModal" title="外链" @ok="onSubmitOutLinkHandler">
        <a-form-model
            ref="nutriForm"
            :model="linkForm"
            :label-col="{span: 4}"
            labelAlign="left"
            :wrapper-col="{span: 20}">
          <a-form-model-item label="名称">
            <a-input v-model="linkForm.name" />
          </a-form-model-item>
          <a-form-model-item label="网址">
            <a-input v-model="linkForm.url" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!--  营养成分单个项目  -->
      <a-modal okText="确定" cancelText="取消" v-model="state.showNutritionSingleForm" title="营养成分" @ok="onSubmitNutritionSingle">
        <a-form-model
            ref="nutriSingleForm"
            :model="nutriSingleForm"
            :label-col="{span: 4}"
            labelAlign="left"
            :wrapper-col="{span: 20}">
          <a-form-model-item label="分组">
            <a-select v-model="nutriSingleForm.group">
              <a-select-option value="基础信息">基础信息</a-select-option>
              <a-select-option value="维生素">维生素</a-select-option>
              <a-select-option value="矿物质">矿物质</a-select-option>
              <a-select-option value="可选择性成分">可选择性成分</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="名称">
            <a-input v-model="nutriSingleForm.name" />
          </a-form-model-item>
          <!--        <a-form-model-item label="含量">-->
          <!--          <a-input v-model="nutriSingleForm.num" />-->
          <!--        </a-form-model-item>-->
          <a-form-model-item label="单位">
            <a-input v-model="nutriSingleForm.unit" />
          </a-form-model-item>
          <a-form-model-item label="说明">
            <a-textarea v-model="nutriSingleForm.intro" :autoSize="{minRows: 4, maxRows: 6}" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductForm",
  data() {
    return {
      //provinceId=310000, cityId=310100, countyId=310113
      form: {
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        productType: this.$route.query.productType,
        faceUrl: '',
        brandId: '',
        productName: '',
        serialName: '',
        expirationDate: '',
        serviceMobile: '',
        level: '',
        suiteAge: '',
        specification: '',
        price: '',
        producer: '',
        registerNo: '',
        produceAddress: '',
        produceTech: '',
        version: '',
        productGroup: '',
        productIntro: '',
        images: [],
        // 营养成分
        nutritionDesc: '',
        nutritionUnit: '',
        nutritionDetail: [],
        advantages: [],
        warnings: [],
        outerLinks: [],
      },
      advantagesSortMap: {},
      warningsSortMap: {},
      nutriForm: {
        name: '',
        intro: '',
        visible: true
      },
      nutriSingleForm: {
        name: '',
        intro: '',
        num: '',
        unit: '',
        group: '',
      },
      linkForm: {
        name: '',
        url: '',
        visible: true
      },
      rules: {  },
      state: {
        activeKey: '0',
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 16},
        showNutritionFormModal: false,
        showOutlinkFormModal: false,
        showNutritionSingleForm: false,
        // 1营养品，2优点，3注意, 4外链
        curFormMode: 1,
        curFormAdd: false,
        nutriFormTitle: '',
        checkedAdvantages: [],
        checkedWarnings: [],
        checkedLinks: [],
      },
      data: {
        brands: [],
        levels: [],
        types: [],
        categories: [],
        advantages: [],
        warnings: [],
        links: [],
        countries: [],
        prices: [],
        nutritions: [],
        nutriColumns: [
          {dataIndex: '', title: '名称', scopedSlots: {customRender: 'name'}},
          {dataIndex: '', title: '含量', scopedSlots: {customRender: 'num'}},
          {dataIndex: 'unit', title: '单位'},
          {dataIndex: '', title: '操作', scopedSlots: {customRender: 'action'}},
        ],
        userCollectCount: 0,
        userCommentCount: 0,
      }
    };
  },
  mounted() {
    this.initData();
    let typeName = ['奶粉', '辅食', '营养品'][parseInt(this.$route.query.productType)-1];
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: typeName, isCur: false, url: '/product?type='+this.$route.query.productType},
      {name: '查看'+typeName, isCur: true, url: ''},
    ]);
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
    productType() {
      return parseInt(this.$route.query.productType);
    }
  },
  methods: {
    async initData() {
      if (this.isAddForm) {
        // 初始化优点和注意，
        // this.form.advantages = this.$utils.productFeatureAdvantageLoad();
        // this.form.warnings = this.$utils.productFeatureWarningLoad();
        this.form.nutritionDetail = this.$utils.productFeatureNrLoad();
        this.data.nutritions = this.$utils.productFeatureNrLoad();
      }
      await this.fetchTypeConditionList();
      await this.fetchBrandList();
      if (!this.isAddForm) {
        // 加载数据
        await this.fetchEntity();
      }
    },
    // 获取分类的数据
    async fetchTypeConditionList() {
      let res = await this.$api.brandListConditions(this.productType);
      console.log('product-fetchTypeConditionList: ', this.productType, res);
      if(res.data) {
        this.data.advantages = res.data.advantages;
        res.data.advantages.forEach((t, idx) => this.advantagesSortMap[t.name] = idx);
        this.data.warnings = res.data.warnings;
        res.data.warnings.forEach((t, idx) => this.warningsSortMap[t.name] = idx);
        this.data.links = res.data.links;
        this.data.countries = res.data.countries;
        // 首先筛选出所有的groupName
        let groups = {'基础信息': 'jcxx','维生素': 'wss', '矿物质': 'kwz', '可选择性成分': 'kxzxcf' };
        let savedDNrDetails = this.$utils.productFeatureNrLoad()||[];
        // savedDNrDetails.forEach((t, idx) => {
        //   if (!groups[t.group]) {
        //     groups[t.group] = idx;
        //   }
        // });
        // console.log(savedDNrDetails, groups);
        let nutritions = savedDNrDetails.map((t, idx) => ({...t, key: idx}));
        let otherNutritions = [];
        for (let i = 0; i<nutritions.length; i++) {
          let n = nutritions[i];
          if(groups[n.group] !== undefined) {
            otherNutritions.push({name: n.group, key: groups[n.group]+'a', intro: '', num: null, unit: null, group: true});
            delete groups[n.group];
          }
          otherNutritions.push(n);
        }
        for (let key in groups) {
          otherNutritions.push({name: key, key: groups[key]+'a', intro: '', num: null, unit: null, group: true});
        }
        console.log(JSON.stringify(otherNutritions))
        this.data.nutritions = otherNutritions;
      }
    },
    // 获取品牌列表
    async fetchBrandList() {
      let res = await this.$api.brandListAll(this.productType);
      console.log('product-fetchBrandList: ', res);
      if(res.list) {
        this.data.brands = res.list;
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.productUnqiue({id: this.$route.query.id});
      console.log('product-fetchentity: ', this.id, res);
      if(res.data) {
        this.form = res.data.entity;
        this.data.userCollectCount = res.data.userCollectCount;
        this.data.userCommentCount = res.data.userCommentCount;
        let nutritions = (res.data.nutritionDetail || []);
        // 根据已有的数据，进行展示，首先分组
        // 首先筛选出所有的groupName
        let groups = {};
        nutritions.forEach((t, idx) => {
          if (!groups[t.group]) {
            groups[t.group] = idx;
          }
        });
        let otherNutritions = [];
        for (let i = 0; i<nutritions.length; i++) {
          let n = nutritions[i];
          if(groups[n.group]) {
            otherNutritions.push({name: n.group, key: groups[n.group]+'a', intro: '', num: null, unit: null, group: true});
            delete groups[n.group];
          }
          otherNutritions.push(n);
        }
        this.data.nutritions = otherNutritions;
        this.state.checkedAdvantages = this.form.advantages
            .filter((t) => {
              for (let i = 0; i < this.data.advantages.length; i++) {
                if (this.data.advantages[i].name === t.name) {
                  t.idx = i;
                  return true;
                }
              }
              return false;
        }).map(t => t.idx);
        this.state.checkedWarnings = this.form.warnings
            .filter((t) => {
              for (let i = 0; i < this.data.warnings.length; i++) {
                if (this.data.warnings[i].name === t.name) {
                  t.idx = i;
                  return true;
                }
              }
              return false;
        }).map(t => t.idx);
        this.state.checkedLinks = this.form.outerLinks
            .filter((t) => {
              for (let i = 0; i < this.data.links.length; i++) {
                if (this.data.links[i].name === t.name) {
                  t.idx = i;
                  return true;
                }
              }
              return false;
        }).map(t => t.idx);
      }
    },
    // 切换tab
    onTabChangeHandler(activeKey) {
      this.state.activeKey = activeKey;
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,
        images: JSON.stringify(this.form.images),
        nutritionDetail: JSON.stringify(this.data.nutritions.filter(t => t.group !== true)),
        advantages: JSON.stringify(this.form.advantages),
        warnings: JSON.stringify(this.form.warnings),
        outerLinks: JSON.stringify(this.form.outerLinks),
      };
      console.log('product-form: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.productAddOrSave(form);
      if (res.code === 0) {
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 内链地址变更
    onInlineAddressChangedHandler(provinceId, cityId, countyId) {
      console.log('地址变更: provinceId='+provinceId+', cityId='+cityId+', countyId='+countyId)
    },
    // 营养元素含量变更
    onNutritionChangeHandler() {
      // console.log('营养元素发生了变更: ', val, this.data.nutritions);
    },
    // 营养元素详情
    onNutritionFormHandler(row) {
      // 打开营养成分详情
      // console.log(row)
      this.nutriForm = row;
      this.nutriFormTitle = '营养成分';
      this.state.showNutritionFormModal = true;
      this.state.curFormMode = 1;
    },
    // 添加优点详情
    onAddAddvantageHandler() {
      // console.log(row)
      console.log('adv', this.form.advantages)
      this.nutriForm = {name: '', intro: ''};
      this.nutriFormTitle = '添加优点';
      this.state.showNutritionFormModal = true;
      this.state.curFormAdd = true;
      this.state.curFormMode = 2;
    },
    // 编辑优点详情
    onEditAddvantageHandler(row) {
      // console.log(row)
      this.nutriForm = row;
      this.nutriFormTitle = '编辑优点';
      this.state.showNutritionFormModal = true;
      this.state.curFormAdd = false;
      this.state.curFormMode = 2;
    },
    // 切换选择状态
    onToggleAdvantage(e) {
      console.log('onToggleAdvantage: ', e.target)
      let {checked, value} = e.target;
      this.onToggleFeature(checked, value, this.data.advantages, this.form.advantages, this.advantagesSortMap)
      return true;
    },
    onToggleFeature(checked, value, dataList, formList, sortMap) {
      // 首选找features中是否有这个item，如果是有
      let foundIdx = -1;
      let checkItem = dataList[value];
      for (let i = 0; i < formList; i++) {
        let it = formList[i];
        if(it.name === checkItem.name) {
          foundIdx = i;
          break;
        }
      }
      if (checked) {
        // 选择状态
        if (foundIdx === -1) {
          formList.push(checkItem);
        }
      } else {
        // 取消选择
        if (foundIdx > -1) {
          formList.splice(foundIdx, 1);
        }
      }
      formList.sort((t1, t2) => (sortMap[t1.name] || 0) - (sortMap[t2.name] || 0));
      console.log(formList.map(t => t.name));
    },
    // 删除优点详情
    onRemoveAddvantageHandler(row) {
      // console.log(row)
      console.log('删除优点: ', row);
      let list = this.form.advantages;
      for (let i = 0; i < list.length; i++) {
        if(list[i].name === row.name) {
          list.splice(i, 1);
          break;
        }
      }
    },
    // 添加注意详情
    onAddWarningHandler() {
      this.nutriForm = {name: '', intro: ''};
      this.nutriFormTitle = '添加注意';
      this.state.showNutritionFormModal = true;
      this.state.curFormAdd = true;
      this.state.curFormMode = 3;
    },
    // 编辑注意详情
    onEditWarningHandler(row) {
      // console.log(row)
      this.nutriForm = row;
      this.nutriFormTitle = '编辑注意';
      this.state.showNutritionFormModal = true;
      this.state.curFormAdd = false;
      this.state.curFormMode = 3;
    },
    onToggleWarning(e) {
      console.log('onToggleWarning: ', e.target)
      let {checked, value} = e.target;
      this.onToggleFeature(checked, value, this.data.warnings, this.form.warnings, this.warningsSortMap)
      return true;
    },
    // 删除优点详情
    onRemoveWarningHandler(row) {
      // console.log(row)
      console.log('删除注意: ', row);
      let list = this.form.warnings;
      for (let i = 0; i < list.length; i++) {
        if(list[i].name === row.name) {
          list.splice(i, 1);
          break;
        }
      }
    },
    // 修改元素详情
    onSubmitNutrition(){
      let form = this.nutriForm;
      if (!form.name || !form.intro) {
        return this.$message.error('请输入名称及介绍');
      }
      let list = [];
      if(this.state.curFormMode === 2) {
        list = this.data.advantages;
        // this.$utils.productFeatureAdvantageUpdate(form.name, form.intro);
      }else if(this.state.curFormMode === 3) {
        list = this.data.warnings;
        // this.$utils.productFeatureWarningUpdate(form.name, form.intro);
      }
      // 盘点同样的名称是否存在
      if (this.state.curFormAdd) {
        if (list.map(t => t.name).indexOf(form.name) > -1) {
          return this.$message.error(form.name + '已存在');
        }
        list.push({...form});
      }else {
        if(list.filter(t => t.name === form.name).length > 1) {
          return this.$message.error(form.name + '已存在');
        }
      }
      this.state.showNutritionFormModal = false;
    },
    // 添加外链
    onAddOutLinkHandler(){
      this.state.showOutlinkFormModal=true;
      this.state.curFormAdd = true;
      this.linkForm = {
        name: '', url: '', visible: true,
      }
    },
    // 修改外链
    onEditOutLinkHandler(row) {
      this.state.showOutlinkFormModal=true;
      this.state.curFormAdd = false;
      this.linkForm = row;
    },
    onToggleOutLink(e) {
      console.log('onToggleOutLink: ', e.target)
      let {checked, value} = e.target;
      this.onToggleFeature(checked, value, this.data.links, this.form.outerLinks)
      return true;
    },
    // 删除外链
    onRemoveOutLinkHandler(row) {
      for (let i = 0; i < this.form.outerLinks.length; i++) {
        if(this.form.outerLinks[i].name === row.name){
          this.form.outerLinks.splice(i, 1);
        }
      }
    },
    // 添加一个营养成分
    onAddNutritionSingle() {
      this.nutriSingleForm = {
        name: '', num: '', unit: '', intro: '', group: ''
      }
      this.state.showNutritionSingleForm = true;
    },
    // 删除一个营养成分
    onRemoveNutritionSingle(row) {
      for (let i = 0; i < this.data.nutritions.length; i++) {
        if(this.data.nutritions[i].name === row.name) {
          this.data.nutritions.splice(i, 1);
          break;
        }
      }
    },
    // 确定添加一个营养成分
    onSubmitNutritionSingle() {
      // 首先找到分组下的最后一个
      let lastIdx = -1;
      for (let i = this.data.nutritions.length-1; i >=0 ; i--) {
        if (this.data.nutritions[i].name === this.nutriSingleForm.name) {
          return this.$message.error("该名称存在重复");
        }
        if (this.data.nutritions[i].group === this.nutriSingleForm.group) {
          lastIdx = i;
          break;
        }
      }
      console.log('最后一个索引为: ', lastIdx);
      let curItem = {...this.nutriSingleForm};
      this.$utils.productFeatureNrUpdate(curItem.name, curItem);
      let list = [...this.data.nutritions.splice(0, lastIdx+1), curItem, ...this.data.nutritions];
      list.map((t, idx) => ({...t, key: idx}));
      console.log(JSON.stringify(list));
      this.data.nutritions = list;
      this.state.showNutritionSingleForm = false;
    },
    // 保存外链
    onSubmitOutLinkHandler() {
      let form = this.linkForm;
      let list = this.form.outerLinks;
      if (!form.name || !form.url) {
        return this.$message.error('请输入名称及网址');
      }
      let multiCount = list.filter(t => t.name === form.name).length;
      if ((this.state.curFormAdd && multiCount>0) || (!this.state.curFormAdd && multiCount > 1)) {
        return this.$message.error(form.name+'已存在');
      }
      this.data.links.push({...form});
      this.state.showOutlinkFormModal = false;
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
    // 判断当前项目是否选择
    isFeatureChecked(list, it) {
      for (let i = 0; i < list.length; i++) {
        if(list[i].name === it.name) {
          return true;
        }
      }
      return false;
    }
  }
}
</script>
<style lang="scss" scoped>
.data-group {
  margin-left: 10px;
  margin-right: 10px;
  border-right: 1px solid #EAEAEA;
  width: 100px;
  &.noborder {
    border-right: none;
  }
  .label {
    height: 26px;
  }
  .num {
    font-size: 16px;
    font-weight: 500;
    color: #222222;
  }
}
.page-container {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #fff;
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}

.spec-area {

}

.ant-checkbox-wrapper {
  margin-left: 8px;
  margin-bottom: 4px;
}
</style>
