<template>
  <div class="page-container">
    <a-row type="flex" >
      <a-col flex="1">
        <div class="agg">评测者名额 {{page.total}} 人，已选 {{ data.selectedCount }} 人<span class="desc">（评测者名单会在报名截止期后统一推送给小程序端，报名截止期前均可更改）</span></div>
      </a-col>
      <a-col>
        <a-input-group compact>
          <a-select v-model="form.fieldType" style="width: 100px">
            <a-select-option :value="1">手机号</a-select-option>
            <a-select-option :value="2">姓名</a-select-option>
            <a-select-option :value="3">昵称</a-select-option>
          </a-select>
          <a-input v-model="form.keyword" placeholder="请输入关键字" @keypress.enter="refreshList" style="width: 200px" />
        </a-input-group>
      </a-col>
    </a-row>
    <br><br>
    <a-row class="data-list">
      <a-table :columns="columns"
               :data-source="dataList"
               :pagination="page"
               @change="onDataListChange"
      >
        <template slot="headImageUrl" slot-scope="headImageUrl">
          <img :src="headImageUrl" style="width: 100px; height: 100px" alt="">
        </template>
        <template slot="action" slot-scope="row">
          <a-space>
            <a-popconfirm v-if="!disabled && row.status === 0" title="确认要选择当前用户为测评者吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onChoosedUserHandler(row.id)">
              <a class="link">选为测评者</a>
            </a-popconfirm>
            <a-popconfirm v-if="!disabled && row.status === 1" title="确认要取消当前用户为测评者吗?" ok-text="确认" cancel-text="取消"
                          @confirm="onCancelUserHandler(row.id)">
              <a class="link">取消为测评者</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "EvaluationEnroll.vue",
  props: ['evaluationId', 'disabled'],
  data() {
    return {
      // 表单
      form: {
        fieldType: 1,
        keyword: '',
        evaluationId: this.evaluationId
      },
      // 状态
      state: {

      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      columns: [
        {title: '报名头像', dataIndex:'headImageUrl', scopedSlots: {customRender: 'headImageUrl'}},
        {title: '昵称', dataIndex:'nickName'},
        {title: '姓名', dataIndex:'userName'},
        {title: '手机号', dataIndex:'mobile'},
        {title: '地址', dataIndex:'address'},
        {title: '报名时间', dataIndex:'createTime'},
        {title: '备注', dataIndex:'memo'},
        {title: '操作', width: '210px', dataIndex:'', scopedSlots: {customRender: 'action'}},
      ],
      dataList: [
      ],
      data: {
        selectedCount: 0
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('EvaluationEnroll 初始化数据...', [this.disabled])
      // 先加载需要的数据
      this.refreshList();
    },
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.evaluationEnrollPage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page, selectedCount} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.data.selectedCount = selectedCount;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
    },
    // 选为测评者
    async onChoosedUserHandler(id) {
      console.log('选为测评者: '+id);
      await this.$api.evaluationEnrollElect({id, action: 1});
      await this.refreshList();
      this.$emit('opearted');
    },
    // 取消为测评者
    async onCancelUserHandler(id) {
      console.log('取消为测评者: '+id);
      await this.$api.evaluationEnrollElect({id, action: 0});
      await this.refreshList();
      this.$emit('opearted');
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  min-height: 600px;
  padding: 20px;
}
.top_tab {
  border-bottom: 1px solid #EAEAEA;
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;

  .refresh-btn {
    padding-right: 20px;
    .btn {
      cursor: pointer;
    }
  }
}
.agg {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* or 18px */
  color: #000000;
}
.desc {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  font-size: 13px;
  /* or 18px */
  color: #bababa;
}
</style>
