<template>
  <div class="activity-list">
    <div class="activity" v-for="(b, idx) in list" :key="idx">
      <my-upload className="faceUrl" @change="flushValue" :name="'活动封面图片'" v-model="b.faceUrl" :multiple="false"></my-upload>
      <a-textarea class="title" @change="flushValue" v-model="b.title" placeholder="请输入活动描述信息"></a-textarea>
      <a-button type="link" @click="onDeleteActivity(idx)">删除</a-button>
    </div>
    <div class="activity">
      <a-button type="link" @click="onAddActivity">添加活动</a-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array
  },
  data() {
    return {
      list: this.value,
      form: {
        title: '',
        faceUrl: '',
      }
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (oldVal === newVal) {
        return ;
      }
      console.log('store-activity-form: ', oldVal, newVal);
      this.list = this.value;
    }
  },
  methods: {
    flushValue() {
      this.$emit('input', this.list);
    },
    onAddActivity() {
      this.list.push({title: '', faceUrl: ''});
    },
    onDeleteActivity(idx) {
      console.log('delete activity idx: ', idx);
      this.list.splice(idx, 1);
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-list {
  .activity {
    width: 100%;
    background: #FAFAFA;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin-bottom: 15px;
    .faceUrl {}
    .title {
      width: 480px;
      margin-left: 20px;
    }
  }
}
</style>
