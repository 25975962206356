<template>
  <div>
    <div class="page-container condition-wrap">
      <a-row>
        <div class="single-input">
          <div class="label">下单时间</div>
          <date-group-picker v-on:change="onDateRangeChanged"></date-group-picker>
        </div>
      </a-row>
      <a-row>
        <a-space>
          <div class="single-input">
            <div class="label">订单编号</div>
            <a-input class="input-txt" v-model="form.orderNo" placeholder="请输入订单编号" />
          </div>
          <div class="single-input">
            <div class="label">商品名称</div>
            <a-input class="input-txt" v-model="form.orderName" placeholder="请输入商品名称" />
          </div>
          <div class="single-input">
            <div class="label">订单类型</div>
            <a-select class="input-select" v-model="form.goodsType">
              <a-select-option value="">全部类型</a-select-option>
              <a-select-option :value="1">商品</a-select-option>
              <a-select-option :value="2">基因</a-select-option>
            </a-select>
          </div>
        </a-space>
      </a-row>
      <a-row>
        <a-space>
          <div class="single-input">
            <div class="label">手机号</div>
            <a-input class="input-txt" v-model="form.buyerMobile" placeholder="请输入手机号" />
          </div>
          <div class="single-input">
            <div class="label">收货人姓名</div>
            <a-input class="input-txt" v-model="form.buyerUserName" placeholder="请输入收货人姓名" />
          </div>
          <div class="single-input" style="display: none">
            <div class="label">订单状态</div>
            <a-select class="input-select" v-model="form.status">
              <a-select-option value="">全部类型</a-select-option>
              <a-select-option :value="0">待付款</a-select-option>
              <a-select-option :value="1">助力中</a-select-option>
              <a-select-option :value="2">待发货</a-select-option>
              <a-select-option :value="3">待收货</a-select-option>
              <a-select-option :value="4">完成</a-select-option>
              <a-select-option :value="8">超时</a-select-option>
              <a-select-option :value="9">取消</a-select-option>
              <a-select-option :value="10">助力失败</a-select-option>
            </a-select>
          </div>
        </a-space>
      </a-row>
      <a-row>
        <div class="label"></div>
        <a-space>
          <a-button @click="refreshList" type="primary">筛选</a-button>
          <a-button @click="resetForm" type="link">重置筛选</a-button>
        </a-space>
      </a-row>
    </div>
    <div class="page-container">
      <a-row class="top_tab" type="flex">
        <a-col flex="1">
          <my-page-tab :showNum="true" @change="onTabChangeHandler" v-model="data.pageTabs" />
        </a-col>
        <a-col class="refresh-btn">
        </a-col>
      </a-row>
      <br>
      <a-row class="data-list" style="padding: 0 20px">
        <div class="data-table">
          <div class="theader">
            <div class="col1">商品信息</div>
            <div class="col2">订单实收金额</div>
            <div class="col3">买家/收货人</div>
            <div class="col4">订单状态</div>
            <div class="col5">操作</div>
          </div>
          <div class="tbody">
            <div class="trow" v-for="(ord, idx) in dataList" :key="idx">
              <div class="trow-head">
                <a-space size="large">
                  <span>订单编号: {{ ord.orderNo }}</span>
                  <span>下单时间: {{ord.createTime}}</span>
                  <span>{{ mapOrderType(ord.goodsType) }}订单</span>
                  <span>支付单号: {{ ord.orderPayNo }}</span>
                </a-space>
              </div>
              <div class="trow-cols">
                <div class="col1">
                  <div class="goods-item" v-for="(ordi, idx1) in ord.detailList" :key="idx1">
                    <img :src="ordi.itemImageUrl" alt="">
                    <div class="goods-content">
                      <div class="title">{{ordi.itemName}}</div>
                      <div class="desc">
                        <a-space>
                          <span>颜色: {{ordi.specification.color}}</span>
                          <span>尺寸: {{ordi.specification.size}}</span>
                        </a-space>
                      </div>
                    </div>
                    <div class="goods-num">
                      <div>¥{{mapPrice(ordi.price)}}</div>
                      <div>x{{ordi.buyCount}}</div>
                    </div>
                  </div>
                </div>
                <div class="col2">
                  <div class="price">￥{{mapPrice(ord.totalPrice)}}</div>
                  <div class="desc">（{{mapExpressFee(ord.expressPrice)}}）</div>
                </div>
                <div class="col3">
                  <div class="txt">买家昵称：{{ ord.buyerNickname }}</div>
                  <div class="txt">收货人姓名：{{ ord.buyerUserName }}</div>
                  <div class="txt">收货人手机号：{{ ord.buyerMobile }}</div>
                  <div class="txt">收货地址：{{ ord.buyerAddress }}</div>
                </div>
                <div class="col4">{{mapOrderStatus(ord.status)}}</div>
                <div class="col5">
                  <a-space direction="vertical">
                    <a-button @click="onUniqueHandler(ord.id)" type="link">订单详情</a-button>
                    <a-popconfirm v-if="ord.status === 2" title="确定要发货吗？" ok-text="确定" cancel-text="取消" placement="bottom" @confirm="onSendGoodsHandler(ord)">
                      <a-button type="primary">发货</a-button>
                    </a-popconfirm>
                    <a-popconfirm v-if="ord.status === 0" title="确定要取消订单吗?" ok-text="确定" cancel-text="取消" placement="bottom" @confirm="onCancelOrderHandler(ord)">
                      <a-button>取消订单</a-button>
                    </a-popconfirm>
                  </a-space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-row>
      <a-divider />
      <div class="page">
        <div class="left">共{{page.total}}条</div>
        <a-pagination :current="page.current" :total="page.total" :pageSize="page.pageSize"
                      @change="onPageNoChangeHandler"
                      :showQuickJumper="true" />
      </div>
    </div>
    <my-order-operate-modales v-model="state.curModal" :curOrder="curOrder" @refresh="refreshList" />
  </div>
</template>

<script>
export default {
  name: "Order.vue",
  data() {
    return {
      // 表单
      form: {
        keyword: '',
        startDate: '',
        endDate: '',
        goodsType: '',
        buyerMobile: '',
        buyerUserName: '',
        status: '',
        orderNo: '',
        orderName: '',
        curTab: 0,
      },
      curOrder: {
        id: '',
        orderNo:'SN-88888888',
        createTime: '2020-01-02',
        buyerAddress: '',
        buyerUserName: '',
        buyerMobile: '',
        expressNo: '',
        expressCompany: '',
        cancelReason: '',
        cancelAddition: '',
      },
      // 状态
      state: {
        datePicker: [],
        curModal: 0,
      },
      // 分页信息
      page: { current: 1, pageNow: 1, pageSize: 10, total: 0, size: '10', showQuickJumper: true },
      dataList: [
      ],
      data: {
        pageTabs: [
          {title: '全部', count: 0, status: ''},
          {title: '待付款', count: 0, status: 0},
          {title: '助力中', count: 0, status: 1},
          {title: '待发货', count: 0, status: 2},
          {title: '待收货', count: 0, status: 3},
          {title: '完成', count: 0, status: 4},
          {title: '超时', count: 0, status: 8},
          {title: '取消', count: 0, status: 9},
          {title: '助力失败', count: 0, status: 10},
        ],
      },
    };
  },
  computed: {
  },
  mounted() {
    this.$store.commit('clearRouteStack');
    this.$store.commit('changeCurRouteStack', 9);
    this.initData();
  },
  methods: {
    // 初始化数据
    async initData() {
      console.log('初始化数据...')
      // 先加载需要的数据
      this.refreshList();
    },
    mapOrderType(type) {
      return ['', '商品', '基因'][type];
    },
    mapOrderStatus(type) {
      return ['待付款', '助力中', '待发货', '待收货', '完成', '','','','超时', '取消', '助力失败'][type];
    },
    mapPrice(price) {
      return (price || 0).toFixed(2);
    },
    mapExpressFee(fee) {
      if(fee > 0) {
        return '邮费: ￥' + this.mapPrice(fee) + '元';
      }
      return '包邮';
    },
    // 刷新数据
    async refreshList() {
      console.log("获取数据: ", JSON.stringify(this.form));
      let res = await this.$api.orderPage({pageNow: this.page.current, pageSize: this.page.pageSize, ...this.form});
      let {page, dataSum} = res.data;
      this.page.current = page.current;
      this.page.total = page.total;
      this.page.pages = page.pages;
      this.dataList = (page.records || []).map((t, idx) => ({...t, key: idx}));
      this.data.pageTabs[0].count = dataSum.totalCount;
      this.data.pageTabs[1].count = dataSum.count1;
      this.data.pageTabs[2].count = dataSum.count2;
      this.data.pageTabs[3].count = dataSum.count3;
      this.data.pageTabs[4].count = dataSum.count4;
      this.data.pageTabs[5].count = dataSum.count5;
      this.data.pageTabs[6].count = dataSum.count6;
      this.data.pageTabs[7].count = dataSum.count7;
      this.data.pageTabs[8].count = dataSum.count8;
    },
    // 日期改变
    onDateRangeChanged(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    onPageNoChangeHandler(current) {
      this.page.current = current;
      this.refreshList();
    },
    // 查看
    onUniqueHandler(id) {
      console.log('查看order: '+id);
      this.onGotoFormHandler(id);
    },
    // 编辑
    onEditHandler(id) {
      console.log('编辑order: '+id);
      this.onGotoFormHandler(id);
    },
    // 订单发货
    async onSendGoodsHandler(ord) {
      this.curOrder = ord;
      this.state.curModal = 1;
    },
    // 订单发货
    async onCancelOrderHandler(ord) {
      this.curOrder = ord;
      this.state.curModal = 2;
    },
    // 上架
    async onPullonHandler(id) {
      // console.log('删除order: '+id);
      await this.$api.goodsPopup({id, action: 1});
      await this.refreshList();
    },
    // 下架
    async onPulloffHandler(id) {
      // console.log('删除order: '+id);
      await this.$api.goodsPopup({id, action: 0});
      await this.refreshList();
    },
    // 推广
    async onPormoptHandler(id) {
      console.log('推广goods: '+id);
      // todo
      await this.refreshList();
    },
    // 前往表单页面
    async onGotoFormHandler(id) {
      // 前往登录页面
      this.$router.push(`order/orderDetail?id=${id}`);
    },
    // 页面tab页切换
    async onTabChangeHandler(idx) {
      let status = this.data.pageTabs[idx].status;
      console.log('page-goods: 切换导航栏=', idx, status);
      this.form.curTab = idx;
      this.form.status = status;
      this.refreshList();
    },
    // 翻页
    onDataListChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(this.name+'表格列表发生了变更: ', pagination, filters, sorter, currentDataSource);
      this.page.current = pagination.current;
      this.refreshList();
    },
    // 重置表单
    resetForm() {
      this.form = {
        keyword: '',
        startDate: '',
        endDate: '',
        goodsType: '',
        buyerMobile: '',
        buyerUserName: '',
        status: '',
        orderNo: '',
        orderName: '',
        curTab: 0,
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  margin-bottom: 20px;
}
.condition-wrap {
  padding: 20px;
  border-radius: 5px;
  .single-input {
    margin-bottom: 15px;
    margin-right: 40px;
    .label {
      display: inline-block;
      width: 100px;
    }
    .input-txt {
      width: 200px;
    }
    .input-select {
      width: 160px;
    }
  }
}
.sell-goods-button {
  margin-right: 20px;
}
.top_tab {
  border-bottom: 1px solid #EAEAEA;
  height: 50px;
  line-height: 50px;

  .refresh-btn {
    padding-right: 20px;
    .btn {
      cursor: pointer;
    }
  }
}
.inline-edit-btn {
  margin-left: 10px;
  cursor: pointer;
}
.data-table {
  .col1 {
    width: 42%;
  }
  .col2 {
    width: 9%;
  }
  .col3 {
    width: 23%;
  }
  .col4 {
    width: 9%;
  }
  .col5 {
    width: 17%;
  }
  $grayColor: #f5f5f5;
  $borderColor: #EAEAEA;
  .theader {
    background: $grayColor;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    padding: 10px 20px;
    font-weight: 500;
    color: #222;
  }
  .tbody {
    .trow {
      margin-top: 20px;
      color: #222222;
      line-height: 20px;
      .trow-head {
        padding: 10px 20px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background: $grayColor;
      }
      .trow-cols {
        display: flex;
        border: 1px solid $borderColor;
        &>div {
          border-right: 1px solid $borderColor;
          padding: 20px;
          font-size: 12px;
        }
        &>div:last-child {
          border-right: none;
        }
        .col1 {
          .goods-item {
            $goodsImgSize: 60px;
            display: flex;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
            img {
              background: $grayColor;
              width: $goodsImgSize;
              height: $goodsImgSize;
              border-radius: 3px;
            }
            .goods-content {
              color: #888;
              flex: 1;
              margin-left: 10px;
              line-height: 20px;
              .title {
                color: #222222;
              }
              .desc {}
              .no {}
            }
            .goods-num {
              text-align: right;
            }
          }
        }
        .col2 {
          .price {
            color: #E54545;
          }
          .desc {

          }
        }
        .col4 {
          font-weight: 500;
        }
        .col5 {
          text-align: center;
        }
      }
    }
  }
}
.page {
  padding: 20px;
  display: flex;
  .left {
    flex: 1;
  }
}
</style>
