<template>
  <div class="image-viewer">
    <div class="image-wrap" @click="state.showBigModal=true">
      <img v-if="hasPic" :src="value" alt="查看图片" />
      <a-icon v-if="!hasPic" type="picture" class="no-pic" />
      <div v-if="showHit" class="show-image-bg"></div>
      <div v-if="showHit" class="show-image-txt">查看大图</div>
    </div>
    <a-modal class="image-viewer-modal" :visible="state.showBigModal"
             @cancel="state.showBigModal = false"
             :width="800"
             :dialogStyle="{backgroundColor: 'transparent'}"
             :footer="false"
             >
      <div style="padding: 20px">
        <img v-if="hasPic" :src="value" style="width: 100%"/>
        <a-icon v-if="!hasPic" type="picture" style="font-size: 100px; width: 100%" />
      </div>
    </a-modal>
  </div>
</template>
<style>
.image-viewer-modal .ant-modal-content {
  background: transparent;
  box-shadow: none;
}
</style>
<script>
export default {
  name: "ImageViewer",
  props: {
    // 图片地址
    value: {
      required: true,
      type: String,
    },
    // 是否展示展示大图
    showHit: {
      required: false,
      type: Boolean,
      defaultValue: false
    }
  },
  data() {
    return {
      state: {
        showBigModal: false
      }
    };
  },
  computed: {
    hasPic() {
      return !!this.value;
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.image-viewer {
  width: 100%;
  $width: 50px;
  $height: 50px;
  .image-wrap {
    position: relative;
    width: $width;
    height: $height;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    img {
      width: $width;
      height: $height;
    }
    .no-pic {
      font-size: $height;
    }
    .show-image-bg {
      background: rgba(0,0,0, 0.4);
      position: absolute;
      bottom: 0;
      left: 0;
      height: 20px;
      width: $width;
    }
    .show-image-txt {
      text-align: center;
      color: #fff;
      width: $width;
      font-size: 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      line-height: 20px;
      height: 20px;
    }
  }
}
</style>
