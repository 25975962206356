<template>
  <a-date-picker v-model="valObj"
                 :show-time="getShowTimeConfig()"
                 @change="onDateChangeHandler"
                 :placeholder="placeholder" />
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
export default {
  name: "DateFormItem",
  props: ['value', 'placeholder', 'defaultTime'],
  data() {
    return {
      valObj: moment()
    }
  },
  mounted() {
    this.transformDateVal();
  },
  watch: {
    value() {
      this.transformDateVal();
    }
  },
  methods: {
    getShowTimeConfig() {
      return {open:true, defaultValue: moment(this.defaultTime, 'HH:mm:ss')};
    },
    onDateChangeHandler(_, dateStr) {
      this.$emit('input', dateStr);
    },
    transformDateVal() {
      try {
        if (!this.value) {
          this.valObj = null;
        } else {
          this.valObj = moment(this.value);
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
