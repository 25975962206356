<template>
  <div class="page-container">
    <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :label-col="state.labelCol"
                  :rules="rules"
                  labelAlign="left"
                  :wrapper-col="state.wrapperCol">
      <a-tabs :active-key="state.activeKey" @change="onTabChangeHandler">
        <a-tab-pane key="0" tab="基础信息">
          <div class="title">代理信息</div>
          <a-row>
            <a-col :span="state.formColSpan">
<!--              <a-form-model-item label="连锁品牌">-->
<!--                <a-select v-model="form.brandId" placeholder="请选择品牌">-->
<!--                  <a-select-option v-for="(b, idx) in data.brands" :key="idx" :value="b.id">-->
<!--                    {{b.name}}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item label="连锁品牌">-->
<!--                <a-input v-model="form.brandName" />-->
<!--              </a-form-model-item>-->
              <a-form-model-item label="代理商名称">
                <a-input v-model="form.storeName" />
                <p class="desc">不超过30个字</p>
              </a-form-model-item>
<!--              <a-form-model-item label="代理商编号">-->
<!--                <a-input v-model="form.storeNo" />-->
<!--              </a-form-model-item>-->
              <a-form-model-item label="代理商地址">
                <inline-address :province="form.province" :city="form.city" :county="form.county" :only-province="true" @changed="onInlineAddressChangedHandler" />
              </a-form-model-item>
              <a-form-model-item label="详细地址">
                <a-input v-model="form.address" />
                <tecent-map :width="800" :height="500"
                             :lat="form.lat" :lng="form.lng" v-model="form.address"
                             @change="onMapChangeHandler" />
                <p class="desc">请输入详细代理商地址且该地址被腾讯地图已收录，如未收录请先前往腾讯地图<a target="_blank" href="http://map.qq.com">【商家标注】</a></p>
              </a-form-model-item>
              <a-form-model-item label="代理商电话">
                <a-input v-model="form.linkMobile" />
              </a-form-model-item>
<!--              <a-form-model-item label="代理商项目">-->
<!--                <a-select-->
<!--                    mode="multiple"-->
<!--                    v-model="form.serviceIds"-->
<!--                    style="width: 100%"-->
<!--                    placeholder="请选择代理商项目"-->
<!--                    @change="onStoreServicesChanged"-->
<!--                >-->
<!--                  <a-select-option v-for="(b) in data.services" :key="b.id" :value="b.id">-->
<!--&lt;!&ndash;                    <span>&ndash;&gt;-->
<!--&lt;!&ndash;                      <img :src="b.icon" style="width: 20px; height: 20px" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                    </span>&ndash;&gt;-->
<!--                    {{ b.title }}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-model-item>-->
              <a-form-model-item label="代理商封面图">
                <my-upload v-model="form.faceUrl" :name="'代理商封面图'" :multiple="false" />
              </a-form-model-item>
              <a-form-model-item label="代理商二维码">
                <my-upload v-model="form.qrcode" :name="'代理商二维码'" :multiple="false" />
                <p class="desc">该代理商的微信二维码，建议尺寸200*200像素</p>
              </a-form-model-item>
              <a-form-model-item label="代理商图片">
                <my-upload v-model="form.images" :multiple="true" :name="'代理商图片'" :max-file="9" />
                <p class="desc">最多上传9张，建议尺寸320*180像素，鼠标拖拽图片调整顺序，支持、JPG或JPEG格式图片，大小不超过5MB</p>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="1" tab="代理商介绍">
          <div class="title">代理商介绍</div>
          <a-row>
            <a-col :span="state.formColSpan">
              <a-form-model-item label="代理商介绍">
                <a-textarea v-model="form.storeIntro" :autosize="{minRows: 6}" style="width: 480px" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <div class="title">经营品牌</div>
          <a-row>
            <a-col :span="state.formColSpan">
              <a-form-model-item label="经营品牌">
<!--                <my-upload v-model="form.brandImages" :name="'品牌图片'" :multiple="true" :max-file="9" />-->
<!--                <p class="desc">经营品牌图片，建议尺寸200*200像素 </p>-->
                <a-textarea v-model="form.operateBrands" :autosize="{minRows: 6}" style="width: 480px" placeholder="请输入经营品牌" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="2" v-if="false" tab="活动介绍">
          <div class="title">活动</div>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="展示活动">
                <store-activity-form v-model="form.activities" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="3" v-if="false" tab="视频">
          <div class="title">视频</div>
          <a-form-model-item label="添加视频">
            <my-video-upload v-model="form.videos" :multiple="true" />
          </a-form-model-item>
        </a-tab-pane>
        <a-tab-pane key="4" v-if="false" tab="优惠券">
          <div class="title">优惠券</div>
          <a-form-model-item label="添加优惠券">
            <store-coupon-form v-model="form.coupons" />
          </a-form-model-item>
        </a-tab-pane>
      </a-tabs>
    </a-form-model>
    <a-divider />
    <a-space>
      <a-button @click="onCancelHandler">取消</a-button>
      <a-button type="primary" @click="onSubmitFormHandler">保存</a-button>
    </a-space>
    <!--  营养成分表单  -->
<!--    <a-modal v-model="state.showNutritionFormModal" title="营养成分" @ok="">-->
<!--      <a-form-model-->
<!--          ref="nutriForm"-->
<!--          :model="nutriForm"-->
<!--          :label-col="{span: 4}"-->
<!--          labelAlign="left"-->
<!--          :wrapper-col="{span: 20}">-->
<!--        <a-form-model-item label="营养成分">-->
<!--          <a-input v-model="nutriForm.name" />-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="说明">-->
<!--          <a-textarea v-model="nutriForm.intro" :autoSize="{minRows: 4, maxRows: 6}" />-->
<!--        </a-form-model-item>-->
<!--      </a-form-model>-->
<!--    </a-modal>-->
  </div>
</template>

<script>
import StoreActivityForm from "@/pages/store/StoreActivityForm";
import StoreCouponForm from "@/pages/store/StoreCouponForm";
import TecentMap from "@/components/TecentMap";
import {transformFromGCJToBaidu, transformFromBaiduToGCJ} from '@/utils/WSCoordinate'
export default {
  name: "ProductForm",
  components: {
    TecentMap,
    'store-activity-form': StoreActivityForm,
    'store-coupon-form': StoreCouponForm,
  },
  data() {
    return {
      //provinceId=310000, cityId=310100, countyId=310113
      form: {
        // 代理商信息
        id: this.$route.query.id === 'null' ? '' : this.$route.query.id,
        brandId: '',
        storeName: '',
        lat: 39.903740,
        lng: 116.397827,
        faceUrl: '',
        province: '',
        city: '',
        county: '',
        address: '',
        linkMobile: '',
        score: '',
        services: [],
        serviceIds: [],
        qrcode: '',
        operateBrands: '',
        images: [],
        // 品牌介绍
        storeIntro: '',
        brandImages: [],
        // 活动
        activities: [],
        // 视频
        videos: [],
        // 优惠券
        coupons: [],
        type: 2
      },
      rules: {  },
      state: {
        activeKey: '0',
        formColSpan: 22,
        labelCol: {span: 3, offset: 2},
        wrapperCol: {span: 18},
      },
      data: {
        brands: [],
        services: [
          {id: 1, title: "宝宝理发", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/bblf.png"},
          {id: 2, title: "宝宝游泳", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/bbyy.png"},
          {id: 3, title: "产康护理", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/ckhl.png"},
          {id: 4, title: "儿童摄影", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/etsy.png"},
          {id: 5, title: "视力检测", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/sljc.png"},
          {id: 6, title: "送货上门", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/shsm.png"},
          {id: 7, title: "体质监测", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/tzjc.png"},
          {id: 8, title: "小儿推拿", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/xetn.png"},
          {id: 9, title: "娱乐项目", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/ylxm.png"},
          {id: 10, title: "育儿课堂", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/yekt.png"},
          {id: 11, title: "主题乐园", "icon": "https://milk-query-1304198898.cos.ap-chengdu.myqcloud.com/icons/ztly.png"},
        ]
      }
    };
  },
  mounted() {
    this.$store.commit('pushRouteStack', [
      {name: '首页', isCur: false, url: '/dashboard'},
      {name: '代理商', isCur: false, url: '/proxy'},
      {name: '发布代理商', isCur: true, url: ''},
    ]);
    this.initData();
  },
  computed: {
    // 页面模式
    isAddForm() {
      return !(this.$route.query.id>0);
    },
  },
  methods: {
    async initData() {
      await this.fetchBrandList();
      if (!this.isAddForm) {
        // 加载数据
        this.fetchEntity();
      }
    },
    // 获取品牌列表
    async fetchBrandList() {
      let res = await this.$api.brandListAll();
      console.log('store-fetchBrandList: ', res);
      if(res.list) {
        this.data.brands = res.list;
      }
    },
    // 加载表单编辑时需要的数据
    async fetchEntity() {
      let res = await this.$api.storeUnqiue({id: this.$route.query.id});
      console.log('store-fetchentity: ', this.$route.query.id, res);
      if(res.data) {
        res.data.coupons = res.list;
        // 服务项目
        let serviceList = [];
        this.data.services.forEach((t) => {
          if ((res.data.services||[]).filter(t1 => t1 && t.id === t1.id).length > 0) {
            serviceList.push(t.id);
          }
        });
        res.data.serviceIds = serviceList;
        // console.log('serviceList: ', res.data, this.data.services);
        if(!res.data.advantages) res.data.advantages = [];
        if(!res.data.warnings) res.data.warnings = [];
        if(!res.data.outerLinks) res.data.outerLinks = [];
        if(!res.data.activities) res.data.activities = [];
        console.log('store-fetchentity-result: ', res.data)
        let {latitude, longitude} = transformFromBaiduToGCJ(res.data.lat, res.data.lng);
        res.data.lat = latitude;
        res.data.lng = longitude;
        this.form = res.data;
      }
    },
    // 切换tab
    onTabChangeHandler(activeKey) {
      this.state.activeKey = activeKey;
    },
    // 提交表单
    async onSubmitFormHandler() {
      console.log('pagemode: ', this.isAddForm);
      let form = {...this.form,};
      console.log('store-form: ', JSON.stringify(form));
      let {latitude, longitude} = transformFromGCJToBaidu(this.form.lat, this.form.lng);
      form.lat = latitude;
      form.lng = longitude;
      console.log('store-form-after: ', JSON.stringify(form));
      // 添加的form
      let res = await this.$api.storeAddOrSave(form);
      if (res.code === 0) {
        this.$router.back();
      }
    },
    // 取消
    onCancelHandler() {
      this.$router.back()
    },
    // 上传图片完成
    onImagesUploaded(images) {
      console.log('上传图片完成: ', JSON.stringify(images));
      this.form.images = images;
    },
    // 地址
    onInlineAddressChangedHandler(province, city, county) {
      this.form.province = province;
      this.form.city = city;
      this.form.county = county;
    },
    // 选择代理商项目
    onStoreServicesChanged(item) {
      console.log('service-item changed: ', item);
      this.form.services.splice(0);
      item.forEach(t => {
        this.data.services.forEach( t1 => {
          if(t === t1.id) {
            this.form.services.push(t1);
          }
        })
      })
    },
    // 地图地址有变化
    onMapChangeHandler(lat, lng, address) {
      this.form.lat = lat;
      this.form.lng = lng;
      this.form.address = address;
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  background: #fff;
  $input-width: 300px;
  .ant-input {
    width: $input-width;
  }
  .ant-select {
    width: $input-width;
  }
  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    /* identical to box height, or 157% */
    /* 文字颜色/黑_222 */
    color: #222222;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.ant-form-item-label > label {
  color: #888888!important;
}
.spec-area {
  padding: 10px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 50px;
}
.ant-tag {
  padding: 4px 8px;
  a {
    color: #5A66FF;
  }
}
</style>
