<template>
  <div :id="domId"></div>
</template>

<script>
import wangEditor from 'wangeditor';
export default {
  name: "WangEditor",
  props: ['value'],
  data() {
    return {
      domId: 'wang_edtior'+Math.floor(Math.random()*10000),
      editor: null,
      editorData: '',
    }
  },
  mounted() {
    const editor = new wangEditor(`#${this.domId}`);
    editor.config.onchange = newHtml => {
      this.editorData = newHtml;
      this.$emit('input', newHtml);
    }
    let that = this;
    editor.config.zIndex = 1
    // editor.config.uploadImgServer = '/upload-img'
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024 // 2M
    editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
    editor.config.uploadImgMaxLength = 1 // 一次最多上传 5 个图片
    editor.config.customUploadImg = async function (resultFiles, insertImgFn) {
      // resultFiles 是 input 中选中的文件列表
      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
      console.log(resultFiles);
      let res = await that.$api.publicUpload({file: resultFiles[0]})
      // console.log(res);
      // 上传图片，返回结果，将图片插入到编辑器中
      insertImgFn(res.data.url)
    }
    // 视频设置
    editor.config.uploadVideoMaxSize = 1 * 1024 * 1024 * 1024 // 1024m
    editor.config.uploadVideoAccept = ['mp4']
    editor.config.customUploadVideo = async function (resultFiles, insertVideoFn) {
      // resultFiles 是 input 中选中的文件列表
      // insertVideoFn 是获取视频 url 后，插入到编辑器的方法
      let res = await that.$api.publicUpload({file: resultFiles[0]})
      // 上传视频，返回结果，将视频地址插入到编辑器中
      insertVideoFn(res.data.url)
    }
    editor.create();
    this.editor = editor;
    this.editor.txt.html(this.value);
  },
  watch: {
    value() {
      if (this.value !== this.editorData) {
        this.editor.txt.html(this.value);
      }
    }
  }
}
</script>

<style scoped>

</style>