<template>
<div class="footer">
  Copyright © 2020 - 2020. All Rights Reserved. 奶查版权所有
</div>
</template>
<script>
export default {
  name: "footer",
  comments: {

  }
}
</script>
<style>
.footer{
  width: 100%;
  padding: 20px;
  margin-top: 40px;
  text-align: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: #222222;
}
</style>
