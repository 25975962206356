<template>
  <div>
    <div>坐标点: {{lat}}, {{lng}}</div>
    <div>坐标地址: {{searchAddress}}</div>
    <div>
      <a-input v-model="searchAddress" />
    </div>
    <tencent-map :width="800" :height="500" :lat="lat" :lng="lng" v-model="searchAddress" @change="onMapChangeHandler" />
    <!--引入Javascript API GL，参数说明参见下文    -->
  </div>
</template>

<script>

export default {
  name: "TecentMapTest",
  data() {
    return {
      searchAddress: '',
      lat: 0,
      lng: 0,
    };
  },
  methods: {
    onMapChangeHandler(lat, lng, address) {
      console.log('地图中的地址发生了变化: ', lat, lng, address);
      this.lat = lat;
      this.lng = lng;
      this.searchAddress = address;
    },
  }
}
</script>

<style lang="scss" scoped>
.map-container {
  /*地图(容器)显示大小*/
  width: 800px;
  height:400px;
}
</style>
