<template>
  <div class="login-page-container">
    <!--  背景  -->
    <div class="right-top-bg">
      <img src="../assets/images/login-bottom-right-top.png" alt="">
    </div>
    <div class="left-bottom-bg">
      <img src="../assets/images/login-bg-left-bottom.png" alt="">
    </div>
    <!-- 登录框主体 -->
    <div class="login-body" :gutter="24" justify="space-around">
      <a-row>
        <a-col :flex="1" :span="12" :offset="6">
          <a-row type="flex" justify="center" class="logo">
            <img src="../assets/images/logo.png" alt="">
          </a-row>
          <a-row class="slogo">母婴查询，就用奶查</a-row>
          <a-row class="slogo1">欢迎使用后台管理系统</a-row>
          <a-row class="sinput"><a-input v-model="form.loginName" placeholder="请输入用户名"></a-input></a-row>
          <a-row class="sinput">
            <a-input-password v-model="form.loginPass" placeholder="请输入密码"></a-input-password>
          </a-row>
          <a-row class="login-btn">
            <a-button type="primary" :loading="state.loginBtnLoading" @click="onSubmitHandler" block>登录</a-button>
          </a-row>
          <br>
          <a-row type="flex" align="middle">
            <a-col flex="1">
              <a-checkbox v-model="isAutoLogin">三天内自动登录</a-checkbox>
            </a-col>
            <a-col>
              <a-button type="link" @click="onForgetPassHandler">忘记密码</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/common/Footer'
const AUTO_LOGIN_KEY = 'milk_query_is_auto_login';
const AUTO_LOGIN_VAL = 'PdiowmUE===';
export default {
  name: "Login.vue",
  components: {Footer},
  data() {
    return {
      state: {
        loginBtnLoading: false
      },
      form: {
        loginName: '',
        loginPass: '',
      },
      isAutoLogin: false,
    }
  },
  mounted() {
    // 是否自动登录
    if (AUTO_LOGIN_VAL === localStorage.getItem(AUTO_LOGIN_KEY)) {
      this.checkTokenValid();
    }
  },
  computed: {},
  watch: {
    isAutoLogin() {
      localStorage.setItem(AUTO_LOGIN_KEY, AUTO_LOGIN_VAL);
    }
  },
  methods: {
    async checkTokenValid() {
      let res = await this.$api.getUserInfo();
      console.log(res);
      if (res === null || res.code !== 0) {
        localStorage.removeItem(AUTO_LOGIN_KEY);
      } else {
        this.$router.replace('/dashboard');
      }
    },
    // 提交表单
    async onSubmitHandler() {
      this.state.loginBtnLoading = true;
      if (!this.form.loginName || this.form.loginName.length<4) {
        return this.$api.warning("请输入用户名");
      }
      if (!this.form.loginPass || this.form.loginPass.length<4) {
        return this.$api.warning("请输入密码");
      }
      let res = await this.$api.publicLogin(this.form);
      console.log(res)
      this.state.loginBtnLoading = false;
      if(res.code === 0) {
        this.$router.replace('/dashboard');
      }
    },
    // 忘记密码
    onForgetPassHandler() {
      this.$message.warning('请联系管理员');
    },
  },
}
</script>
<style>
.ant-input-password .ant-input {
    height: 40px;
}
</style>

<style lang="scss" scoped>
.login-page-container {
  width: 100%;
  z-index: 1;
  min-height: 900px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(247, 248, 255, 0.5), rgba(247, 248, 255, 0.5)), transparent;
}
.right-top-bg {
  position: absolute;
  z-index: -1;
  right: -100px;
  top: -300px;
  width: 980.91px;
  height: 1177.09px;
  margin-top: 200px;
  mix-blend-mode: normal;
}
.left-bottom-bg {
  position: absolute;
  z-index: -1;
  width: 791px;
  height: 949.2px;
  left: -320px;
  bottom: 0px;
  mix-blend-mode: normal;
}
.login-body {
  width: 600px;
  height: 600px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  background: #FFFFFF;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding-top: 50px;
  .logo-txt {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 45px;
    /* identical to box height */
    text-align: center;
    color: #222222;
    margin-top: 10px;
  }
  .slogo {
    margin-top: 20px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #222222;
  }
  .slogo1 {
    text-align: center;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    color: #888888;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  $input-height: 40px;
  .sinput {
    margin-bottom: 15px;
    .ant-input {
      height:$input-height;
      width: 100%;
    }
    .ant-input-password {
      height: $input-height;
      .ant-input {
        height:$input-height;
      }
    }
  }
}
$logosize: 60px;
.logo {
  img {
    width: $logosize;
    height: 29px;
  }
}
</style>
