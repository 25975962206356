import Vue from "vue";
import * as utils from "../utils/index";

const MyPlugin = {};
MyPlugin.install = function (Vue) {
    Vue.prototype.$utils = utils;
}

Vue.use(MyPlugin,{});

export default utils;
