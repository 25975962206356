<template>
  <div>
    <a-select v-if="isEdit" v-model="innerValue" :style="{width: (width||300)+'px'}">
      <a-select-option v-for="(b,idx) in list" :key="idx" :value="b.id"
        @click="onChooseHandler(b)">{{b.name}}</a-select-option>
    </a-select>
    <span v-if="!isEdit">{{name}}</span>
  </div>
</template>

<script>
export default {
  name: "PregnantBxIndexFormItem",
  // status: plain 纯文本展示，edit 展示， disabled 禁用
  props: ['value', 'name', 'isEdit', 'width'],
  data() {
    return {
      innerValue: this.value || '',
      list: []
    }
  },
  watch: {
    value() {
      this.innerValue = this.value;
    }
  },
  mounted() {
    this.fetchDatas();
  },
  methods: {
    async fetchDatas() {
      let res = await this.$api.pregnantBxIndexListAll();
      this.list = res.list;
    },
    onChooseHandler(b) {
      this.$emit('change', b);
    }
  }
}
</script>

<style scoped>

</style>